"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var enum_1 = require("@/types/futures/enum");
var vuex_1 = require("vuex");
var common_1 = require("utils/futures/formula/common");
exports.default = {
    data: function () {
        return {
            hide: false
        };
    },
    computed: __assign(__assign(__assign(__assign({}, (0, vuex_1.mapState)('user', ['assets', 'earnOverview', 'walletHide'])), (0, vuex_1.mapState)('futures', ['futuresWallet', 'futuresSymbols', 'positions'])), (0, vuex_1.mapGetters)('futures', ['walletBalance'])), { 
        /**
         * 钱包余额
         * available + frozen + 所有仓位的保证金
         */
        futuresWalletBalance: function () {
            return this.positions.reduce(function (pre, item) {
                return pre.plus(item.im);
            }, this.bigNum(this.futuresWallet.available).plus(this.futuresWallet.frozen));
        }, 
        /**
         * 可用保证金: 余额 - 全仓亏损
         */
        availableMargin: function () {
            var _this = this;
            var loss = this.positions.reduce(function (pre, item) {
                if (item.openType === enum_1.OpenType.Cross) {
                    var contract = _this.futuresSymbols[item.symbol];
                    if (!contract) {
                        return pre;
                    }
                    var fn = item.positionType === enum_1.PositionType.Long
                        ? common_1.calcLongPl : common_1.calcShortPl;
                    var pnl = fn(item.holdAvgPrice, contract.ticker.fairPrice, item.totalVol, contract);
                    if (pnl.lt(0)) {
                        return pre.plus(pnl);
                    }
                    return pre;
                }
                else {
                    return pre;
                }
            }, this.bigNum(0));
            return loss.plus(this.futuresWallet.available);
        }, 
        /**
         * 仓位总盈亏
         */
        futuresTotalPl: function () {
            var _this = this;
            return this.positions.reduce(function (pre, item) {
                var contract = _this.futuresSymbols[item.symbol];
                if (!contract) {
                    return pre;
                }
                var fn = item.positionType === enum_1.PositionType.Long
                    ? common_1.calcLongPl : common_1.calcShortPl;
                var pnl = fn(item.holdAvgPrice, contract.ticker.fairPrice, item.totalVol, contract);
                return pre.plus(pnl);
            }, this.bigNum(0));
        }, 
        /**
         * 合约总权益
         *
         */
        futuresTotalEquity: function () {
            var _this = this;
            return this.positions.reduce(function (pre, item) {
                var contract = _this.futuresSymbols[item.symbol];
                if (!contract) {
                    return pre;
                }
                var fn = item.positionType === enum_1.PositionType.Long
                    ? common_1.calcLongPl : common_1.calcShortPl;
                var pnl = fn(item.holdAvgPrice, contract.ticker.fairPrice, item.totalVol, contract);
                return pre.plus(pnl);
            }, this.futuresWalletBalance);
        }, btcPrecision: function () {
            return this.assets['BTC'] ? this.assets['BTC'].precision : 6;
        }, spotFiatValue: function () {
            var totalValue = this.bigNum(0);
            for (var item in this.assets) {
                var total = this.bigNum(this.assets[item].available)
                    .plus(this.assets[item].freeze)
                    .plus(this.assets[item].other_freeze)
                    .toFixed();
                if (Number(total) !== 0) {
                    var value = this.$convertFiatValue(total, this.assets[item].asset_code);
                    totalValue = totalValue.plus(value || 0);
                }
            }
            return totalValue.toFixed();
        }, spotBtcValue: function () {
            return this.$fiatConvertBtc(this.spotFiatValue);
        }, futuresFiatValue: function () {
            return this.$convertFiatValue(this.futuresTotalEquity, 'USDT');
        }, futuresBtcValue: function () {
            return this.$fiatConvertBtc(this.futuresFiatValue);
        }, earnValue: function () {
            return this.$convertFiatValue(this.earnOverview.total_deposited, 'USDT');
        }, earnBtc: function () {
            return this.$fiatConvertBtc(this.earnOverview.total_deposited);
        }, totalFiatValue: function () {
            var total = this.bigNum(this.spotFiatValue).plus(this.futuresFiatValue).plus(this.earnValue).toFixed();
            return this.showFiatValue(total);
        }, totalBtc: function () {
            var total = this.bigNum(this.spotBtcValue).plus(this.futuresBtcValue).plus(this.earnBtc).toFixed();
            return this.showBtcValue(total);
        } }),
    methods: __assign(__assign({}, (0, vuex_1.mapMutations)('user', ['setWalletHide'])), { showFiatValue: function (value) {
            return this.c2ThousandsCount(this.numTrim(value, 2, false));
        }, showBtcValue: function (value) {
            return this.c2ThousandsCount(this.numTrim(value, this.btcPrecision, false));
        }, switchWalletHide: function () {
            this.setWalletHide(!this.walletHide);
        } }),
};
