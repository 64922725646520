<!--
 * 根据Naive-ui space组件修改
 -->
<script type="text/jsx">
// type Align =
//   | 'stretch'
//   | 'baseline'
//   | 'start'
//   | 'end'
//   | 'center'
//   | 'flex-end'
//   | 'flex-start'

// type Justify =
//   | 'start'
//   | 'end'
//   | 'center'
//   | 'space-around'
//   | 'space-between'
//   | 'space-evenly'

export default {
  name: 'Space',
  props: {
    align: String,
    inline: Boolean,
    wrapItem: {
      type: Boolean,
      default: true
    },
    itemStyle: [String, Object],
    justify: {
      type: String,
      default: 'start'
    },
    vertical: Boolean,
    size: {
      type: [String, Number, Array],
      default: 'medium'
    },
    wrap: {
      type: Boolean,
      default: true
    },
    tag: {
      type: String,
      default: 'div'
    }
  },
  data() {
    return {
      useGap: this.ensureSupportFlexGap(),
      supportFlexGap: undefined
    };
  },
  methods: {
    ensureSupportFlexGap() {
      if (this.supportFlexGap === undefined) {
        // create flex container with row-gap set
        const flex = document.createElement('div');
        flex.style.display = 'flex';
        flex.style.flexDirection = 'column';
        flex.style.rowGap = '1px';

        // create two, elements inside it
        flex.appendChild(document.createElement('div'));
        flex.appendChild(document.createElement('div'));

        // append to the DOM (needed to obtain scrollHeight)
        document.body.appendChild(flex);
        const isSupported = flex.scrollHeight === 1; // flex container should be 1px high from the row-gap
        document.body.removeChild(flex);
        return (this.supportFlexGap = isSupported);
      }
      return this.supportFlexGap;
    },
    flatten(vNodes) {
      if (!vNodes) return []
      
      return vNodes.filter((vnode) => vnode.tag);
    },
  },
  computed: {
    margin() {
      const { size } = this
      if (Array.isArray(size)) {
        return {
          horizontal: size[0],
          vertical: size[1]
        }
      }
      if (typeof size === 'number') {
        return {
          horizontal: size,
          vertical: size
        }
      }

      switch (size) {
        case 'medium':
          return {
            horizontal: 8,
            vertical: 12
          }
        case 'small':
          return {
            horizontal: 4,
            vertical:8
          }
        default:
          return {
            horizontal: 12,
            vertical: 16
          }
      }
    },
  },
  render() {
    const {
      vertical,
      align,
      inline,
      justify,
      itemStyle,
      margin,
      wrap,
      useGap,
      wrapItem,
      internalUseGap,
      tag
    } = this;

    const children = this.flatten(this.$slots.default);
    if (!children.length) return null;

    const horizontalMargin = `${margin.horizontal}px`
    const semiHorizontalMargin = `${margin.horizontal / 2}px`
    const verticalMargin = `${margin.vertical}px`
    const semiVerticalMargin = `${margin.vertical / 2}px`
    const lastIndex = children.length - 1
    const isJustifySpace = justify.startsWith('space-')
    return (
      <tag
        class={[
          `bc-space`,
        ]}
        style={{
          display: inline ? 'inline-flex' : 'flex',
          flexDirection: vertical ? 'column' : 'row',
          justifyContent: ['start', 'end'].includes(justify)
            ? 'flex-' + justify
            : justify,
          flexWrap: !wrap || vertical ? 'nowrap' : 'wrap',
          marginTop: useGap || vertical ? '' : `-${semiVerticalMargin}`,
          marginBottom: useGap || vertical ? '' : `-${semiVerticalMargin}`,
          alignItems: align,
          gap: useGap ? `${margin.vertical}px ${margin.horizontal}px` : ''
        }}
      >
        {!wrapItem && useGap
          ? children
          : children.map((child, index) => (
          <div
            role="none"
            class="bc-space-item"
            style={[
              itemStyle,
              {
                maxWidth: '100%'
              },
              useGap
                ? ''
                : vertical
                ? {
                    marginBottom: index !== lastIndex ? verticalMargin : ''
                  }
                : {
                    marginInlineEnd: isJustifySpace
                      ? justify === 'space-between' && index === lastIndex
                        ? ''
                        : semiHorizontalMargin
                      : index !== lastIndex
                      ? horizontalMargin
                      : '',
                    marginInlineStart: isJustifySpace
                      ? justify === 'space-between' && index === 0
                        ? ''
                        : semiHorizontalMargin
                      : '',
                    paddingTop: semiVerticalMargin,
                    paddingBottom: semiVerticalMargin
                  }
            ]}
          >
            {child}
          </div>
        ))}
      </tag>
    );
  }
};
</script>
