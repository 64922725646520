<template>
  <div class="header-wrapper">
    <header ref="header" class="client-header header" :class="{ dark: dark }">
      <RouterLink to="/">
        <!-- <img class="logo" src="@/assets/logo.png" alt="Biconomy ｜ Exchange the Economy" /> -->
        <img class="logo" src="@/assets/header/logo.png" alt="Biconomy ｜ Exchange the Economy" />
      </RouterLink>

      <template  v-if="showHeader">
        <Nav @hook:updated="updated" v-show="!mobileHeader" />

        <div class="header-right">
          <template v-if="!isUpDownPage">
            <HeaderSearch />

            <User>
              <div class="header-icon-wrap" @click="userSideShow = true">
                <img class="avatar" :src="require('assets/header/avatar_dark.svg')" alt="" />
              </div>
            </User>
          </template>
          
          <SecondsUser v-else>
            <div class="header-icon-wrap" @click="userSideShow = true">
              <img class="avatar" :src="require('assets/header/avatar_dark.svg')" alt="" />
            </div>
          </SecondsUser>

          <Lang v-if="!mobileHeader" />
          <Download v-show="!mobileHeader && !isUpDownPage" />

          <div class="header-icon-wrap side-header" v-if="mobileHeader" @click="sideNavShow = true">
            <svg
              width="0.3rem"
              height="0.3rem"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="header-icon"
            >
              <path
                d="M7 9H23"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7 15.0254H23"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7 21H23"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>

          <UserSide v-if="user.id" v-model="userSideShow" />

          <SideNav v-model="sideNavShow" />
        </div>
      </template>
    </header>
  </div>
</template>

<script>
import Nav from './nav.vue';
import Download from './download.vue';
import Lang from './lang.vue';
import User from './user/user.vue';
import UserSide from './user/user-side.vue';
import SideNav from './side-nav.vue';
import Telegram from '../TelegramList.vue';
import HeaderSearch from './search/header-search.vue'
import SecondsUser from './user/seconds-user.vue';
import { mapActions, mapMutations, mapState } from 'vuex';
import { throttle } from 'throttle-debounce';

// 页头
export default {
  name: 'ClientHeader',
  props: {
    show: Boolean
  },
  components: {
    Nav,
    Download,
    Lang,
    User,
    UserSide,
    SideNav,
    Telegram,
    HeaderSearch,

    SecondsUser
  },
  data() {
    return {
      headerWidth: 0,
      sideNavShow: false,
      userSideShow: false
    };
  },
  computed: {
    ...mapState('user', {
      user: (state) => state.info
    }),
    ...mapState('header', ['mobileHeader', 'showHeader']),
    dark() {
      return this.$route.path === '/';
    },
    isUpDownPage() {
      return this.$route.name === 'SecondsTrade'
    }
  },
  watch: {
    show: {
      handler(val) {
        if (!val) {
          this.sideNavShow = false;
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('header', ['getHeaders']),
    ...mapMutations('header', ['setMobileHeader']),
    windowResize() {
      if (!this.show || !this.$refs.header) return;

      // 15为滚动条宽度
      const width = document.body.offsetWidth + 15;
      const headerWidth = this.$refs.header.scrollWidth;
      if (!this.mobileHeader && headerWidth > width) {
        this.headerWidth = headerWidth;

        this.setMobileHeader(true);
      }

      if (this.mobileHeader && this.headerWidth <= width) {
        this.headerWidth = 0;
        this.setMobileHeader(false);
      }
    },
    updated() {
      this.$nextTick(() => {
        setTimeout(() => {
          const width = document.body.offsetWidth + 15;
          const headerWidth = this.$refs.header.scrollWidth;
          if (!this.mobileHeader && headerWidth > width) {
            this.headerWidth = headerWidth;
            this.setMobileHeader(true);
          }
        }, 0);
      })
    }
  },
  created() {
    this.getHeaders({ lang: this.$lang.getLang() });
  },
  mounted() {
    window.addEventListener('resize', throttle(100, this.windowResize));
  },
  beforeDestroy() {
    window.removeEventListener('resize', throttle(100, this.windowResize));
  }
};
</script>
