/* eslint-disable no-undef,import/extensions,import/no-unresolved */
import RemInit from '@livelybone/rem-init';

import 'babel-polyfill';
import '../css/app-zt.scss';
let needChangeSize = false;

export const remInit = (route) => {
  return RemInit({
    pageNoScale: /noScale/i.test(window.location.href),
    pageScalable: true,
    // pageScaleMaxFactor: true,
    pageScaleMiddleware: (fontScale, isMobile) => {
      if (isMobile) {
        if (route.meta.remInit) {
          document.documentElement.style.fontSize = '625%';
          return 1;
        } else if (route.path.includes('/mobile')) {
          needChangeSize = true;
          return 1 / fontScale;
        } else {
          return 1 / fontScale / fontScale / (1200 / fontScale / window.screen.availWidth);
        }
      }

      return 1;
    },
  });
};
// remInit(window.location.href);

if (needChangeSize) {
  // window.devicePixelRatio *
  // document.documentElement.style.fontSize = window.screen.availWidth /750 * 650 + '%'
  // document.documentElement.style.fontSize = 625 * fontScale + '%';
}
