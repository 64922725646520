<template>
  <Popover
    trigger="hover"
    ref="popoverRef"
    class="telegram-list"
    :referenceArrow="false"
    :closeDelay="10"
    :offset="0"
    popperClass="header-nav-popover telegram-list-popover"
  >
    <slot></slot>
    <div class="telegram-list-wrap" slot="popper">
      <div class="link-card">
        <a
          target="_blank"
          rel="noopener noreferrer"
          v-for="item in telegramList"
          :key="item.name"
          :href="item.link"
          @click="click"
        >
          <img class="flag-icon" :src="getOssUrl(item.code)" alt="telegram" />
          {{ item.name }}</a
        >
      </div>
    </div>
  </Popover>
</template>

<script>
import Popover from 'components/common/popover'
import { getOssDoc } from 'utils/oss';
import { telegramList } from 'utils/telegram-list';

export default {
  name: 'TelegramList',
  components: {
    Popover
  },
  props: {
    popperOptions: Object
  },
  data() {
    return {
      telegramList: telegramList
    };
  },
  methods: {
    click() {
      this.$refs['popoverRef'].doToggle();
    },
    getOssUrl(code) {
      return getOssDoc(`/flags_png/${code}.png`);
    }
  }
};
</script>

<style lang="scss">
.telegram-list-popover {
  .telegram-list-wrap {
    padding: 0.12rem;

    .link-card {
      @include paddingRow(0, 0.05rem);
      height: 1.96rem;
      overflow-y: auto;
    }
    a {
      white-space: nowrap;
      font-size: 0.14rem;
      line-height: 0.24rem;
      color: var(--telegram-list);
      padding: 0 0.1rem;
      @include flex();

      .flag-icon {
        height: auto !important;
        width: 0.15rem !important;
        border-radius: 0 !important;
        @include marginRow(0, 0.15rem);
      }

      &:hover {
        background-color: $gray-hover;
      }
    }
  }
}
</style>