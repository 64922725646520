import Vue from 'vue';
import { 
  DatePicker,
  Input, 
  Popover, 
  Progress, 
  Skeleton, 
  SkeletonItem, 
  Carousel, 
  CarouselItem, 
  Row, 
  Col
} from 'element-ui';

Vue.use(DatePicker);
Vue.use(Input);
Vue.use(Popover);
Vue.use(Progress);
Vue.use(Skeleton);
Vue.use(SkeletonItem);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Row);
Vue.use(Col);
