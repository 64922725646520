var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bc-form-item",class:[
    {
      'bc-form-item--feedback': _vm.elForm && _vm.elForm.statusIcon,
      'is-error': _vm.validateState === 'error',
      'is-validating': _vm.validateState === 'validating',
      'is-success': _vm.validateState === 'success',
      'is-required': _vm.isRequired || _vm.required,
      'is-no-asterisk': _vm.elForm && _vm.elForm.hideRequiredAsterisk
    },
    _vm.sizeClass ? 'bc-form-item--' + _vm.sizeClass : ''
  ]},[_c('label-wrap',{attrs:{"is-auto-width":_vm.labelStyle && _vm.labelStyle.width === 'auto',"update-all":_vm.form.labelWidth === 'auto'}},[(_vm.label || _vm.$slots.label)?_c('label',{staticClass:"bc-form-item__label",style:(_vm.labelStyle),attrs:{"for":_vm.labelFor}},[_vm._t("label",function(){return [_vm._v(_vm._s(_vm.label + _vm.form.labelSuffix))]})],2):_vm._e()]),_c('div',{staticClass:"bc-form-item__content",style:(_vm.contentStyle)},[_vm._t("default"),_c('transition',{attrs:{"name":"el-zoom-in-top"}},[(_vm.validateState === 'error' && _vm.showMessage && _vm.form.showMessage)?_vm._t("error",function(){return [_c('div',{staticClass:"bc-form-item__error",class:{
            'bc-form-item__error--inline':
              typeof _vm.inlineMessage === 'boolean'
                ? _vm.inlineMessage
                : (_vm.elForm && _vm.elForm.inlineMessage) || false
          }},[_vm._v(" "+_vm._s(_vm.validateMessage)+" ")])]},{"error":_vm.validateMessage}):_vm._e()],2)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }