import Store from 'store'
import expirePlugin  from 'store/plugins/expire'
import { endOfDay } from 'date-fns';

Store.addPlugin(expirePlugin)

const keys = {
  popupBanner: 'popup_banner_hidden',
  headerSearchRecords: 'header_search_records'
};

export default class StoreData {
  static getPopupBannerHidden() {
    return Store.get(keys.popupBanner) || []
  }
  static setPopupBanner(id) {
    const list = this.getPopupBannerHidden() || []

    if (!list.includes(id)) {
      list.push(id)

      const today = new Date();
      const endOfToday = endOfDay(today).getTime();

      Store.set(keys.popupBanner, list, endOfToday)
    }
  }


  static getHeaderSearchRecords() {
    return Store.get(keys.headerSearchRecords) || []
  }
  static setHeaderSearchRecords(coin) {
    if (!coin) return

    const list = this.getHeaderSearchRecords() || []

    const index = list.findIndex(item => item === coin)
    if (index > -1) {
      list.splice(index, 1)
    }

    list.unshift(coin)

    Store.set(keys.headerSearchRecords, list.slice(0, 20))
  }
  static clearHeaderSearchRecords() {
    Store.set(keys.headerSearchRecords, [])
  }
}