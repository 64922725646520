import * as Http from 'extensions/axios';
import config from 'config/config';

/**
 * 获取理财所有币种
 */
export function getEarnToken() {
  return Http.get(`${config.activityUrl}/earn_saving/asset/list`);
}
/**
 * 获取理财项目列表接口
 * @returns { Array<Object> } earn_list 理财列表
 * @property { String } name 理财名称
 * @property { String } deposit_asset 储蓄货币
 * @property { String } deposit_asset_name 货币名称
 * @property { String } apy_rate 全年收益
 * @property { String } term_desc 周期描述
 * @property { String } earn_product_list 理财产品列表
 * @property { String } type 类型 Flexible/Fixed
 * @property { String } rate 收益率
 * @property { String } term_amount 期数
 * @property { String } term_unit 单位 DAYS
 */
export function getAllTerms({ offset = 0, limited = 10, asset, earnType, rateSort, activityType }) {
  const obj = { offset, limited, rateSort };
  if (earnType) {
    obj.earnType = earnType;
  }

  if (asset) {
    obj.asset = asset;
  }

  if (['Staking', 'Promotions', 'Savings'].includes(activityType)) {
    obj.activityType = activityType;
  }
  return Http.get(`${config.activityUrl}/earn_saving/allearnterms`, obj);
}

export function topEarnTerms() {
  return Http.get(`${config.activityUrl}/earn_saving/topearnterms`);
}

/**
 * 查询⽤户购买的理财数据概览
 * @returns { Number } total_deposited
 * @returns { Number } total_earnings
 */
export function termOverview() {
  return Http.get(`${config.activityUrl}/earn_saving/overview`);
}

/**
 * 创建购买理财订单
 * @returns { String } 返回订单的id
 */
export function createOrder({ earn_saving_id, amount }) {
  return Http.post(`${config.activityUrl}/earn_saving/order/create`, { earn_saving_id, amount });
}

/**
 * 查询获取理财详情
 * @param { String } asset
 * @returns
 */
export function getTermDetail({ asset }) {
  return Http.get(`${config.activityUrl}/earn_saving/earndetail`, { asset });
}

/**
 * 查询⽤户理财列表
 * @param {Number} earnType  1: 活期 2: 定期
 * @returns {String} status saving理财中 expired已过期 redeemed已赎回
 */
export function getUserEarns({ offset = 0, limited = 10, asset = '', startTime = '', endTime = '', earnType }) {
  const obj = { offset, limited, asset, startTime, endTime };
  if (earnType) {
    obj.earnType = earnType;
  }
  return Http.get(`${config.activityUrl}/earn_saving/myearn`, obj);
}

/**
 *
 * 根据instanceId获取理财详情
 */
export function getInstanceDetail({ instance_id }) {
  return Http.get(`${config.activityUrl}/earn_saving/instancedetail`, { instance_id });
}

/**
 * 创建理财赎回请求
 * @returns
 */
export function redeemEarn({ instance_id, amount }) {
  return Http.post(`${config.activityUrl}/earn_saving/redeem/create`, { instance_id, amount });
}

/**
 * 查询⽤户到期/赎回订单
 */
export function earnHistory({ offset = 0, limited = 10, asset = '', startTime = '', endTime = '', earnType }) {
  const obj = { offset, limited, asset, startTime, endTime };
  if (earnType) {
    obj.earnType = earnType;
  }
  return Http.get(`${config.activityUrl}/earn_saving/order/list`, obj);
}

/**
 * 查询⽤户收益+购买+赎回记录
 */
export function earnTransaction({ offset = 0, limited = 10, asset = '', startTime = '', endTime = '', earnType }) {
  const obj = { offset, limited, asset, startTime, endTime };
  if (earnType) {
    obj.earnType = earnType;
  }
  return Http.get(`${config.activityUrl}/earn_saving/transaction/list`, obj);
}

/**
 * 获取用户参与过的理财项目列表接口
 * @returns { Array<Object> } earn_product_list 理财列表
 * @property { String } status 赎回状态redeemable/unredeemable
 * @property { String } deposit_asset 储蓄货币
 * @property { String } deposit_asset_name 货币名称
 * @property { String } user_input 用户当前储蓄数量
 * @property { String } total_earnings 总收益
 * @property { String } earn_asset 收益货币
 * @property { String } type 类型 Flexible/Fixed
 * @property { String } rate 收益率
 * @property { String } term_amount 期数
 * @property { String } term_unit 单位 DAYS
 */
export function getMyEarnings() {
  return Http.get('/earn/myearnings');
}

/**
 *
 * 获取用户理财概览数据
 * @property { String } total_deposited
 * @property { String } total_earnings
 */
export function getOverview() {
  return Http.get('earn/overview');
}

/**
 * 获取某个币种的理财详情数据包含数据图
 * @param { String } asset
 * @returns { Object }
 * @property { String } id 产品编号
 * @property { String } input_min 最小购买数量
 * @property { String } total_input_max 理财项目总数量
 * @property { String } current_input 当前购买数量
 * @property { String } subscribe_at 订阅时间
 * @property { String } expire_at 过期时间
 */
export function getDetail(asset) {
  return Http.get(`earn/detail/${asset}`);
}

/**
 * 获取用户历史理财订单列表
 * @returns { Object }
 * @property { String } status 订单状态redeemed/expired/redeeming
 * @property { String } order_id 订单编号
 * @property { String } start_time 开始时间
 * @property { String } end_time 结束时间
 */
export function getOrders(asset) {
  return Http.get(`earn/history/orders`);
}

/**
 * 获取用户理财订单历史收益明细
 * @returns { Object }
 * @property { String } status 交易状态
 * @property { String } asset 交易货币
 * @property { String } asset_name 货币全名
 * @property { String } asset_amount 交易货币数量
 * @property { String } type 交易类型 profit/subcribe
 * @property { String } term_type 期数类型
 * @property { String } create_time 创建时间
 */
export function getTransactions() {
  return Http.get('earn/transactions');
}

/**
 * 用户下单认购活期/定期理财接口
 * @param { Number } earn_product_id 认购的理财产品id
 * @param { Number } amount 购买数量
 */
export function earnConfirm({ earn_product_id, amount }) {
  return Http.post('earn/order/confirm', { earn_product_id, amount });
}

/**
 * 用户活期理财订单赎回接口（包括部分赎回和全部赎回）
 * @param { Number } order_id 要赎回的活期订单id
 * @param { Number } amount 赎回数量
 */
export function redeem({ order_id, amount }) {
  return Http.post('earn/order/redeem', { order_id, amount });
}

export function earnNotice() {
  return Http.get(`${config.activityUrl}/earn_saving/earnNotice`);
}

/**
 * 申购历史
 */
export function earnSavingList({ offset, limit, earnType, activityType, startTime, endTime, asset }) {
  return Http.get(`${config.activityUrl}/earn_saving/earnSaving/list`, { offset, limit, earnType, activityType, startTime, endTime, asset })
}

/**
 * 赎回历史
 */
export function redeemList({ offset, limit, earnType, activityType, startTime, endTime, asset }) {
  return Http.get(`${config.activityUrl}/earn_saving/redeem/list`, { offset, limit, earnType, activityType, startTime, endTime, asset })
}

/**
 * 利息发放历史
 */
export function profitList({ offset, limit, earnType, activityType, startTime, endTime, asset }) {
  return Http.get(`${config.activityUrl}/earn_saving/profit/list`, { offset, limit, earnType, activityType, startTime, endTime, asset })
}