<template>
  <SideWrapper
    :height="sideHeight"
    class="header-user-side"
    v-model="sideShow"
    @input="sideShowChange"
  >
    <template slot="top">
      <div class="user-side-top">
        <img
          class="avatar"
          v-if="theme === 'light'"
          :src="require('assets/header/avatar_light.svg')"
          alt=""
        />
        <img class="avatar" v-else :src="require('assets/header/avatar_dark.svg')" alt="" />

        <div class="user-info">
          <p class="user-info-name">{{ user.username }}</p>

          <div class="user-info-id">
            UID: {{ user.id }}
            <img
              class="copy-icon"
              :src="require('assets/client/actPage/copy_icon.svg')"
              alt=""
              @click="copy"
            />

            <div class="identity-status" :class="identityStatusClass" @click="identity">
              {{ $t(identityStatusName) }}
            </div>
          </div>
        </div>
      </div>
    </template>

    <template slot="content">
      <template v-if="mobileHeader">
        <Collapse v-for="item in userLink" :data="item" :key="item.title" />
      </template>

      <template v-for="item in userInfoLink">
        <NavLink
          :key="item.name"
          :data="item"
          class="header-side-item"
        >
          {{ item.name }}
        </NavLink>
      </template>
    </template>

    <template slot="footer">
      <div class="log-out">
        <Button type="primary" block size="large" @click="logoutHandle" :loading="loading">
          {{ $t('user.logout') }}
        </Button>
      </div>
    </template>
  </SideWrapper>
</template>

<script>
import SideWrapper from '../side-wrapper.vue';
import Collapse from '../collapse.vue';
import NavLink from '../nav-link.vue';
import { mapState, mapActions, mapGetters } from 'vuex';
import { copyText } from 'utils/Utils';
import Status from 'data/immutable-data/Status';

export default {
  props: {
    value: Boolean
  },
  components: {
    SideWrapper,
    Collapse,
    NavLink
  },
  data() {
    return {
      sideShow: false,
      sideHeight: `calc(100vh - 0.6rem - 0.48rem - 0.28rem - 0.3rem - 0.46rem - 0.76rem)`,
      loading: false
    };
  },
  computed: {
    ...mapState('user', {
      user: (state) => state.info
    }),
    ...mapState('header', ['mobileHeader']),
    ...mapGetters('header', ['userLink', 'userInfoLink']),
    identityStatusName() {
      if (!this.user.id) return null;

      let status = this.user.identity_status;
      if (this.user.user_identity_auth === 1) {
        status = 2
      }

      return Status.authorizeStatus[status].name
    },
    identityStatusClass() {
      let identity_status = this.user.identity_status;
      if (this.user.user_identity_auth === 1) {
        identity_status = 1
      }

      if (identity_status === 1) {
        return 'identity-status-authorized'
      } else {
        return 'identity-status-failed'
      }
    }
  },
  watch: {
    value: {
      handler(val) {
        this.sideShow = val;
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('user', ['logout']),
    sideShowChange(val) {
      this.$emit('input', val);
    },
    copy() {
      copyText(this.user.id)
        .then((val) => this.snackBar.info(this.$t(val)))
        .catch((e) => this.snackBar.error(this.$t(e)));
    },
    identity() {
      this.$router.push('/user-center/security/identity');
    },
    async logoutHandle() {
      this.loading = true

      try {
        await this.logout()
      } catch (error) {
        this.snackBar.error(error)
      } finally {
        this.loading = false
      }
    }
  }
};
</script>
