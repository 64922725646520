<template>
  <div class="header-icon-wrap" @click="open">
    <SvgIcon class="header-icon" name="language" />

    <slot></slot>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  methods: {
    ...mapMutations(['setLocaleCurrencyModalShow']),
    open() {
      this.setLocaleCurrencyModalShow(true)
    }
  }
};
</script>
