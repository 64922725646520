<template>
  <component
    class="bc-btn"
    :class="className"
    @click="handleClick"
    :type="nativeType"
    :disabled="disabled || loading"
    :is="tag"
    v-bind="$attrs"
  >
    <SvgIcon name="loading" v-if="loading" />

    <span class="bc-btn-content" v-if="$slots.default">
      <slot></slot>
    </span>
  </component>
</template>

<script>
export default {
  name: 'BcButton',
  props: {
    // filled, outlined, primary, cancel
    type: {
      type: String,
      default: 'filled'
    },
    size: {
      type: String,
      default: 'medium'
    },
    block: Boolean,
    loading: Boolean,
    disabled: Boolean,
    nativeType: {
      type: String,
      default: 'button'
    },
    tag: {
      type: String,
      default: 'button'
    }
  },
  computed: {
    className() {
      let str = 'bc-btn-' + this.type;

      if (this.block) {
        str = str + ' ' + 'bc-btn-block';
      }

      if (this.size === 'large') {
        str = str + ' ' + 'bc-btn-large';
      } else if (this.size === 'small') {
        str = str + ' ' + 'bc-btn-small';
      } else {
        str = str + ' ' + 'bc-btn-medium';
      }

      if (this.loading) {
        str = str + ' ' + 'bc-btn-loading';
      }

      if (this.disabled) {
        str = str + ' ' + 'bc-btn-disabled';
      }

      return str;
    }
  },
  methods: {
    handleClick(e) {
      if (!this.loading && !this.disabled) {
        this.$emit('click', e);
      }
    }
  }
};
</script>
