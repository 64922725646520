"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mitt = void 0;
var futures_socket_1 = require("utils/futures/futures-socket");
var mitt_1 = require("mitt");
var config = require("config/config");
var enum_1 = require("@/types/futures/enum");
var auth_token_1 = require("data/api/auth-token");
exports.mitt = (0, mitt_1.default)();
var futuresSocket = new futures_socket_1.default(config.futuresWs, exports.mitt);
var FuturesSocket = /** @class */ (function () {
    function FuturesSocket() {
    }
    FuturesSocket.Auth = function () {
        if (FuturesSocket.authorized) {
            Promise.resolve();
        }
        if (!FuturesSocket.authPromise) {
            FuturesSocket.authPromise = new Promise(function (resolve, reject) {
                futuresSocket.request({
                    method: 'login',
                    params: {
                        token: auth_token_1.AuthToken.getToken()
                    },
                    channel: 'response.login'
                }).then(function (_a) {
                    var data = _a.data;
                    if (data === 'success') {
                        FuturesSocket.authorized = true;
                        resolve();
                    }
                });
            });
        }
        return FuturesSocket.authPromise;
    };
    FuturesSocket.subscribeTicker = function (callback) {
        futuresSocket.subscribe({
            method: 'subscribe.tickers',
            channel: 'push.tickers'
        });
        exports.mitt.on('push.tickers', function (_a) {
            var data = _a.data;
            callback(data);
        });
    };
    FuturesSocket.unsubscribeTicker = function () {
        futuresSocket.unsubscribe({
            method: 'unsubscribe.tickers',
            channel: 'push.tickers'
        });
        exports.mitt.off('push.tickers');
    };
    FuturesSocket.subscribeKline = function (trend, params, callback) {
        var method, channel;
        switch (trend) {
            case enum_1.Trend.Last:
                method = 'subscribe.kline';
                channel = 'push.kline';
                break;
            case enum_1.Trend.Index:
                method = 'subscribe.kline.index.price';
                channel = 'push.kline.index.price';
                break;
            default:
                method = 'subscribe.kline.fair.price';
                channel = 'push.kline.fair.price';
        }
        futuresSocket.subscribe({
            method: method,
            params: params,
            channel: channel
        });
        exports.mitt.on(channel, function (_a) {
            var data = _a.data;
            data.symbol += "_".concat(trend);
            callback(data);
        });
    };
    FuturesSocket.unsubscribeKline = function (trend, params) {
        var method, channel;
        switch (trend) {
            case enum_1.Trend.Last:
                method = 'unsubscribe.kline';
                channel = 'push.kline';
                break;
            case enum_1.Trend.Index:
                method = 'unsubscribe.kline.index.price';
                channel = 'push.kline.index.price';
                break;
            default:
                method = 'unsubscribe.kline.fair.price';
                channel = 'push.kline.fair.price';
        }
        futuresSocket.unsubscribe({ method: method, params: params, channel: channel });
        exports.mitt.off(channel);
    };
    FuturesSocket.subUser = function () {
        this.Auth().then(function () {
            futuresSocket.subscribe({
                method: 'user',
                channel: 'user',
                params: {
                    "events": [
                        { "key": "asset" },
                        { "key": "position" }
                    ]
                }
            });
        });
    };
    FuturesSocket.authorized = false;
    FuturesSocket.authPromise = null;
    return FuturesSocket;
}());
exports.default = FuturesSocket;
