/**
 * 根据element-plus中的scrollbar组件修改
 */
import Scrollbar from './src/scrollbar.vue';

/* istanbul ignore next */
Scrollbar.install = function (Vue) {
  Vue.component(Scrollbar.name, Scrollbar);
};

export default Scrollbar;
