import { createPopper } from '@popperjs/core';
const stop = e => e.stopPropagation();

/**
 * @param {HTMLElement} [reference=$refs.reference] - The reference element used to position the popper.
 * @param {HTMLElement} [popper=$refs.popper] - The HTML element used as popper, or a configuration used to generate the popper.
 * @param {String} [placement=button] - Placement of the popper accepted values: top(-start, -end), right(-start, -end), bottom(-start, -end), left(-start, -end)
 * @param {Number} [offset=0] - Amount of pixels the popper will be shifted (can be negative).
 * @param {Boolean} [visible=false] Visibility of the popup element.
 * @param {Boolean} [visible-arrow=false] Visibility of the arrow, no style.
 */
let popperContainer = null

export default {
  props: {
    transformOrigin: {
      type: [Boolean, String],
      default: true
    },
    placement: {
      type: String,
      default: 'bottom-start'
    },
    boundariesPadding: {
      type: Number,
      default: 5
    },
    reference: {},
    popper: {},
    offset: {
      default: 4
    },
    // 默认值
    value: Boolean,
    visibleArrow: Boolean,
    arrowOffset: {
      type: Number,
      default: 35
    },
    appendToBody: {
      type: Boolean,
      default: true
    },
    popperOptions: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      showPopper: false,
      currentPlacement: ''
    };
  },

  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.showPopper = val;
        this.$emit('input', val);
      }
    },

    showPopper(val) {
      if (this.disabled) return;
      val ? this.updatePopper() : this.destroyPopper();
      this.$emit('input', val);
    }
  },

  methods: {
    createPopper() {
      let placement = this.currentPlacement = this.currentPlacement || this.placement;
      if (!/^(top|bottom|left|right)(-start|-end)?$/g.test(this.currentPlacement)) {
        return;
      }

      const dir = this.$lang.getDir()
      if (dir === 'rtl') {
        if (placement.includes('left')) {
          placement = placement.replace('left', 'right')
        } else if (placement.includes('right')) {
          placement = placement.replace('right', 'left')
        } else if (placement.includes('start')) {
          placement = placement.replace('start', 'end')
        } else if (placement.includes('end')) {
          placement = placement.replace('end', 'start')
        }
      }

      const popper = this.popperElm = this.popperElm || this.popper || this.$refs.popper;
      let reference = this.referenceElm = this.referenceElm || this.reference || this.$refs.reference;

      if (!reference &&
        this.$slots.reference &&
        this.$slots.reference[0]) {
        reference = this.referenceElm = this.$slots.reference[0].elm;
      }

      if (!popper || !reference) return;
      if (this.visibleArrow) this.appendArrow(popper);
      if (this.appendToBody) {
        if (!popperContainer) {
          popperContainer = document.createElement('div')
          popperContainer.className = "bc-popper-wrapper"
          document.body.appendChild(popperContainer)
        }
        
        popperContainer.appendChild(this.popperElm)
      }
      if (this.popperJS && this.popperJS.destroy) {
        this.popperJS.destroy();
      }
      this.popperJS = createPopper(reference, popper, {
        placement: placement,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, this.offset]
            }
          },
          {
            "name": "computeStyles",
            "options": {
              "gpuAcceleration": false
            }
          },
          ...this.popperOptions
        ],
        onFirstUpdate: () => {
          this.$emit('created', this);
          this.resetTransformOrigin();
          this.$nextTick(this.updatePopper);
        }
      });

      this.popperElm.addEventListener('click', stop);
    },

    updatePopper() {
      const popperJS = this.popperJS;
      if (popperJS) {
        popperJS.update();
      } else {
        this.createPopper();
      }
    },

    doDestroy(forceDestroy) {
      if (!this.popperJS || (this.showPopper && !forceDestroy)) return;
      this.popperJS.destroy();
      this.popperJS = null;
    },

    destroyPopper() {
      if (this.popperJS) {
        this.resetTransformOrigin();
      }
    },

    resetTransformOrigin() {
      if (!this.transformOrigin) return;
      let placementMap = {
        'top start': 'left bottom',
        'top': 'center bottom',
        'top-end': 'right bottom',
        'right-start': 'left top',
        'right': 'left center',
        'right-end': 'left bottom',
        'bottom-end': 'right top',
        'bottom': 'center top',
        'bottom-start': 'left top',
        'left-end': 'right bottom',
        'left': 'right center',
        'left-start': 'right top'
      }

      const popper = this.popperJS.state.elements.popper
      let placement = popper.getAttribute('data-popper-placement');
      popper.style.transformOrigin = typeof this.transformOrigin === 'string'
        ? this.transformOrigin
        : placementMap[placement];
    },

    appendArrow(element) {
      let hash;
      if (this.appended) {
        return;
      }

      this.appended = true;

      for (let item in element.attributes) {
        if (/^_v-/.test(element.attributes[item].name)) {
          hash = element.attributes[item].name;
          break;
        }
      }

      const arrow = document.createElement('div');

      if (hash) {
        arrow.setAttribute(hash, '');
      }
      arrow.setAttribute('x-arrow', '');
      arrow.className = 'popper__arrow';
      element.appendChild(arrow);
    }
  },

  beforeDestroy() {
    this.doDestroy(true);
    if (this.popperElm && this.popperElm.parentNode === document.body) {
      this.popperElm.removeEventListener('click', stop);
    }

    if (this.popperElm && this.appendToBody) {
      popperContainer?.removeChild(this.popperElm);
    }
  },

  // call destroy in keep-alive mode
  deactivated() {
    this.$options.beforeDestroy[0].call(this);
  }
};
