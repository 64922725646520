<template>
  <div ref="scrollbarRef" class="bc-scrollbar">
    <div ref="wrapRef" :class="wrapKls" :style="style" @scroll="handleScroll">
      <component
        :is="tag"
        ref="resizeRef"
        :class="resizeKls"
        :style="viewStyle"
      >
        <slot />
      </component>
    </div>
    <template v-if="!native">
      <bar
        ref="barRef"
        :height="sizeHeight"
        :width="sizeWidth"
        :always="always"
        :ratio-x="ratioX"
        :ratio-y="ratioY"
      />
    </template>
  </div>
</template>

<script>
import Bar from './bar.vue'
import { GAP } from './util'
import { addResizeListener, removeResizeListener } from 'element-ui/src/utils/resize-event';

export default {
  name: 'BcScrollbar',
  props: {
    /**
     * @description height of scrollbar
     */
    height: {
      type: [String, Number],
      default: '',
    },
    /**
     * @description max height of scrollbar
     */
    maxHeight: {
      type: [String, Number],
      default: '',
    },
    /**
     * @description whether to use the native scrollbar
     */
    native: {
      type: Boolean,
      default: false,
    },
    /**
     * @description style of wrap
     */
    wrapStyle: {
      type: [String, Object, Array],
      default: '',
    },
    /**
     * @description class of wrap
     */
    wrapClass: {
      type: [String, Array],
      default: '',
    },
    /**
     * @description class of view
     */
    viewClass: {
      type: [String, Array],
      default: '',
    },
    /**
     * @description style of view
     */
    viewStyle: {
      type: [String, Array, Object],
      default: '',
    },
    /**
     * @description do not respond to container size changes, if the container size does not change, it is better to set it to optimize performance
     */
    noresize: Boolean, // 如果 container 尺寸不会发生变化，最好设置它可以优化性能
    /**
     * @description element tag of the view
     */
    tag: {
      type: String,
      default: 'div',
    },
    /**
     * @description always show
     */
    always: Boolean,
    /**
     * @description minimum size of scrollbar
     */
    minSize: {
      type: Number,
      default: 20,
    }
  },
  components: {
    Bar
  },
  provide(){
    return {
      'scrollbarContextKey': () => {
        return {
          scrollbarElement: this.scrollbarElement,
          wrapElement: this.wrapElement,
        }
      }
    }
  },
  data() {
    return {
      scrollbarElement: null,
      wrapElement: null,
      sizeWidth: '0',
      sizeHeight: '0',
      ratioY: 1,
      ratioX: 1
    }
  },
  computed: {
    style() {
      const style = {}
      if (this.height) style.height = this.addUnit(this.height)
      if (this.maxHeight) style.maxHeight = this.addUnit(this.maxHeight)

      return [this.wrapStyle, style]
    },
    wrapKls() {
      return [
        this.wrapClass,
        'bc-scrollbar__wrap',
        {
          'bc-scrollbar__wrap--hidden-default': !this.native
        }
      ]
    },
    resizeKls() {
      return ['bc-scrollbar__view', this.viewClass]
    },
    watchHeight() {
      return [this.maxHeight, this.height]
    }
  },
  watch: {
    noresize: {
      handler(noresize) {
        if (noresize) {
          this.stopResizeObserver()
          this.stopResizeListener()
        } else {  
          this.$nextTick(() => {
            addResizeListener(this.$refs.resizeRef, this.update)
          })
          
          window.addEventListener('resize', this.update)
        }
      },
      immediate: true
    },
    watchHeight() {
      if (this.native) {
        this.$nextTick(() => {
          this.update()
          if(this.$refs.wrapRef) {
            this.$refs.barRef.handleScroll(this.$refs.wrapRef)
          }
        })
      }
    }
  },
  methods: {
    addUnit(val) {
      return val
      return val + 'px'
    },
    handleScroll() {
      if (this.$refs.wrapRef) {
        this.$refs.barRef.handleScroll(this.$refs.wrapRef)

        this.$emit('scroll', {
          scrollTop: this.$refs.wrapRef.scrollTop,
          scrollLeft: this.$refs.wrapRef.scrollLeft,
        })
      }
    },
    scrollTo(arg1, arg2) {
      if (typeof arg1 === 'object') {
        this.$refs.wrapRef.scrollTo(arg1)
      } else if (typeof arg1 === 'number' && typeof arg1 === 'number') {
        this.$refs.wrapRef.scrollTo(arg1, arg2)
      }
    },
    setScrollTop(value) {
      if (!typeof value === 'number') {
        return
      }
      this.$refs.wrapRef.scrollTop = value
    },
    setScrollLeft(value) {
      if (!typeof value === 'number') {
        return
      }
      this.$refs.wrapRef.scrollLeft = value
    },
    update() {
      const offsetHeight =  this.$refs.wrapRef.offsetHeight - GAP
      const offsetWidth =  this.$refs.wrapRef.offsetWidth - GAP

      const originalHeight = offsetHeight ** 2 / this.$refs.wrapRef.scrollHeight
      const originalWidth = offsetWidth ** 2 / this.$refs.wrapRef.scrollWidth
      const height = Math.max(originalHeight, this.minSize)
      const width = Math.max(originalWidth, this.minSize)

      this.ratioY =
        originalHeight /
        (offsetHeight - originalHeight) /
        (height / (offsetHeight - height))
      this.ratioX =
        originalWidth /
        (offsetWidth - originalWidth) /
        (width / (offsetWidth - width))

      this.sizeHeight = height + GAP < offsetHeight ? `${height}px` : ''
      this.sizeWidth = width + GAP < offsetWidth ? `${width}px` : ''
    },
    stopResizeObserver() {
      if (this.$refs.resizeRef) {
        removeResizeListener(this.$refs.resizeRef, this.update)
      }
    },
    stopResizeListener() {
      window.removeEventListener('resize', this.update)
    }
  },
  updated() {
    this.update()
  },
  mounted() {
    this.scrollbarElement = this.$refs.scrollbarRef
    this.wrapElement = this.$refs.wrapRef

    if (this.native) {
      this.$nextTick(() => {
        this.update()
      })
    }
  },
  beforeDestroy() {
    this.stopResizeObserver()
    this.stopResizeListener()
  }
}
</script>