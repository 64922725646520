<template>
  <SvgIcon 
    name="star"
    class="bc-favorite-icon"
    :class="{ 'is_favorite': isFavorite }" 
    @click.stop="toggleFavorite" 
  />
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'SymbolFavorite',
  props: {
    // spot, futures
    type: String,
    symbol: String,
    id: Number
  },
  computed: {
    ...mapState('market', ['spotFavoriteList']),
    ...mapState('futures', ['futuresFavoriteList']),
    isFavorite() {
      if (this.type === 'spot') {
        return this.spotFavoriteList.includes(this.id)
      } else if (this.type === 'futures') {
        return this.futuresFavoriteList.includes(this.symbol)
      }
    }
  },
  methods: {
    ...mapActions('market', ['toggleSpotFavorite']),
    ...mapActions('futures', ['toggleFuturesFavorite']),
    toggleFavorite() {
      if (this.type === 'spot') {
        this.toggleSpotFavorite(this.id)
      } else {
        this.toggleFuturesFavorite(this.symbol)
      }
    }
  }
}
</script>

<style lang="scss">
.bc-svg-icon {
  &.bc-favorite-icon {
    color: $gray;
    font-size: 0.16rem; 
  }

  &.is_favorite {
    color: $main-color;
  }
}

</style>