import { get, post } from 'utils/axios/seconds-axios'

export default class SecondsTrade {
  static getRoundData(data) {
    return get('/round/last', data);
  }

  static postOrder(data) {
    return get('/position/make', data);
  }

  static getOrderByIds(ids) {
    return get('/position/get-by-ids', ids);
  }

  static getPendingOrder(data) {
    return get('/position/pending', data);
  }

  static getHistoryOrder(data) {
    return get('/position/history', data);
  }

  static getUserAsset(data) {
    return get('/user/asset', data);
  }

  static getConfig() {
    return get('/config');
  }

  static getUserReward(data) {
    return get('/user/reward', data);
  }

  static getRewardStatics(data) {
    return get('/user/reward/statics', data);
  }

  static getRewardLevel(data) {
    return get('/user/reward/level', data);
  }
}
