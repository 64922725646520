// 获取交易对的价格 - price，数量 - amount，总额 - total 的精度
export function getPrecision(symbol) {
  const { quote_asset_precision, base_asset_precision } = { ...symbol };
  const precisions = {};
  precisions.price = quote_asset_precision >= 0 ? quote_asset_precision : 8;
  precisions.amount = base_asset_precision >= 0 ? base_asset_precision : 8;
  // precisions.total = precisions.price + precisions.amount;
  precisions.total = precisions.amount;
  return precisions;
}

export function getFuturesPrecision(contractCoin) {
  const { coinScale, baseCoinScale } = contractCoin;
  const precisions = {};
  precisions.amount = coinScale >= 0 ? coinScale : 8;
  precisions.price = baseCoinScale >= 0 ? baseCoinScale : 8;
  precisions.total = precisions.amount;
  return precisions;
}
