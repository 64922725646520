import Store from 'store'

const keys = {
  spotFavorites: 'bc-spot-favorites'
}

export default class SpotStore {
  static getSpotFavorites() {
    return (Store.get(keys.spotFavorites) || []).filter((item) => !!item)
  }

  static delSpotFavorites(id) {
    if (!id) return

    const list = this.getSpotFavorites() || []
    const index = list.findIndex(item => item === id)
    if (index > -1) {
      list.splice(index, 1)
    }
    
    Store.set(keys.spotFavorites, list)
  }

  static addSpotFavorites(id) {
    this.delSpotFavorites(id)
    const list = this.getSpotFavorites() || []
    list.unshift(id)
    Store.set(keys.spotFavorites, list)
  }

  static clearSpotFavorites() {
    Store.set(keys.spotFavorites, [])
  }
}