"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssetRecordEnum = exports.TpSlVolType = exports.VolType = exports.TpSlTriggerSide = exports.OrderType = exports.TradeSide = exports.OrderSide = exports.PositionType = exports.OpenType = exports.Uint = exports.Trend = void 0;
var Trend;
(function (Trend) {
    /** @desc 最新价 */
    Trend[Trend["Last"] = 1] = "Last";
    /** @desc 合理价 */
    Trend[Trend["Fair"] = 2] = "Fair";
    /** @desc 指数价 */
    Trend[Trend["Index"] = 3] = "Index";
})(Trend || (exports.Trend = Trend = {}));
var Uint;
(function (Uint) {
    Uint[Uint["Cont"] = 0] = "Cont";
    Uint[Uint["Base"] = 1] = "Base";
    Uint[Uint["Quote"] = 2] = "Quote";
})(Uint || (exports.Uint = Uint = {}));
var OpenType;
(function (OpenType) {
    OpenType[OpenType["Isolated"] = 1] = "Isolated";
    OpenType[OpenType["Cross"] = 2] = "Cross";
})(OpenType || (exports.OpenType = OpenType = {}));
/** 1 多 2 空 */
var PositionType;
(function (PositionType) {
    PositionType[PositionType["Long"] = 1] = "Long";
    PositionType[PositionType["Short"] = 2] = "Short";
})(PositionType || (exports.PositionType = PositionType = {}));
/**
 * 方向，1开多, 2平空, 3开空, 4平多
 */
var OrderSide;
(function (OrderSide) {
    OrderSide[OrderSide["OpenLong"] = 1] = "OpenLong";
    OrderSide[OrderSide["OpenShort"] = 3] = "OpenShort";
    OrderSide[OrderSide["CloseLong"] = 4] = "CloseLong";
    OrderSide[OrderSide["CloseShort"] = 2] = "CloseShort";
})(OrderSide || (exports.OrderSide = OrderSide = {}));
var TradeSide;
(function (TradeSide) {
    TradeSide[TradeSide["BUY"] = 1] = "BUY";
    TradeSide[TradeSide["SELL"] = 2] = "SELL";
})(TradeSide || (exports.TradeSide = TradeSide = {}));
/** 1:限价单, 2:Post Only只做Maker, 3:立即成交或立即取消, 4:全部成交或者全部取消| 5 市价 */
var OrderType;
(function (OrderType) {
    OrderType[OrderType["Limit"] = 1] = "Limit";
    OrderType[OrderType["PostOnly"] = 2] = "PostOnly";
    OrderType[OrderType["IOC"] = 3] = "IOC";
    OrderType[OrderType["FOK"] = 4] = "FOK";
    OrderType[OrderType["Market"] = 5] = "Market";
    OrderType[OrderType["Conditional"] = 10] = "Conditional";
})(OrderType || (exports.OrderType = OrderType = {}));
var TpSlTriggerSide;
(function (TpSlTriggerSide) {
    TpSlTriggerSide[TpSlTriggerSide["None"] = 0] = "None";
    TpSlTriggerSide[TpSlTriggerSide["Profit"] = 2] = "Profit";
    TpSlTriggerSide[TpSlTriggerSide["Loss"] = 1] = "Loss";
})(TpSlTriggerSide || (exports.TpSlTriggerSide = TpSlTriggerSide = {}));
/**
 * Partial: 部分止盈止损 Entire: 全部止盈止损
 */
var VolType;
(function (VolType) {
    VolType[VolType["Partial"] = 1] = "Partial";
    VolType[VolType["Entire"] = 2] = "Entire";
})(VolType || (exports.VolType = VolType = {}));
var TpSlVolType;
(function (TpSlVolType) {
    TpSlVolType["SAME"] = "SAME";
    TpSlVolType["SEPARATE"] = "SEPARATE";
})(TpSlVolType || (exports.TpSlVolType = TpSlVolType = {}));
var AssetRecordEnum;
(function (AssetRecordEnum) {
    AssetRecordEnum["LiquidationClearance"] = "LIQUIDATION";
    AssetRecordEnum["FundingFee"] = "FUNDING";
    AssetRecordEnum["Transfer"] = "TRANSFER";
    AssetRecordEnum["Fee"] = "FEE";
    AssetRecordEnum["RealizedPNL"] = "CLOSE_POSITION";
    AssetRecordEnum["ADL"] = "ADL";
    AssetRecordEnum["Bonus"] = "BONUS";
})(AssetRecordEnum || (exports.AssetRecordEnum = AssetRecordEnum = {}));
