<template>
  <Modal
    class="locale-currency-modal"
    :value="localeCurrencyModalShow"
    :appendToBody="true"
    @input="handleInput"
    width="7.2rem"
  >
    <template #header>
      <div class="locale-currency-modal-tabs">
        <p
          class="locale-currency-modal-tabs-item"
          @click="tabValue = 'locale'"
          :class="{ 'is-selected': tabValue === 'locale' }"
        >
          {{ $t('language') }}
        </p>
        <p
          v-show="!isUpDownPage"
          class="locale-currency-modal-tabs-item"
          @click="tabValue = 'currency'"
          :class="{ 'is-selected': tabValue === 'currency' }"
        >
          {{ $t('Currency') }}
        </p>
      </div>
    </template>
    <template #body>
      <template v-if="tabValue === 'locale'">
        <div class="lang-wrapper">
          <div
            class="lang-item"
            :class="{ 'lang-item-active': lang === item.value }"
            v-for="item in options"
            :key="item.value"
            @click="selectLang(item.value)"
          >
            {{ item.name }}
          </div>
        </div>
      </template>

      <template v-else>
        <div class="lang-wrapper">
          <div
            class="lang-item"
            :class="{ 'lang-item-active': $currencyVal === item.value }"
            v-for="item in $currency.options"
            :key="item.value"
            @click="selectCurrency(item.value)"
          >
            {{ item.name }} - {{ item.unit }}
          </div>
        </div>
      </template>
    </template>
  </Modal>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'LocaleCurrencyModal',
  data() {
    return {
      tabValue: 'locale',
      lang: this.$lang.getLang(),
      upDownLangs: ['en', 'ko', 'ja']
    };
  },
  computed: {
    ...mapState(['localeCurrencyModalShow']),
    isUpDownPage() {
      return this.$route.name === 'SecondsTrade'
    },
    options() {
      if (this.isUpDownPage) {
        return this.$langOptions.filter(item => this.upDownLangs.includes(item.value))
      } else {
        return this.$langOptions
      }
    },
  },
  watch: {
    isUpDownPage: {
      handler(bol) {
        if (bol && !this.upDownLangs.includes(this.lang)) {
          this.selectLang('en');
          this.tabValue = 'locale';
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations(['setLocaleCurrencyModalShow']),
    selectLang(e) {
      this.lang = e;
      this.$lang.setLangAndRefresh(e);
      this.handleInput(false)
    },
    selectCurrency(val) {
      this.$currency.setCurrency(val);
      this.handleInput(false)
    },
    handleInput(e) {
      if (!e) {
        this.setLocaleCurrencyModalShow(false)
      }
    }
  }
}
</script>

<style lang="scss">
.locale-currency-modal {
  z-index: $overlay-index + 1;

  &-tabs {
    @include flex;

    &-item {
      cursor: pointer;
      color: $gray;
      line-height: 0.38rem;
      position: relative;

      &:not(:first-of-type) {
        margin-inline-start: 0.45rem;
      }

      &.is-selected {
        color: var(--color);

        &::after {
          display: block;
          content: '';
          width: 90%;
          height: 0.02rem;
          background-color: $main-color;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }

  .lang-wrapper {
    @include flex(space-between, center, wrap);

    .lang-item {
      width: 33%;
      line-height: 0.4rem;
      border-radius: 0.06rem;
      cursor: pointer;
      padding: 0 0.24rem;
      margin-bottom: 0.04rem;

      &-active,
      &:hover {
        background-color: var(--gray2);
        color: $main-color;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .lang-wrapper .lang-item {
      width: 49%;
    }
  }

  @media screen and (max-width: 600px) {
    .lang-wrapper {
      .lang-item {
        width: 100%;
      }
    }
  }
}
</style>
