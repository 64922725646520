<template>
  <div>
    <div class="header-wallet-info">
      <div class="top">
        <p>{{ $t('AssetsManage.portfolio_value_1') }} (USDT)</p>

        <SvgIcon class="eye-icon" v-if="walletHide" name="eye-invisible" @click="switchWalletHide" />
        <SvgIcon class="eye-icon" v-else name="eye" @click="switchWalletHide" />
      </div>

      <template v-if="walletHide">
        <p class="btc-value">******</p>

        <p class="fiat-value">******</p>
      </template>

      <template v-else>
        <p class="btc-value">{{ numTrim(secondsAsset.balance, 2) }}</p>
      </template>
    </div>

    <Space :size="8" :wrapItem="false">
      <RouterLink to="#" @click.native="translate()">
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
          <path d="M11.5352 1.37695V6.72433H1.91016V9.9322H20.0918L11.5352 1.37695ZM10.4654 20.627V15.2796H20.0904V12.0717H1.91016L10.4654 20.627Z" fill="#4D77FF"/>
        </svg>

        <span style="color: #fff">{{ $t('transfer') }}</span>
      </RouterLink>
    </Space>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import walletMixin from 'utils/mixins/wallet'
import { openTransferModal } from '@/utils/mitt/secondsTransfer';

export default {
  mixins: [walletMixin],
  computed: {
    ...mapState('user', ['secondsAsset']),
  },
  methods: {
    ...mapActions('user', ['getAssets']),
    ...mapActions('market', ['getSymbols', 'listenPrices']),
    translate() {
      openTransferModal();
    }
  },
  async created() {
    ['getAssets', 'getSymbols'].forEach(item => {
      if (item === 'getSymbols') {
        this[item]().then(() => {
          this.listenPrices()
        })
      } else {
        this[item]()
      }
    })
  }
}
</script>