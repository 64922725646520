<template>
  <Popover 
    class="header-dropdown" 
    trigger="hover" 
    :closeDelay="10"
    :offset="0"
    popperClass="header-nav-popover"
    ref="popoverRef"
  >
    <p class="header-label">
      {{ data.title }}
    </p>

    <template slot="popper">
      <template v-if="data.type === 'more'">
        <div v-for="item in data.children" :key="item.name">
          <p class="header-nav-popper-item-title">
            {{ item.title }}
          </p>

          <div class="header-nav-popper-item-list">
            <NavItem @click="clickLink" :data="h" v-for="(h, i) in item.children" :key="i" />
          </div>
        </div>
      </template>

      <template v-else>
        <NavItem @click="clickLink" v-for="item in data.children" :data="item" :key="item.name" />
      </template>
    </template>
  </Popover>
</template>

<script>
import Popover from 'components/common/popover'
import NavItem from './nav-item.vue';

export default {
  components: {
    NavItem,
    Popover
  },
  props: {
    data: Object
  },
  methods: {
    clickLink() {
      this.$refs['popoverRef'].doToggle();
    }
  }
};
</script>

<style lang="scss">
.header-nav-popover {
  $width: 3rem;
  border-radius: 0 0 .04rem .04rem;
  --bc-popover-bg: var(--popper-bc);

  .header-nav-popper-item-list {
    @include flex(flex-start, stretch, wrap);
    width: $width * 2;
    margin: 0.05rem 0;
  }

  .header-nav-popper-item {
    padding: 0.16rem;
    width: $width;
    @include flex(flex-start, flex-start);
    cursor: pointer;
    min-height: 0.8rem;

    &-title {
      line-height: 0.3rem;
      background-color: var(--gray2);
      padding: 0 0.16rem;
      font-weight: 400;
      font-size: 0.14rem;
    }

    .icon {
      width: 0.3rem;
      height: 0.3rem;
      @include marginRow(0, 0.16rem);
    }

    &-content {
      width: calc(100% - 0.46rem);
      .name {
        font-size: 0.14rem;
        font-weight: 500;
        line-height: 0.22rem;
        color: var(--color);
      }

      .desc {
        color: $gray;
        font-size: 0.12rem;
        line-height: 0.2rem;
      }
    }

    &:hover {
      background-color: var(--gray2);

      .name {
        color: $main-color;
      }
    }

    .beta {
      display: inline-block;
      padding: 0 0.03rem;
      height: 0.18rem;
      background-color: $white;
      border-radius: 0.04rem;
      transform: translateY(-50%);
      @include marginRow(0.04rem);
      line-height: 0.18rem;
      background-color: $main-color;
      color: $white;

      span {
        font-size: 0.12rem;
        transform: scale(0.85);
        font-weight: 500;
      }
    }
  }
}
</style>