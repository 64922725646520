/**
 * 利用 localStorage 缓存用户信息，交易对信息，深度信息，历史交易信息...
 * */
import Storage from 'utils/StorageX';
import Singleton from 'utils/Singleton';

// 不使用 cookie
const storage = new Storage();

storage.delete(1);

const keys = {
  header: 'header',
  footer: 'footer',
  userInfo: 'userInfo',
  inviteInfo: 'inviteInfo',
  symbols: 'symbols',
  symbolClasses: 'symbolClasses',
  quoteAssets: 'quoteAssets',
  deals: 'deals',
  depthMap: 'depthMap',
  theme: 'Theme',
  walletHide: 'walletHide',
  assetSearchHistory: 'bc_asset_search_history'
};

function reduceFr(fn, key) {
  return Singleton.promise(
    () =>
      new Promise((res) => {
        setTimeout(() => {
          fn();
          res();
        }, 1000);
      }),
    key,
  );
}

export default class StoreToStorage {
  /**
   * @param obj
   * @param token 授权token
   * */
  static setUser(obj = {}, token) {
    if (token) {
      reduceFr(() => storage.set(keys.userInfo, { [token]: obj }), keys.userInfo);
    }
  }

  static getUser(token) {
    if (!token) return {};
    return (storage.get(keys.userInfo) || {})[token] || {};
  }

  static setInvite(obj = {}, token) {
    if (token) {
      reduceFr(() => storage.set(keys.inviteInfo, { [token]: obj }), keys.inviteInfo);
    }
  }

  static getInvite(token) {
    if (!token) return {};
    return (storage.get(keys.inviteInfo) || {})[token] || {};
  }

  static setHeader(data = []) {
    reduceFr(() => storage.set(keys.header, data), keys.header);
  }

  static getHeader() {
    return storage.get(keys.header);
  }

  static setFooter(obj = {}) {
    reduceFr(() => storage.set(keys.footer, obj), keys.footer);
  }

  static getFooter() {
    return storage.get(keys.footer);
  }

  static setSymbols(obj = {}) {
    reduceFr(() => storage.set(keys.symbols, obj), keys.symbols);
  }

  static getSymbols() {
    return storage.get(keys.symbols);
  }

  static setSymbolClasses(obj = []) {
    reduceFr(() => storage.set(keys.symbolClasses, obj), keys.symbolClasses);
  }

  static getSymbolClasses() {
    return storage.get(keys.symbolClasses);
  }

  static setQuoteAssets(obj = []) {
    reduceFr(() => storage.set(keys.quoteAssets, obj), keys.quoteAssets);
  }

  static getQuoteAssets() {
    return storage.get(keys.quoteAssets);
  }

  static setDeals(obj = {}) {
    reduceFr(() => storage.set(keys.deals, obj), keys.deals);
  }

  static getDeals() {
    return storage.get(keys.deals);
  }

  static setDepthMap(obj = {}) {
    reduceFr(() => storage.set(keys.depthMap, obj), keys.depthMap);
  }

  static getDepthMap() {
    return storage.get(keys.depthMap);
  }

  static setTheme(theme) {
    reduceFr(() => storage.set(keys.theme, theme), keys.theme);
  }

  static getTheme() {
    return storage.get(keys.theme);
  }

  static setWalletHide(value) {
    reduceFr(() => storage.set(keys.walletHide, value), keys.walletHide);
  }

  static getWalletHide() {
    return storage.get(keys.walletHide) || false;
  }

  static setAssetSearchHistory(value) {
    const data = this.getAssetSearchHistory()

    const index = data.findIndex(item => item === value)
    if (index > -1) {
      data.splice(index, 1)
    }

    data.unshift(value)

    storage.set(keys.assetSearchHistory, data.slice(0, 10))
  }

  static getAssetSearchHistory() {
    return (storage.get(keys.assetSearchHistory) || []).slice(0, 10);
  }
}
