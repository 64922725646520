"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calcMaxHoldCont = exports.calcMmr = exports.calcMaxLvg = exports.calcPositionPnlRate = exports.calcShortPl = exports.calcLongPl = void 0;
var big_1 = require("utils/big");
function calcLongPl(openPrice, targetPrice, cont, contract) {
    return (0, big_1.bigNum)(targetPrice)
        .minus(openPrice)
        .times(cont)
        .times(contract.faceValue);
}
exports.calcLongPl = calcLongPl;
function calcShortPl(openPrice, targetPrice, cont, contract) {
    return (0, big_1.bigNum)(openPrice)
        .minus(targetPrice)
        .times(cont)
        .times(contract.faceValue);
}
exports.calcShortPl = calcShortPl;
function calcPositionPnlRate(floatingPnl, oim) {
    if (oim === 0) {
        return '0';
    }
    return (0, big_1.bigNum)(floatingPnl).div(oim).times(100).toFixed(2, 0);
}
exports.calcPositionPnlRate = calcPositionPnlRate;
/** 根据风险等级计算最大杠杆 */
function calcMaxLvg(level, imr, incrImr) {
    return Math.floor(1 / (imr + (level - 1) * incrImr));
}
exports.calcMaxLvg = calcMaxLvg;
/** 根据风险等级计算保证金率 */
function calcMmr(level, mmr, riskIncrMmr) {
    return (0, big_1.bigNum)(level).minus(1).times(riskIncrMmr).plus(mmr);
}
exports.calcMmr = calcMmr;
/**
 * 根据等级计算最大可持仓张数
 * @param riskBaseVol 风险限额基础张数
 * @param riskIncrVol 风险限额递增张数
 */
function calcMaxHoldCont(level, riskBaseVol, riskIncrVol) {
    return (0, big_1.bigNum)(level).minus(1).times(riskIncrVol).plus(riskBaseVol).toFixed(0, 0);
}
exports.calcMaxHoldCont = calcMaxHoldCont;
