<template>
  <Popover
    class="header-dropdown"
    :referenceArrow="false"
    placement="bottom-end"
    trigger="hover"
    :closeDelay="0"
    :offset="0"
    popperClass="header-nav-popover header-download-popover"
  >
    <div class="header-icon-wrap">
      <SvgIcon class="header-icon" name="download" />
    </div>

    <template slot="popper">
      <div class="qrcode-wrapper">
        <img :src="qrCode" class="qrcode" alt="" />

        <div class="logo-wrapper">
          <img class="bit-logo" :src="require('assets/bit_logo.png')" alt="" />
        </div>
      </div>

      <p class="download-text">{{ $t('scan_download_app') }}</p>
    </template>
  </Popover>
</template>

<script>
import Popover from 'components/common/popover'
import { QrCode } from 'utils/Utils';

export default {
  components: {
    Popover
  },
  data() {
    return {
      qrCode: ''
    };
  },
  created() {
    QrCode('https://www.biconomy.com/download', {
      margin: 0,
      width: 100,
      errorCorrectionLevel: 'Q'
    }).then((res) => {
      this.qrCode = res;
    });
  }
};
</script>

<style lang="scss">
.header-download-popover {
  padding: 0.12rem;

  .qrcode-wrapper {
    position: relative;
    background-color: $gray-F7;
    border-radius: 0.06rem;
    padding: 0.1rem;
    width: 1.2rem;
    height: 1.2rem;

    .qrcode {
      margin: 0 auto;
      display: block;
      width: 1rem;
      height: 1rem;
    }

    .logo-wrapper {
      padding: 0.04rem;
      border-radius: 0.06rem;
      background-color: $white;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      height: 0.28rem;
    }

    .bit-logo {
      width: 0.2rem;
      height: 0.2rem;
    }
  }

  .download-text {
    text-align: center;
    margin-top: 0.16rem;
    width: 1.2rem;
    font-weight: 500;
  }
}
</style>