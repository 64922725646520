"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var enum_1 = require("@/types/futures/enum");
var Store = require("store");
var keys = {
    futuresFavorites: 'bc-futures-favorites',
    uint: 'bc-futures-uint'
};
var FuturesStore = /** @class */ (function () {
    function FuturesStore() {
    }
    FuturesStore.getFuturesFavorites = function () {
        return (Store.get(keys.futuresFavorites) || []).filter(function (item) { return !!item; });
    };
    FuturesStore.delFuturesFavorites = function (symbol) {
        if (!symbol)
            return;
        var list = this.getFuturesFavorites() || [];
        var index = list.findIndex(function (item) { return item === symbol; });
        if (index > -1) {
            list.splice(index, 1);
        }
        Store.set(keys.futuresFavorites, list);
    };
    FuturesStore.addFuturesFavorites = function (symbol) {
        this.delFuturesFavorites(symbol);
        var list = this.getFuturesFavorites() || [];
        list.unshift(symbol);
        Store.set(keys.futuresFavorites, list);
    };
    FuturesStore.clearFuturesFavorites = function () {
        Store.set(keys.futuresFavorites, []);
    };
    FuturesStore.getFuturesUint = function () {
        var uint = Store.get(keys.uint);
        if (uint in enum_1.Uint) {
            return uint;
        }
        return enum_1.Uint.Cont;
    };
    FuturesStore.setFuturesUint = function (uint) {
        Store.set(keys.uint, uint);
    };
    return FuturesStore;
}());
exports.default = FuturesStore;
