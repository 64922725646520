<template>
  <transition name="bc-message-fade" @after-leave="handleAfterLeave">
    <div
      :class="[
        'bc-message',
        type ? `bc-message--${ type }` : '',
        center ? 'is-center' : '',
        showClose ? 'is-closable' : '',
        customClass
      ]"
      :style="positionStyle"
      v-show="visible"
      role="alert">

      <div 
        class="bc-message-wrapper" 
        @mouseenter="clearTimer"
        @mouseleave="startTimer"
      >
        <template v-if="type === 'info'">
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="bc-message__icon"
          >
            <path
              d="M4.87165 4.87172C6.44059 3.30282 8.60657 2.33331 10.9999 2.33331C13.3933 2.33331 15.5593 3.30282 17.1282 4.87172C18.6971 6.44065 19.6666 8.60663 19.6666 11C19.6666 13.3933 18.6971 15.5593 17.1282 17.1282C15.5592 18.6971 13.3933 19.6666 10.9999 19.6666C8.60657 19.6666 6.44059 18.6971 4.87166 17.1282C3.30276 15.5593 2.33325 13.3933 2.33325 11C2.33325 8.60663 3.30275 6.44065 4.87165 4.87172ZM4.87165 4.87172L4.5181 4.51817L4.87165 4.87172Z"
              fill="#00BE7B"
              stroke="#00BE7B"
              stroke-linejoin="round"
            />
            <path
              d="M7.33325 11L10.0833 13.75L15.5833 8.25"
              stroke="white"
              stroke-width="1.6"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </template>

        <template v-if="type === 'error'">
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="bc-message__icon"
          >
            <path
              d="M4.87165 4.87172C6.44059 3.30282 8.60657 2.33331 10.9999 2.33331C13.3933 2.33331 15.5593 3.30282 17.1282 4.87172C18.6971 6.44065 19.6666 8.60663 19.6666 11C19.6666 13.3933 18.6971 15.5593 17.1282 17.1282C15.5592 18.6971 13.3933 19.6666 10.9999 19.6666C8.60657 19.6666 6.44059 18.6971 4.87166 17.1282C3.30276 15.5593 2.33325 13.3933 2.33325 11C2.33325 8.60663 3.30275 6.44065 4.87165 4.87172ZM4.87165 4.87172L4.5181 4.51817L4.87165 4.87172Z"
              fill="#F6465D"
              stroke="#F6465D"
              stroke-linejoin="round"
            />
            <path
              d="M14.4343 15.5657C14.7467 15.8781 15.2533 15.8781 15.5657 15.5657C15.8781 15.2533 15.8781 14.7467 15.5657 14.4343L14.4343 15.5657ZM7.56569 6.43431C7.25327 6.1219 6.74673 6.1219 6.43431 6.43431C6.1219 6.74673 6.1219 7.25327 6.43431 7.56569L7.56569 6.43431ZM15.5657 14.4343L7.56569 6.43431L6.43431 7.56569L14.4343 15.5657L15.5657 14.4343Z"
              fill="white"
            />
            <path
              d="M6.43431 14.4343C6.1219 14.7467 6.1219 15.2533 6.43431 15.5657C6.74673 15.8781 7.25327 15.8781 7.56569 15.5657L6.43431 14.4343ZM15.5657 7.56569C15.8781 7.25327 15.8781 6.74673 15.5657 6.43431C15.2533 6.1219 14.7467 6.1219 14.4343 6.43431L15.5657 7.56569ZM7.56569 15.5657L15.5657 7.56569L14.4343 6.43431L6.43431 14.4343L7.56569 15.5657Z"
              fill="white"
            />
          </svg>
        </template>

        <slot>
          <p v-if="!dangerouslyUseHTMLString" class="bc-message__content">{{ message }}</p>
          <p v-else v-html="message" class="bc-message__content"></p>
        </slot>

        <SvgIcon
          v-if="showClose"
          name="clear"
          class="bc-message__closeBtn"
          @click="close"
        />
      </div>
    </div>
  </transition>
</template>

<script type="text/babel">
  const typeMap = {
    success: 'success',
    info: 'info',
    warning: 'warning',
    error: 'error'
  };

  export default {
    data() {
      return {
        visible: false,
        message: '',
        duration: 3000,
        type: 'info',
        iconClass: '',
        customClass: '',
        onClose: null,
        showClose: false,
        closed: false,
        verticalOffset: 20,
        timer: null,
        dangerouslyUseHTMLString: false,
        center: false
      };
    },

    computed: {
      positionStyle() {
        return {
          'top': `${ this.verticalOffset }px`
        };
      }
    },

    watch: {
      closed(newVal) {
        if (newVal) {
          this.visible = false;
        }
      }
    },

    methods: {
      handleAfterLeave() {
        this.$destroy(true);
        this.$el.parentNode.removeChild(this.$el);
      },

      close() {
        this.closed = true;
        if (typeof this.onClose === 'function') {
          this.onClose(this);
        }
      },

      clearTimer() {
        clearTimeout(this.timer);
      },

      startTimer() {
        if (this.duration > 0) {
          this.timer = setTimeout(() => {
            if (!this.closed) {
              this.close();
            }
          }, this.duration);
        }
      },
      keydown(e) {
        if (e.keyCode === 27) { // esc关闭消息
          if (!this.closed) {
            this.close();
          }
        }
      }
    },
    mounted() {
      this.startTimer();
      document.addEventListener('keydown', this.keydown);
    },
    beforeDestroy() {
      document.removeEventListener('keydown', this.keydown);
    }
  };
</script>
