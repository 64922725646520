const LangMap = {
  'en': {
    name: 'English',
    module: () => import('@/locale/en/index' /* webpackChunkName: "Lang-en" */),
  },
  'ko': {
    name: '한국어',
    module: () => import('@/locale/ko/index' /* webpackChunkName: "Lang-ko" */),
  },
  // 西班牙语
  'es-es': {
    name: 'Español (España)',
    module: () => import('@/locale/es' /* webpackChunkName: "Lang-es" */),
  },
  // 俄语
  'ru': {
    name: 'Pусский',
    module: () => import('@/locale/ru/index' /* webpackChunkName: "Lang-ru" */),
  },
  'ja': {
    name: '日本語',
    module: () => import('@/locale/ja/index' /* webpackChunkName: "Lang-ja" */),
  },
  // 葡萄牙语
  'pt-PT': {
    name: 'Português',
    module: () => import('@/locale/pt/index' /* webpackChunkName: "Lang-pt" */),
  },
  'tr': {
    name: 'Türkçe',
    module: () => import('@/locale/tr/index' /* webpackChunkName: "Lang-tr" */),
  },
  'zh-hans': {
    name: '简体中文',
    module: () => import('@/locale/zh-hans/index' /* webpackChunkName: "Lang-zh-hans" */),
  },
  'zh-hant': {
    name: '繁體中文',
    module: () => import('@/locale/zh-hant/index' /* webpackChunkName: "Lang-zh-hant" */),
  },
  // 阿拉伯语
  'ar': {
    name: 'العربية',
    module: () => import('@/locale/ar/index' /* webpackChunkName: "Lang-ar" */),
  },
  // 马来语
  'ms': {
    name: 'Melayu',
    module: () => import('@/locale/ms/index' /* webpackChunkName: "Lang-ma" */),
  },
  // 印地语
  'hi': {
    name: 'हिन्दी',
    module: () => import('@/locale/hi' /* webpackChunkName: "Lang-hi" */),
  },
  // 伊朗语-波斯语
  'fa': {
    name: 'فارسی',
    module: () => import('@/locale/fa' /* webpackChunkName: "Lang-fa" */),
  },
  'de': {
    name: 'Deutsch',
    module: () => import('@/locale/de' /* webpackChunkName: "Lang-de" */),
  },
  'it': {
    name: 'Italiano',
    module: () => import('@/locale/it' /* webpackChunkName: "Lang-it" */),
  },
  'vi-VN': {
    name: 'Tiếng Việt',
    module: () => import('@/locale/vi-VN' /* webpackChunkName: "Lang-vi-VN" */),
  },
};

const langKeys = Object.freeze(Object.keys(LangMap));

const Langs = Object.freeze(langKeys.map((key) => ({ name: LangMap[key].name, value: key })));

export { LangMap, langKeys, Langs };
