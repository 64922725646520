import axios from 'axios';
import config from 'config/config';
import { AuthToken } from 'data/api/auth-token';
import { convertToFormData } from '../RequestDeal.js';
import { LangStore } from 'extensions/i18n';

const http = axios.create({
  baseURL: config.secondsTradeUrl,
  //withCredentials: true,
  validateStatus: (status) => (status >= 200 && status < 300) || status >= 400,
});

http.interceptors.request.use(
  (config) => {
    const token = AuthToken.getToken()
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

http.interceptors.response.use(
  (response) => {
    const { code, data, message } = response.data;

    if (code === 200) {
      return data;
    }

    if (code === 10006 || code === 10005) {
      // 使用 window.location 进行页面跳转
      window.location.href = '/'; // 替换为你的错误页面路径
    }

    const error = new Error(message);
    error.statusCode = response.status;
    error.resCode = code;
    error.config = response.config;
    throw error;
  },
  (error) => {
    throw error;
  },
);

function post(url, data) {
  return new Promise((resolve, reject) => {
    http({
      method: 'post',
      url,
      data,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function get(url, params) {
  return new Promise((resolve, reject) => {
    http({
      method: 'get',
      url,
      params,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function postForm(url, data) {
  const formData = convertToFormData(data);
  return new Promise((resolve, reject) => {
    http({
      method: 'post',
      url,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export { post, get, postForm };
