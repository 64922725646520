// 事件总线
import Message from 'components/common/message'

const BusPlugin = {};
BusPlugin.install = (Vue) => {
  // snackbar 拦截器
  const snackBarInterceptor = (msg) => msg && +msg.statusCode !== 401 && +msg.statusCode !== 400 && +msg.statusCode !== 426;
  // msg && +msg.statusCode !== 401 && +msg.statusCode !== 400 && +msg.statusCode !== 426 && +msg.resCode !== 2;

  // snackbar 组件
  Vue.prototype.snackBar = {
    info(msg) {
      if (snackBarInterceptor(msg)) Message.info(msg.message || msg);
    },
    error(msg) {
      if (snackBarInterceptor(msg)) Message.error(msg.message || msg);
    },
  };
};

export default BusPlugin;
