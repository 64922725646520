export function isMobile() {
  const ua = typeof window !== 'undefined' ? navigator.userAgent : '';
  const reg = /Android|webOS|iPhone|iPod|iPad|BlackBerry|Windows Phone/i;
  return reg.test(ua);
}

export function isWeiXin() {
  const ua = typeof window !== 'undefined' ? navigator.userAgent : '';
  const reg = /MicroMessenger/i;
  return reg.test(ua);
}

export function isAndroid() {
  const ua = typeof window !== 'undefined' ? navigator.userAgent : '';
  const reg = /(Android|Adr)/i;
  return reg.test(ua);
}

export function isIE() {
  if (!!window.ActiveXObject || 'ActiveXObject' in window) {
    return true;
  }
  return false;
}

export function isIos() {
  const ua = typeof window !== 'undefined' ? navigator.userAgent : '';
  const reg = /\(i[^;]+;( U;)? CPU.+Mac OS X/;
  return reg.test(ua);
}

export function isBiconomy() {
  const ua = typeof window !== 'undefined' ? navigator.userAgent : '';
  const reg = /Biconomy/i;
  return reg.test(ua);
}
