<template>
  <overlay v-show="value" class="header-side-overlay" :lockScroll="false" @click="close">
    <div class="header-side-wrapper" :style="style" ref="wrapper">
      <div class="close-wrapper" @click="close">
        <div class="close-icon-wrapper">
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 1L1 9"
              stroke="#0D1F3C"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M9 9L1 1"
              stroke="#0D1F3C"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>

      <div class="header-side-top">
        <slot name="top"></slot>
      </div>

      <Scrollbar
        class="header-side-content"
        :height="height"
        wrap-style="overflow-x: hidden;"
        always
      >
        <slot name="content"></slot>
      </Scrollbar>

      <slot name="footer"></slot>
    </div>
  </overlay>
</template>

<script>
export default {
  props: {
    value: Boolean,
    height: String
  },
  data() {
    return {
      transformStyle: false
    };
  },
  computed: {
    style() {
      if (this.transformStyle) {
        return {
          transform: 'translateX(0)'
        };
      }

      return {
        transform: 'translateX(100%)'
      };
    }
  },
  watch: {
    value(val) {
      if (val) {
        setTimeout(() => {
          this.transformStyle = true;
        }, 0);
      } else {
        this.transformStyle = false;
      }
    },
    '$route.path': {
      handler() {
        this.close();
      }
    }
  },
  methods: {
    close() {
      this.$emit('input', false);
    }
  },
  mounted() {
    // this.$nextTick(() => {
    //   document.body.appendChild(this.$el)
    // })
  }
};
</script>
