<template>
  <SideWrapper class="side-nav" :value="sideShow" :height="sideHeight" @input="input">
    <template slot="content">
      <template v-for="item in nav">
        <NavLink :data="item" v-if="!item.children" class="header-side-item" :key="item.title">
          <p class="header-label">
            {{ item.title }}
          </p>

          <div class="beta" v-if="item.beta">
            <span>BETA</span>
          </div>

          <img
            class="hot-icon"
            src="https://biconomyweb.oss-accelerate.aliyuncs.com/header/hot.svg"
            alt=""
            v-if="item.hot"
          />
        </NavLink>

        <Collapse v-else-if="item.type !== 'more'" :data="item" :key="item.title" />

        <Collapse v-else :data="getData(item)" :key="item.title" />
      </template>

      <div class="line"></div>

      <Lang class="header-side-item header-side-lang">
        <span>{{ $t('language') }}</span>
      </Lang>

      <RouterLink to="/download" class="header-side-download header-side-item">
        <svg
          class="download-icon header-label"
          width="0.3rem"
          height="0.3rem"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 15.0042V24H24V15"
            stroke="#000000"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M19.5 14.5L15 19L10.5 14.5"
            stroke="#000000"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M14.9961 6V19"
            stroke="#000000"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        {{ $t('download') }}
      </RouterLink>
    </template>

    <template slot="footer" v-if="!user.id">
      <RouterLink to="/sign-in" class="sign-link">
        {{ $t('sign-in') }}
      </RouterLink>

      <RouterLink to="/sign-up" class="sign-link sign-up-link">
        {{ $t('sign-up') }}
      </RouterLink>
    </template>
  </SideWrapper>
</template>

<script>
import SideWrapper from './side-wrapper.vue';
import Collapse from './collapse.vue';
import NavLink from './nav-link.vue';
import Lang from './lang.vue';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'SideNav',
  props: {
    value: Boolean
  },
  components: {
    SideWrapper,
    Collapse,
    NavLink,
    Lang
  },
  data() {
    return {
      sideShow: false
    };
  },
  computed: {
    ...mapGetters('header', ['nav']),
    ...mapState('header', ['mobileHeader']),
    ...mapState('user', {
      user: (state) => state.info
    }),
    sideHeight() {
      if (!this.user.id) {
        return 'calc(100vh - 0.6rem - 0.3rem - 0.46rem - 0.46rem - 0.76rem)';
      }

      return 'calc(100vh - 0.6rem - 0.3rem - 0.46rem - 0.46rem)';
    }
  },
  watch: {
    value: {
      handler(val) {
        this.sideShow = val;
      },
      immediate: true
    },
    mobileHeader: {
      handler(val) {
        if (!val) {
          this.input(false);
        }
      },
      immediate: true
    }
  },
  methods: {
    getData(data) {
      const children = data.children.reduce((pre, item) => {
        item.children.forEach((h) => {
          pre.push(h);
        });

        return pre;
      }, []);

      return {
        ...data,
        children
      };
    },
    input(val) {
      if (this.value !== val) {
        this.$emit('input', val);
      }
    }
  }
};
</script>
