<template>
  <svg class="bc-svg-icon" :class="`bc-icon-${name}`" aria-hidden="true" role="img" @click="click">
    <use :xlink:href="symbolId"></use>
  </svg>
</template>

<script>
export default {
  name: 'SvgIcon',
  props: {
    name: {
      type: String,
      required: true
    }
  },
  computed: {
    symbolId() {
      return `#icon-${this.name}`;
    }
  },
  methods: {
    click(e) {
      this.$emit('click', e);
    }
  }
};
</script>

<style lang="scss">
.bc-icon-loading {
  animation: loadingCircle 1s infinite linear;
  vertical-align: middle;

  @keyframes loadingCircle {
    to {
      transform: rotate(360deg);
    }
  }
}
</style>