import Big from 'big.js';

export function bigNum(val) {
  return new Big(val || 0);
}

export function deleteDecimalZero(num) {
  if (isNaN(num)) return '0';

  const val = String(num);
  return val.replace(/(\.\d*?[1-9])0+$/g, '$1').replace(/\.0*$/g, '');
}

// 取数字精度，并将末尾的 '0' 去掉，如 this.numTrim(3.100001, 3) => '3.1'
export function numTrim(val, toFixed, clearZero = true) {
  let value = val;
  if (toFixed <= 0) return Math.floor(+value).toString();
  if (!+val) value = '0';

  let num = bigNum(value).toFixed();

  let arr = num.split('.');

  if (arr[1]) {
    arr[1] = arr[1].slice(0, toFixed);
  }

  if (!clearZero) {
    if (arr[1] === undefined) {
      arr[1] = '';
    }

    while (arr[1].length < toFixed) {
      arr[1] += '0';
    }
  }

  num = arr.join('.');

  if (clearZero) {
    num = deleteDecimalZero(num);
  }

  return num;
}

// 数值千分号转换，如： 321566 => '321,566'; 321566.3553 => '321,566.3553'
export function c2ThousandsCount(num) {
  const val = String(num);
  const cells = val.match(/^(-?)(\d*)(\.(\d+))?$/);

  if (!cells) {
    return val;
  } else {
    const negative = cells[1];
    let int = cells[2] || '0';
    let decimal = cells[4] || '';

    if (decimal) {
      decimal = `.${decimal}`;
    }

    int = int.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return negative + int + decimal;
  }
}

export function numFormat(num, toFixed = 2) {
  if (!num) return '';
  const val = +num;
  if (val >= 1e9) {
    return numTrim(bigNum(val).div(1e9), 2) + 'B';
  } else if (val >= 1e6) {
    return numTrim(bigNum(val).div(1e6), 2) + 'M';
  } else if (val >= 1e3) {
    return numTrim(bigNum(val).div(1e3), 2) + 'K';
  } else {
    return numTrim(val, toFixed);
  }
}