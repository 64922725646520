"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Socket = /** @class */ (function () {
    function Socket(url, mitt) {
        this.url = '';
        this.socket = null;
        this.connectPromise = null;
        this.mitt = null;
        this.requestMap = new Map();
        this.subscribeMap = new Map();
        this.heartTimer = null;
        this.url = url;
        this.mitt = mitt;
    }
    Socket.prototype.connect = function () {
        var _this = this;
        if (this.connectPromise === null) {
            this.connectPromise = new Promise(function (resolve, reject) {
                if ('WebSocket' in window) {
                    _this.socket = new WebSocket(_this.url);
                }
                else if ('MozWebSocket' in window) {
                    _this.socket = new window.MozWebSocket(_this.url);
                }
                else {
                    reject(new Error('---- Socket: Your browser does not support WebSocket !'));
                }
                _this.socket.addEventListener('open', function () {
                    _this.heartbeat();
                    resolve(_this.socket);
                });
                _this.socket.addEventListener('error', function () {
                    _this.errorHandler();
                });
                _this.socket.addEventListener('close', function () {
                    _this.closeHandler();
                });
                _this.socket.addEventListener('message', function (data) {
                    _this.messageHandler(data);
                });
            });
        }
        return this.connectPromise;
    };
    Socket.prototype.errorHandler = function () {
        var _this = this;
        this.connectPromise = null;
        setTimeout(function () {
            _this.reconnect();
        }, 2000);
    };
    Socket.prototype.closeHandler = function () {
        var _this = this;
        // if (this.heartTimer) clearInterval(this.heartTimer);
        this.connectPromise = null;
        setTimeout(function () {
            _this.reconnect();
        }, 2000);
    };
    Socket.prototype.reconnect = function () {
        var _this = this;
        // 开始重连
        this.requestMap.forEach(function (data) {
            _this.send(data);
        });
        this.subscribeMap.forEach(function (data) {
            _this.send(data);
        });
    };
    Socket.prototype.messageHandler = function (data) {
        var res = JSON.parse(data.data);
        if (res.channel !== 'pong') {
            this.mitt.emit(res.channel, res);
        }
    };
    Socket.prototype.heartbeat = function () {
        var _this = this;
        if (this.heartTimer)
            clearInterval(this.heartTimer);
        this.heartTimer = setInterval(function () {
            _this.send({
                method: 'ping'
            });
        }, 30000);
    };
    Socket.prototype.send = function (data) {
        this.connect().then(function (socket) {
            socket.send(JSON.stringify(data));
        });
    };
    Socket.prototype.request = function (data) {
        var _this = this;
        var sendData = {
            method: data.method,
            params: data.params
        };
        this.requestMap.set(data.channel, sendData);
        this.send(sendData);
        return new Promise(function (resolve) {
            _this.mitt.on(data.channel, function (res) {
                resolve(res);
                _this.requestMap.delete(data.channel);
                _this.mitt.off(data.channel);
            });
        });
    };
    Socket.prototype.subscribe = function (data) {
        var sendData = {
            method: data.method,
            params: data.params
        };
        this.subscribeMap.set(data.channel, sendData);
        this.send(sendData);
    };
    Socket.prototype.unsubscribe = function (data) {
        this.subscribeMap.delete(data.channel);
        this.send({
            method: data.method,
            params: data.params
        });
    };
    return Socket;
}());
exports.default = Socket;
