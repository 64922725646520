<template>
  <NavLink class="header-nav-popper-item" :data="data" @click="$emit('click')">
    <img class="icon" :src="icon" alt="" />

    <div class="header-nav-popper-item-content">
      <div class="name">
        {{ data.name }}

        <div class="beta" v-if="data.beta">
          <span>BETA</span>
        </div>

        <img
          class="hot-icon"
          src="https://biconomyweb.oss-accelerate.aliyuncs.com/header/hot.svg"
          alt=""
          v-if="data.hot"
        />
      </div>
      <p class="desc">
        {{ data.desc }}
      </p>
    </div>
  </NavLink>
</template>

<script>
import NavLink from './nav-link.vue';

export default {
  name: 'NavItem',
  props: {
    data: Object
  },
  components: {
    NavLink
  },
  computed: {
    icon() {
      return this.theme === 'dark' ? this.data.dark_icon : this.data.light_icon;
    }
  }
};
</script>
