<template>
  <div class="container not-found">
    <h1>404</h1>
    <p>{{ $t('not-found') }}</p>
    <a class="back btn btn-blue" href="https://www.biconomy.com" style="display: block">
      {{ $t('back') }}
    </a>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
  beforeMount() {
    document.title = '404';
  }
};
</script>
