import { AuthToken } from 'data/api/auth-token';
import { LangStore } from 'extensions/i18n';
import NotFound from 'pages/NotFound';
import Vue from 'vue';
import Router from 'vue-router';
import Frame from '../page-zt/client/Frame';
import { remInit } from '$router/ThemeComponents';
import { isBiconomy } from 'utils/UserAgent';
import { getAppToken } from 'utils/bridge.js'
import { USER } from 'data/store/mutations-types';

const Home = () => import('pages/client/home/home' /* webpackChunkName:"Home" */);
// 商务合作
const Listings = () => import('pages/client/business/listings' /* webpackChunkName:"Listings" */);
const Accelerator = () => import('pages/client/business/accelerator' /* webpackChunkName:"Accelerator" */);
// 市场
const Markets = () => import('pages/client/markets/page' /* webpackChunkName:"Markets" */);
// 信用卡买币
// const Credit = () => import('pages/client/pagsmile' /* webpackChunkName:"Credit" */);
const Payments = () => import('pages/client/payments' /* webpackChunkName:"Payments" */);

// Invite Friends
const Invitation = () => import('pages/client/invitation/invitation' /* webpackChunkName:"Invitation" */);

const SignIn = () => import('../pages/client/sign-in/sign-in' /* webpackChunkName:"SignIn" */);
const SignUp = () => import('../pages/client/sign-up/sign-up' /* webpackChunkName:"SignUp" */);
const ForgotPassword = () => import('../pages/client/forgot-password/forgot-password' /* webpackChunkName:"ForgotPassword" */);

const UserCenter = () => import('../page-zt/client/user-center.vue' /* webpackChunkName:"UserCenter" */);

const APIManagement = () => import('pages/user-center/api-management' /* webpackChunkName:"APIManagement" */);

const about = () => import('../pages/client/about/about');
const Address = () => import('../pages/client/address/index.vue' /* webpackChunkName:"Address" */);
const DownLoad = () => import('../pages/client/downLoad/downLoad' /* webpackChunkName:"DownLoad" */);
const Deposit = () => import('pages/user-center/deposit' /* webpackChunkName:"Deposit" */);
const Withdraw = () => import('pages/user-center/withdraw' /* webpackChunkName:"WithDraw" */);
const BrandKit = () => import('../pages/client/BrandKit/BrandKit' /* webpackChunkName:"BrandKit" */);
const Launchpad = () => import('../pages/client/Launchpad/Launchpad' /* webpackChunkName:"Launchpad" */);
const launchpadDetail = () => import('../pages/client/Launchpad/launchpadDetail' /* webpackChunkName:"launchpadDetail" */);
const launchpadPlut = () => import('../pages/client/Launchpad/LaunchpadPlut' /* webpackChunkName:"launchpadPlut" */);
const Novice = () => import('../pages/client/Novice/Novice' /* webpackChunkName:"Novice" */);

const ActPage = () => import('pages/client/actPage/actPage' /* webpackChunkName:"ActPage" */);
const ambassadorPage = () => import('pages/client/ambassadorPage/ambassadorPage' /* webpackChunkName:"ambassadorPage" */);
const unionReferral = () => import('pages/client/invitation/union-referral' /* webpackChunkName:"union-referral" */);
const Dao = () => import('pages/client/Dao' /* webpackChunkName:"Dao" */);
const DaoDetail = () => import('pages/client/Dao/detail' /* webpackChunkName:"DaoDetail" */);
// const DaoProposal = () => import('pages/client/Dao/proposal' /* webpackChunkName:"DaoProposal" */);
const DaoList = () => import('pages/client/Dao/list' /* webpackChunkName:"DaoList" */);
const Nft = () => import('pages/client/nft' /* webpackChunkName:"Nft" */);
const NftCollection = () => import('pages/client/nft/collection' /* webpackChunkName:"NftCollection" */);
const NftDetail = () => import('pages/client/nft/detail/index' /* webpackChunkName:"NftDetail" */);
const NftAsset = () => import('pages/client/nft/asset' /* webpackChunkName:"NftAsset" */);
const NftHistory = () => import('pages/client/nft/history' /* webpackChunkName:"NftHistory" */);
const NftInformation = () => import('pages/client/nft/information' /* webpackChunkName:"NftInformation" */);
const NftList = () => import('pages/client/nft/list' /* webpackChunkName:"NftList" */);
const NftWithdraw = () => import('pages/client/nft/withdraw' /* webpackChunkName:"NftWithdraw" */);
// const BusinessVerification = () =>
//   import('../page-zt/client/user-center/business-verification/index' /* webpackChunkName:"BusinessVerification" */);
const live = () => import('pages/client/live' /* webpackChunkName:"live" */);
const Earn = () => import('pages/client/earn' /* webpackChunkName:"earn" */);

const labs = () => import('pages/client/labs/labs');

const UserCenterFrame = () => import('pages/user-center/frame' /* webpackChunkName:"user-center-frame" */);
const CertificationPersonal = () =>
  import('pages/user-center/security/certification/certification' /* webpackChunkName:"CertificationPersonal" */);
const identity = () => import('pages/user-center/security/identity/identity' /* webpackChunkName:"identity" */);
const UserCenterProfile = () => import('pages/user-center/profile/profile' /* webpackChunkName:"user-center-profile" */);
const SecurityCenter = () => import('pages/user-center/security/security-center/index' /* webpackChunkName:"Security" */);
const BindGoogle = () => import('pages/user-center/security/security-center/google-bind');
const AntiPhishing = () => import('pages/user-center/security/anti-phishing');
const ManageAccount = () => import('pages/user-center/security/manage-account');
const DeviceManagement = () => import('pages/user-center/device-management');

const AssetsLayout = () => import('pages/user-center/assets/layout');
const AssetsSpot = () => import('pages/user-center/assets/spot/index');
const AssetsFutures = () => import('pages/user-center/assets/futures/index');
const AssetsOverview = () => import('pages/user-center/assets/overview/index');
const AssetsEarn = () => import('pages/user-center/assets/earn/index.vue');
const AssetsCapitalFlow = () => import('pages/user-center/assets/capital_flow/index.vue');

const BlogHome = () => import('pages/client/blog/index' /* webpackChunkName:"blog-home" */);
const BlogDetail = () => import('pages/client/blog/detail' /* webpackChunkName:"blog-detail" */);
// const Coupons = () => import('pages/client/coupons' /* webpackChunkName:"coupons" */);
const IeoHome = () => import('pages/client/ieo/ieo.vue' /* webpackChunkName:"IeoHome" */);
const IeoDetail = () => import('pages/client/ieo/detail.vue' /* webpackChunkName:"IeoDetail" */);
const Careers = () => import('pages/client/careers' /* webpackChunkName:"Careers" */);
const AntiFake  = () => import('pages/client/anti-fake/anti-fake.vue' /* webpackChunkName:"AntiFake" */);
const Kickstarter = () => import('pages/client/kickstarter/kickstarter.vue' /* webpackChunkName:"Kickstarter" */);
const KickstarterDeatil = () => import('pages/client/kickstarter/detail.vue' /* webpackChunkName:"KickstarterDeatil" */);

const EarnOrder = () => import('pages/user-center/order/earn.vue')
const SpotOrder = () => import('pages/user-center/order/spot/spot.vue')
const FuturesOrder = () => import('pages/user-center/order/futures/futures.vue')
const Fee = () => import('pages/client/fee/fee.vue')

const FuturesInformation = () => import('pages/futures/information/information.vue')
const FuturesBonus = () => import('pages/futures/bonus')

const SecondsTrade = () => import('pages/client/seconds_trade/index.vue')
const PartnerRecruit = () => import('pages/client/partnerRecruit/index.vue')

Vue.use(Router);

const routes = [
  {
    path: '/',
    component: Frame,
    children: [
      {
        path: '',
        name: 'Home',
        meta: { remInit: true },
        component: Home,
      },
      // 信用卡买币
      {
        path: 'buycrypto',
        name: 'buycrypto',
        meta: { remInit: true },
        component: Payments
      },
      // { path: 'exchange', name: 'Exchange', component: Exchange },
      {
        path: 'sign-in',
        name: 'SignIn',
        meta: {
          remInit: true,
        },
        component: SignIn,
      },
      {
        path: 'sign-up',
        name: 'signUp',
        meta: {
          remInit: true,
        },
        component: SignUp,
      },
      {
        path: 'password/reset',
        name: 'resetPassword',
        meta: {
          remInit: true,
        },
        component: ForgotPassword,
      },
      {
        path: 'business/listings',
        meta: {
          remInit: true,
        },
        component: Listings
      },
      { path: 'business/accelerator', component: Accelerator },
      { path: 'markets', component: Markets },
      { path: 'about', component: about },
      {
        path: 'bittoken',
        meta: {
          remInit: true,
        },
        component: ActPage,
      },
      {
        path: 'actpage',
        meta: {
          remInit: true,
        },
        component: ActPage,
      },
      {
        path: 'ambassadorPage',
        meta: {
          remInit: true,
        },
        component: ambassadorPage,
      },
      {
        path: 'Launchpad',
        component: Launchpad,
        meta: {
          remInit: true,
        },
        children: [
          {
            path: 'Launchpad',
            meta: {
              remInit: true,
            },
            component: Launchpad,
          },
          {
            path: 'launchpadDetail',
            meta: {
              remInit: true,
            },
            component: launchpadDetail,
          },
        ],
      },
      {
        path: 'launchpadDetail',
        meta: {
          remInit: true,
        },
        component: launchpadDetail,
      },
      {
        path: 'launchpadPlut',
        meta: {
          remInit: true,
        },
        component: launchpadPlut,
      },
      { path: 'BrandKit', component: BrandKit },
      {
        path: 'Novice',
        meta: {
          remInit: true,
        },
        component: Novice,
      },
      { path: 'union-referral', component: unionReferral },
      {
        path: 'dao',
        component: Dao,
        meta: {
          remInit: true
        }
      },
      { path: 'dao-detail', component: DaoDetail },
      { path: 'live', component: live },
      { path: 'nft', component: Nft },
      { path: 'nft/collection', component: NftCollection },
      { path: 'nft/detail', component: NftDetail },
      { path: 'nft/asset', component: NftAsset, meta: { requireAuth: true } },
      { path: 'nft/history', component: NftHistory, meta: { requireAuth: true } },
      { path: 'nft/information', component: NftInformation, meta: { requireAuth: true } },
      { path: 'nft/list', component: NftList, meta: { requireAuth: true } },
      { path: 'nft/withdraw', component: NftWithdraw, meta: { requireAuth: true } },
      {
        path: 'earn',
        meta: { remInit: true },
        component: Earn,
      },
      { path: 'labs', component: labs },
      {
        path: 'blog',
        name: 'BlogHome',
        meta: {
          remInit: true,
        },
        component: BlogHome,
      },
      {
        path: 'blog/detail',
        redirect: {
          name: 'BlogHome',
        },
      },
      {
        path: 'blog/detail/:id',
        meta: {
          remInit: true,
        },
        component: BlogDetail,
      },
      {
        path: 'download',
        name: 'Download',
        meta: {
          remInit: true,
        },
        component: DownLoad,
      },
      // {
      //   path: 'coupons',
      //   meta: {
      //     remInit: true,
      //   },
      //   component: Coupons,
      // },
      {
        path: '/ieo',
        meta: {
          remInit: true,
        },
        component: IeoHome
      },
      {
        path: '/ieo/:id',
        meta: {
          remInit: true,
        },
        component: IeoDetail,
      },
      {
        path: 'careers',
        meta: {
          remInit: true,
        },
        component: Careers,
      },
      {
        path: 'anti-fake',
        meta: {
          remInit: true,
        },
        component: AntiFake,
      },
      {
        path: '/user-center',
        meta: { requireAuth: true },
        component: UserCenter,
        children: [
          {
            path: 'assets-manage/withdraw',
            name: 'withdraw',
            component: Withdraw,
            meta: { remInit: true },
          },
          {
            path: '/user-center/assets-manage/deposit',
            component: Deposit,
            meta: { remInit: true }
          },
          { path: 'assets-manage/address', component: Address },
          {
            path: 'exchange',
            redirect: {
              name: 'SpotOrder'
            }
          },
          {
            path: 'invitation',
            name: 'invitation',
            meta: {
              remInit: true,
            },
            component: Invitation,
          },
          // { path: 'dao-proposal', component: DaoProposal },
          { path: 'dao-list', component: DaoList },
        ],
      },
      {
        path: '/user-center',
        meta: { requireAuth: true },
        component: UserCenterFrame,
        children: [
          { path: 'profile', component: UserCenterProfile },
          {
            path: 'security',
            component: SecurityCenter,
            name: 'SecurityCenter'
          },
          { path: 'security/bind-verify-google', component: BindGoogle },
          { path: 'security/anti-phishing', component: AntiPhishing },
          { path: 'security/manage-account', component: ManageAccount },
          { path: 'api-management', component: APIManagement, meta: { remInit: true } }
        ],
      },
      {
        path: '/user-center/assets-manage',
        name: 'AssetsManage',
        meta: { requireAuth: true },
        component: AssetsLayout,
        redirect: '/user-center/assets-manage/overview',
        children: [
          { path: 'overview', component: AssetsOverview, meta: { remInit: true } },
          { path: 'spot', component: AssetsSpot, meta: { remInit: true  } },
          { path: 'futures', component: AssetsFutures, name: 'AssetsFutures', meta: { remInit: true  } },
          { path: 'earn', component: AssetsEarn, name: 'earn-asset', meta: { remInit: true  } },
          { path: 'capital-flow', name: 'Transacting', component: AssetsCapitalFlow, meta: { remInit: true  } },
          { path: 'Transacting', redirect: { name: 'Transacting' } },
        ],
      },
      {
        path: '/user-center/security/certification',
        component: CertificationPersonal,
        name: 'Certification',
        meta: { requireAuth: true, remInit: true }
      },
      {
        path: '/user-center/security/identity',
        component: identity,
        meta: { requireAuth: true, remInit: true }
      },
      {
        path: '/user-center/order/earn',
        meta: { requireAuth: true, remInit: true },
        component: EarnOrder,
      },
      {
        path: '/user-center/order/spot',
        meta: { requireAuth: true, remInit: true },
        component: SpotOrder,
        name: 'SpotOrder'
      },
      {
        path: '/user-center/order/futures',
        name: 'FuturesOrder',
        meta: {
          requireAuth: true,
          remInit: true
        },
        component: FuturesOrder
      },
      {
        path: '/user-center/device-management',
        name: 'DeviceManagement',
        meta: {
          requireAuth: true,
          remInit: true
        },
        component: DeviceManagement,
      },
      { path: '/kickstarter', component: Kickstarter, meta: { remInit: true } },
      { path: '/kickstarter/:id', component: KickstarterDeatil, meta: { remInit: true } },
      { path: '/fee', component: Fee, meta: { remInit: true } },
      {
        path: '/futures-information',
        name: 'FuturesInformation',
        component: FuturesInformation,
        meta: {
          remInit: true
        }
      },
      {
        path: '/futures-bonus',
        name: 'FuturesBonus',
        redirect: '/activity'
      },
      {
        path: '/activity',
        name: 'Activity',
        component: FuturesBonus,
        meta: {
          remInit: true
        }
      },
      {
        path: '/up-and-down',
        name: 'SecondsTrade',
        component: SecondsTrade,
        meta: {
          remInit: true,
          hideFooter: true,
          headerName: 'SecondsHeader',
          requireAuth: true
        },
      },
      {
        path: '/partner-recruit',
        name: 'PartnerRecruit',
        component: PartnerRecruit,
        meta: {
          remInit: true,
        },
      },
    ],
  },
  {
    path: '/mobile',
    component: Frame,
    children: [
      {
        path: 'download',
        redirect: {
          name: 'Download',
        },
      },
    ],
  },
  { path: '*', component: NotFound },
];

export function createRouter(i18n, store) {
  const router = new Router({
    mode: 'history',
    routes,
  });

  router.beforeEach(async (to, fr, next) => {
    if (to.path !== fr.path) {
      window.scrollTo(0, 0);
    }

    if (
      !['AssetsFutures', 'FuturesInformation', 'FuturesOrder', 'FuturesBonus'].includes(to.name)
      && (to.path.includes('/exchange') || to.path.includes('/futures'))
    ) {
      window.location.href = to.fullPath;
      return;
    }

    const pro = LangStore.setLang(to.query.lang || i18n.locale, {
      $i18n: i18n,
    });

    // 是否在app里打开的页面
    if (isBiconomy()) {
      const tokenInfo = getAppToken()
      if (tokenInfo) {
        AuthToken.setToken(tokenInfo);
      } else {
        store.commit('user/' + USER.GET_USER_INFO, {})
        AuthToken.setToken('');
      }
    }

    const token = AuthToken.getToken();

    if (['SignIn', 'signUp', 'resetPassword'].includes(to.name) && store.state.user.info.id) {
      next('/user-center/profile')
      return;
    }

    if (['FuturesBonus'].includes(to.name)) {
      await store.dispatch('getArea')
    }

    if (to.matched.some((route) => route.meta.requireAuth) && (!store.state.user.info.id || !token)) {
      // if (to.matched.some((route) => route.meta.requireAuth) && !token) {
      store
        .dispatch('user/getUserInfo')
        .then(() => {
          store.dispatch('user/getSecurity')
          pro.then(() => next());
        })
        .catch(() => {
          pro.then(() =>
            next({
              name: 'SignIn',
              query: { redirectTo: to.fullPath },
            }),
          );
          // Vue.prototype.snackBar.error('请先登录！');
        });
      // next('/sign-in');
    } else {
      if (store.state.user.info.id && token && to.name === 'SecondsTrade') {
        await store.dispatch('seconds/getConfig');
      }
      pro.then(() => next());
    }
  });

  router.afterEach((to) => {
    remInit(to);

    // websocket 实例管理
    // const needKlineSocket = ['Exchange'];
    // const needOtherSocket = ['Home', 'Exchange', 'AssetsManage'];
    // if (!needOtherSocket.includes(to.name)) {
    //   KlineSocket.disconnect();
    // }
    // if (!needKlineSocket.includes(to.name)) {
    //   KlineSocket.disconnect();
    // }
  });

  return router;
}
