"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.futures = void 0;
var common_1 = require("@/data/api/futures/common");
var user_1 = require("data/api/futures/user");
var socket_1 = require("@/data/api/futures/socket");
var vue_1 = require("vue");
var Singleton_1 = require("utils/Singleton");
var futures_1 = require("data/storage/futures");
var common_2 = require("utils/futures/common");
var subUserState = false;
var detailCoverConTract = function (detail, ticker) {
    var data = {
        baseCoinIconUrl: detail.baseCoinIconUrl,
        positionOpenType: detail.pot,
        futureType: detail.ft,
        settleCoin: detail.sc,
        priceScale: detail.ps,
        volScale: detail.vs,
        priceUnit: detail.pu,
        volUnit: detail.vu,
        minVol: detail.minV,
        maxVol: detail.maxV,
        takerFeeRate: detail.tfr,
        makerFeeRate: detail.mfr,
        riskBaseVol: detail.rbv,
        riskIncrVol: detail.riv,
        riskIncrMmr: detail.rim,
        riskIncrImr: detail.rii,
        state: detail.state,
        riskLimitType: detail.rlt,
        marketOrderMaxLevel: detail.moml || 15,
        depthStepList: detail.dsl,
        symbol: detail.symbol,
        symbolName: (0, common_2.getFuturesSymbolName)(detail.symbol),
        showName: detail.dn,
        showNameEn: detail.dne,
        base: detail.bc,
        quote: detail.qc,
        faceValue: detail.cs,
        minLvg: detail.minL,
        maxLvg: detail.maxL,
        mmr: detail.mmr,
        imr: detail.imr,
        riskLevel: detail.rll,
        autoDelivery: 0,
        precision: {
            price: detail.ps,
            amount: detail.vs,
        },
        ticker: ticker,
        bidLimitPriceRate: detail.blpr,
        askLimitPriceRate: detail.alpr,
        indexOrigin: detail.io,
        triggerProtect: detail.tp,
        maxNumOrders: detail.mno
    };
    return data;
};
var state = function () { return ({
    uint: futures_1.default.getFuturesUint(),
    futuresSymbols: {},
    futuresWallet: {
        available: 0,
        bonus: 0, // 体验金
        cur: 'USDT', // 货币
        frozen: 0, // 冻结
        positionMargin: 0, // 仓位保证金
        equity: 0, // 权益
        canWithdraw: 0, // 可提现余饿
        unrealized: 0 // 未实现盈亏
    },
    positions: [],
    futuresWalletList: [],
    futuresBalance: 0,
    futuresFrozenBalance: 0,
    userCouponAmount: 0,
    transferCouponStatus: false,
    futuresFavoriteList: []
}); };
var getters = {
    futuresSymbolList: function (state) {
        return Object.values(state.futuresSymbols).filter(function (item) { return item.state === 0; });
    }
};
var mutations = {
    setFuturesSymbols: function (_state, data) {
        _state.futuresSymbols = data;
    },
    updateFuturesTicker: function (_state, tickers) {
        tickers.forEach(function (item) {
            if (_state.futuresSymbols[item.symbol]) {
                vue_1.default.set(_state.futuresSymbols[item.symbol], 'ticker', item);
            }
        });
    },
    updateFuturesAssets: function (_state, data) {
        var wallet = data.find(function (item) { return item.cur === 'USDT'; });
        if (wallet) {
            _state.futuresWallet = wallet;
        }
    },
    setPositions: function (_state, list) {
        _state.positions = list;
    },
    updatePosition: function (_state, data) {
        var index = _state.positions.findIndex(function (item) { return item.id === data.id; });
        if (index >= 0) {
            if ([2, 3].includes(data.state)) {
                _state.positions.splice(index, 1);
            }
            else {
                _state.positions[index] = Object.assign(_state.positions[index], data);
            }
        }
        else {
            _state.positions.push(data);
        }
    },
    updateFuturesFavoriteList: function (_state, list) {
        _state.futuresFavoriteList = list;
    },
    updateUnit: function (_state, value) {
        futures_1.default.setFuturesUint(value);
        _state.uint = value;
    }
};
var actions = {
    getFuturesSymbols: function (_a) {
        var commit = _a.commit, dispatch = _a.dispatch;
        return Singleton_1.default.promise(function () {
            return Promise.all([
                (0, common_1.getFuturesDetail)(),
                (0, common_1.getFuturesTickerList)()
            ]).then(function (_a) {
                var symbolList = _a[0], tickList = _a[1];
                var data = symbolList || [];
                var obj = {};
                data.forEach(function (item) {
                    obj[item.symbol] = detailCoverConTract(item, {
                        amount24: 0,
                        fairPrice: 0,
                        highest24Price: 0,
                        indexPrice: 0,
                        curPrice: 0,
                        lowest24Price: 0,
                        riseFallRate: 0,
                        symbol: item.symbol,
                        timestamp: 0,
                        volume24: 0,
                        fundingRate: 0,
                        ask1: 0,
                        bid1: 0
                    });
                });
                tickList.forEach(function (item) {
                    if (obj[item.symbol]) {
                        obj[item.symbol].ticker = item;
                    }
                });
                commit('setFuturesSymbols', obj);
                dispatch('subscribePrice');
                return obj;
            });
        }, 'getFuturesDetail');
    },
    // 合约自选列表
    initFuturesFavorite: function (_a) {
        var commit = _a.commit, dispatch = _a.dispatch, rootGetters = _a.rootGetters;
        var isLogin = rootGetters['user/isLogin'];
        if (isLogin) {
            var list = futures_1.default.getFuturesFavorites();
            if (list.length) {
                (0, user_1.addBatchFuturesFavorite)(list)
                    .then(function () {
                    futures_1.default.clearFuturesFavorites();
                })
                    .finally(function () {
                    dispatch('getFuturesFavoriteList');
                });
            }
            else {
                dispatch('getFuturesFavoriteList');
            }
        }
        else {
            commit('updateFuturesFavoriteList', futures_1.default.getFuturesFavorites());
        }
    },
    toggleFuturesFavorite: function (_a, symbol) {
        var state = _a.state, dispatch = _a.dispatch;
        if (state.futuresFavoriteList.includes(symbol)) {
            dispatch('removeFuturesFavorite', symbol);
        }
        else {
            dispatch('addFuturesFavorite', symbol);
        }
    },
    addFuturesFavorite: function (_a, symbol) {
        var commit = _a.commit, dispatch = _a.dispatch, rootGetters = _a.rootGetters;
        var isLogin = rootGetters['user/isLogin'];
        if (isLogin) {
            (0, user_1.addFuturesFavorite)(symbol).then(function () {
                dispatch('getFuturesFavoriteList');
            });
        }
        else {
            futures_1.default.addFuturesFavorites(symbol);
            commit('updateFuturesFavoriteList', futures_1.default.getFuturesFavorites());
        }
    },
    removeFuturesFavorite: function (_a, symbol) {
        var commit = _a.commit, dispatch = _a.dispatch, rootGetters = _a.rootGetters;
        var isLogin = rootGetters['user/isLogin'];
        if (isLogin) {
            (0, user_1.removeFuturesFavorite)(symbol).then(function () {
                dispatch('getFuturesFavoriteList');
            });
        }
        else {
            futures_1.default.delFuturesFavorites(symbol);
            commit('updateFuturesFavoriteList', futures_1.default.getFuturesFavorites());
        }
    },
    getFuturesFavoriteList: function (_a) {
        var commit = _a.commit;
        return new Promise(function (resolve, reject) {
            (0, user_1.getFuturesFavorite)().then(function (list) {
                commit('updateFuturesFavoriteList', list);
                resolve(list);
            }).catch(reject);
        });
    },
    getFuturesAssets: function (_a) {
        var commit = _a.commit, dispatch = _a.dispatch;
        return Singleton_1.default.promise(function () {
            return (0, user_1.getFuturesAssets)().then(function (res) {
                commit('updateFuturesAssets', res || []);
                dispatch('subUser');
            });
        }, 'getFuturesAssets');
    },
    subscribePrice: function (_a) {
        var commit = _a.commit;
        socket_1.default.subscribeTicker(function (data) {
            commit('updateFuturesTicker', data);
        });
    },
    unsubscribePrice: function () {
        socket_1.default.unsubscribeTicker();
    },
    disconnect: function () {
        // disconnect();
        // updatePriceMap.clear();
        // if (updatePriceTimer) {
        //   clearInterval(updatePriceTimer);
        // }
    },
    getPositions: function (_a) {
        var commit = _a.commit;
        return Singleton_1.default.promise(function () {
            return (0, user_1.getFuturesPositions)().then(function (res) {
                commit('setPositions', res || []);
            });
        }, 'getFuturesPositions');
    },
    subUser: function (_a) {
        var commit = _a.commit;
        if (subUserState)
            return;
        socket_1.default.subUser();
        subUserState = true;
        socket_1.mitt.on('push.user.asset', function (_a) {
            var data = _a.data;
            if (data) {
                commit('updateFuturesAssets', [
                    __assign(__assign({}, data), { available: data.avlBal, frozen: data.frzBal })
                ]);
            }
        });
        socket_1.mitt.on('push.user.position', function (_a) {
            var data = _a.data;
            commit('updatePosition', __assign(__assign({}, data), { profitRate: 0 }));
        });
    }
};
exports.futures = {
    namespaced: true,
    state: state(),
    getters: getters,
    mutations: mutations,
    actions: actions,
};
