<template>
  <div class="header-collapse-wrapper">
    <p
      class="header-collapse-item"
      @click="click"
      :class="{ 'header-collapse-item-active': activeState }"
    >
      {{ data.title }}

      <svg width="6" height="4" viewBox="0 0 6 4" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.473696 0C0.282109 0 0.109388 0.115409 0.0360707 0.292412C-0.0372462 0.469415 0.00328 0.673154 0.138752 0.808626L2.66506 3.33493C2.85004 3.51991 3.14996 3.51991 3.33494 3.33493L5.86125 0.808626C5.99672 0.673154 6.03725 0.469415 5.96393 0.292412C5.89061 0.115409 5.71789 0 5.5263 0H0.473696Z"
          fill="black"
        />
      </svg>
    </p>

    <div class="header-collapse-content" :style="style">
      <NavLink
        class="header-collapse-item"
        v-for="h in data.children"
        :key="h.name"
        :data="h"
        :style="{ lineHeight: `${height}rem` }"
      >
        {{ h.name }}
      </NavLink>
    </div>
  </div>
</template>

<script>
import NavLink from './nav-link.vue';
export default {
  name: 'HeaderSideCollapse',
  components: {
    NavLink
  },
  props: {
    data: Object
  },
  data() {
    return {
      activeState: false,
      height: 0.5
    };
  },
  computed: {
    style() {
      if (!this.activeState) {
        return {
          height: '0'
        };
      }

      return {
        height: `${this.data.children.length * this.height}rem`
      };
    }
  },
  methods: {
    click() {
      this.activeState = !this.activeState;
    }
  }
};
</script>
