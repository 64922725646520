<template>
  <div v-if="!user.id && !mobileHeader" class="user-pin login">
    <router-link class="header-item header-label" :to="'/sign-in?redirectTo=' + signInRedirectTo">
      {{ $t('sign-in') }}
    </router-link>
    <router-link class="header-item header-label" :to="'/sign-up?redirectTo=' + signUpRedirectTo">
      {{ $t('sign-up') }}
    </router-link>
  </div>

  <div class="user-wrapper" v-else-if="user.id">
    <template v-if="!mobileHeader">
      <template v-for="item in userLink">
        <UserPopover :data="item" :key="item.title" />
      </template>
    </template>

    <slot></slot>
  </div>
</template>

<script>
import UserPopover from './user-popover.vue'
import UserSide from './user-side.vue';
import { mapGetters, mapState } from 'vuex';

export default {
  components: {
    UserPopover,
    UserSide
  },
  data() {
    return {
      userLink: [
        {
          children: [],
          title: this.$t('wallet'),
          type: 'seconds-wallet'
        }
      ]
    };
  },
  computed: {
    ...mapState('user', {
      user: (state) => state.info
    }),
    ...mapState('header', ['mobileHeader']),
    signInRedirectTo() {
      if (this.$route.path.includes('sign')) {
        return '/';
      }

      return this.$route.path;
    },
    signUpRedirectTo() {
      if (this.$route.path.includes('sign')) {
        return '/';
      }

      return this.$route.path;
    }
  }
};
</script>
