"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFairKline = exports.getIndexKline = exports.getLastKline = exports.getRiskReverseDays = exports.getRiskReverseHistory = exports.getRiskReverse = exports.getFuturesFundingRateDays = exports.getFuturesFundingRateHistory = exports.getFuturesFundingRate = exports.getFuturesIndexPriceWeight = exports.getFuturesTickerList = exports.getFuturesDetail = exports.getFuturesSymbols = void 0;
var futures_axios_new_1 = require("utils/axios/futures-axios-new");
function getFuturesSymbols() {
    return (0, futures_axios_new_1.get)('/product');
}
exports.getFuturesSymbols = getFuturesSymbols;
function getFuturesDetail(symbol) {
    return (0, futures_axios_new_1.get)('/detailV2?client=web', { symbol: symbol });
}
exports.getFuturesDetail = getFuturesDetail;
function getFuturesTickerList() {
    return (0, futures_axios_new_1.get)('/ticker/list');
}
exports.getFuturesTickerList = getFuturesTickerList;
function getFuturesIndexPriceWeight(symbol) {
    return (0, futures_axios_new_1.get)('/marketPrice', { symbol: symbol });
}
exports.getFuturesIndexPriceWeight = getFuturesIndexPriceWeight;
function getFuturesFundingRate(symbol) {
    return (0, futures_axios_new_1.get)("/fundingRate/".concat(symbol));
}
exports.getFuturesFundingRate = getFuturesFundingRate;
function getFuturesFundingRateHistory(symbol, page_num, page_size) {
    return (0, futures_axios_new_1.get)('/fundingRate/history', { symbol: symbol, page_num: page_num, page_size: page_size });
}
exports.getFuturesFundingRateHistory = getFuturesFundingRateHistory;
function getFuturesFundingRateDays(symbol, day) {
    if (day === void 0) { day = 7; }
    return (0, futures_axios_new_1.get)('/fundingRate/latestNDays', { symbol: symbol, day: day });
}
exports.getFuturesFundingRateDays = getFuturesFundingRateDays;
function getRiskReverse(symbol) {
    return (0, futures_axios_new_1.get)("/riskReverse/".concat(symbol));
}
exports.getRiskReverse = getRiskReverse;
function getRiskReverseHistory(symbol, page_num, page_size) {
    return (0, futures_axios_new_1.get)('/riskReverse/history', { symbol: symbol, page_num: page_num, page_size: page_size });
}
exports.getRiskReverseHistory = getRiskReverseHistory;
function getRiskReverseDays(symbol) {
    return (0, futures_axios_new_1.get)('/riskReverse/latestDays', { symbol: symbol });
}
exports.getRiskReverseDays = getRiskReverseDays;
function getLastKline(symbol, data) {
    return (0, futures_axios_new_1.get)('/kline/' + symbol, data);
}
exports.getLastKline = getLastKline;
// 查询指数价k线
function getIndexKline(symbol, data) {
    return (0, futures_axios_new_1.get)('/kline/indexPrice/' + symbol, data);
}
exports.getIndexKline = getIndexKline;
// 查询合理价k线
function getFairKline(symbol, data) {
    return (0, futures_axios_new_1.get)('/kline/fairPrice/' + symbol, data);
}
exports.getFairKline = getFairKline;
