<template>
  <div v-show="show && !close" class="mobile-footer">
    <img class="logo" :src="require('assets/bit_logo.png')" alt="" />

    <div class="footer-info">
      <p class="title">Biconomy</p>
      <i class="el-icon-star-on"></i>
      <i class="el-icon-star-on"></i>
      <i class="el-icon-star-on"></i>
      <i class="el-icon-star-on"></i>
      <i class="el-icon-star-on"></i>
      <span>(2k)</span>
    </div>

    <a :href="url" target="_blank" rel="noopener noreferrer">{{ $t('footer.get_app') }}</a>

    <i @click="close = true" class="el-icon-circle-close"></i>
  </div>
</template>

<script>
import { isAndroid, isIos } from 'utils/UserAgent';
import { mapState, mapActions } from 'vuex';
export default {
  name: 'MobileFooter',
  data() {
    return {
      isAndroid: isAndroid(),
      isIos: isIos(),
      close: false
    };
  },
  computed: {
    ...mapState(['siteInfo']),
    url() {
      return (
        this.siteInfo.android_id &&
        (this.isIos ? this.siteInfo.appstore_id : this.siteInfo.android_id)
      );
    },
    show() {
      return ['/'].includes(this.$route.path);
    }
  },
  methods: {
    ...mapActions(['getSiteInfo'])
  },
  created() {
    this.getSiteInfo();
  }
};
</script>
