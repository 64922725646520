"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getOrderLiquidatePrice = exports.getLimitOrderDealDetails = exports.getAssetRecord = exports.getStopHistory = exports.getForcedLiquidationHistory = exports.getPlanOrderHistory = exports.marginChangeDetails = exports.getFuturesPositionHistory = exports.getFuturesOrderDeals = exports.getFuturesLimitOrders = exports.cancelAllPositionTpSl = exports.cancelPositionTpSl = exports.cancelAllConditionalOrder = exports.cancelConditionalOrder = exports.cancelAllLimitOrder = exports.cancelLimitOrder = exports.getFuturesPlanOrders = exports.getFuturesStopOrders = exports.getFuturesLimitOrdersBySymbol = exports.getFuturesOpenOrders = exports.removeBatchFuturesFavorite = exports.removeFuturesFavorite = exports.addBatchFuturesFavorite = exports.addFuturesFavorite = exports.getFuturesFavorite = exports.getFuturesAssets = exports.getUserLeverage = exports.getUserRiskLimit = exports.getFuturesPositions = void 0;
var futures_axios_new_1 = require("utils/axios/futures-axios-new");
function getFuturesPositions(symbol) {
    return (0, futures_axios_new_1.get)('/private/position/openPositions', { symbol: symbol });
}
exports.getFuturesPositions = getFuturesPositions;
function getUserRiskLimit(symbol) {
    return (0, futures_axios_new_1.get)('/private/account/riskLimit', { symbol: symbol });
}
exports.getUserRiskLimit = getUserRiskLimit;
function getUserLeverage(symbol) {
    return (0, futures_axios_new_1.get)('/private/position/lvg', { symbol: symbol });
}
exports.getUserLeverage = getUserLeverage;
function getFuturesAssets() {
    return new Promise(function (resolve, reject) {
        (0, futures_axios_new_1.get)('/private/account/assets').then(function (res) {
            resolve(res.map(function (item) { return ({
                available: item.avlBal,
                frozen: item.frzBal,
                positionMargin: item.positionMargin,
                equity: item.equity,
                canWithdraw: item.canWithdraw,
                bonus: item.bonus,
                cur: item.cur,
                unrealized: item.unrealized
            }); }));
        }).catch(reject);
    });
}
exports.getFuturesAssets = getFuturesAssets;
/** 获取用户收藏列表 */
function getFuturesFavorite() {
    return (0, futures_axios_new_1.get)('/private/account/preferredSymbols');
}
exports.getFuturesFavorite = getFuturesFavorite;
/** 添加收藏币对 */
function addFuturesFavorite(symbol) {
    return (0, futures_axios_new_1.post)('/private/account/add_preferred_symbol', { symbol: symbol });
}
exports.addFuturesFavorite = addFuturesFavorite;
/** 批量添加收藏币对 */
function addBatchFuturesFavorite(symbols) {
    return (0, futures_axios_new_1.post)('/private/account/batch_add_preferred_symbol', symbols);
}
exports.addBatchFuturesFavorite = addBatchFuturesFavorite;
/** 取消收藏币对 */
function removeFuturesFavorite(symbol) {
    return (0, futures_axios_new_1.post)('/private/account/remove_preferred_symbol', { symbol: symbol });
}
exports.removeFuturesFavorite = removeFuturesFavorite;
/** 批量添加收藏币对 */
function removeBatchFuturesFavorite(symbols) {
    return (0, futures_axios_new_1.post)('/private/account/batch_remove_preferred_symbol', symbols);
}
exports.removeBatchFuturesFavorite = removeBatchFuturesFavorite;
/** -----  Futures Orders  ------------- */
function getFuturesOpenOrders(symbol) {
    return (0, futures_axios_new_1.get)('/private/position/openPositions', { symbol: symbol });
}
exports.getFuturesOpenOrders = getFuturesOpenOrders;
function getFuturesLimitOrdersBySymbol(symbol, pageConfig) {
    if (symbol) {
        return (0, futures_axios_new_1.get)("/private/order/openOrders/".concat(symbol), pageConfig);
    }
    return (0, futures_axios_new_1.get)("/private/order/openOrders", pageConfig);
}
exports.getFuturesLimitOrdersBySymbol = getFuturesLimitOrdersBySymbol;
function getFuturesStopOrders(data) {
    return (0, futures_axios_new_1.get)('/private/stoporder/orders', data);
}
exports.getFuturesStopOrders = getFuturesStopOrders;
function getFuturesPlanOrders(data) {
    return (0, futures_axios_new_1.get)('/private/planorder/orders', data);
}
exports.getFuturesPlanOrders = getFuturesPlanOrders;
/** 取消限价单 */
function cancelLimitOrder(data) {
    return (0, futures_axios_new_1.post)('/private/order/cancel', data);
}
exports.cancelLimitOrder = cancelLimitOrder;
/** 取消全部限价单 */
function cancelAllLimitOrder(data) {
    return (0, futures_axios_new_1.post)('/private/order/cancelAll', data);
}
exports.cancelAllLimitOrder = cancelAllLimitOrder;
/** 取消计划委托单 */
function cancelConditionalOrder(data) {
    return (0, futures_axios_new_1.post)('/private/planorder/cancel', data);
}
exports.cancelConditionalOrder = cancelConditionalOrder;
/** 取消全部计划委托单 */
function cancelAllConditionalOrder(data) {
    return (0, futures_axios_new_1.post)('/private/planorder/cancelAll', data);
}
exports.cancelAllConditionalOrder = cancelAllConditionalOrder;
/** 取消仓位止盈止损 */
function cancelPositionTpSl(data) {
    return (0, futures_axios_new_1.post)('/private/stoporder/cancel', data);
}
exports.cancelPositionTpSl = cancelPositionTpSl;
/** 批量取消仓位止盈止损 */
function cancelAllPositionTpSl(data) {
    return (0, futures_axios_new_1.post)('/private/stoporder/cancel_all', data);
}
exports.cancelAllPositionTpSl = cancelAllPositionTpSl;
/** ----- Open Orders ----- */
/** 限价委托记录 */
function getFuturesLimitOrders(data) {
    return (0, futures_axios_new_1.get)('/private/order/historyOrders', data);
}
exports.getFuturesLimitOrders = getFuturesLimitOrders;
function getFuturesOrderDeals(data) {
    return (0, futures_axios_new_1.get)('/private/order/orderDeals', data);
}
exports.getFuturesOrderDeals = getFuturesOrderDeals;
function getFuturesPositionHistory(data) {
    return (0, futures_axios_new_1.get)('/private/position/historyPositions', data);
}
exports.getFuturesPositionHistory = getFuturesPositionHistory;
function marginChangeDetails(positionId, symbol, pageIndex, pageSize) {
    return (0, futures_axios_new_1.get)('/private/position/marginChangeDetails', { positionId: positionId, symbol: symbol, pageIndex: pageIndex, pageSize: pageSize });
}
exports.marginChangeDetails = marginChangeDetails;
function getPlanOrderHistory(data) {
    return (0, futures_axios_new_1.get)('/private/planorder/orders', data);
}
exports.getPlanOrderHistory = getPlanOrderHistory;
function getForcedLiquidationHistory(data) {
    return (0, futures_axios_new_1.get)('/private/order/closeOrders', data);
}
exports.getForcedLiquidationHistory = getForcedLiquidationHistory;
function getStopHistory(data) {
    return (0, futures_axios_new_1.get)('/private/stoporder/orders', data);
}
exports.getStopHistory = getStopHistory;
function getAssetRecord(params) {
    return new Promise(function (resolve, reject) {
        var data = __assign(__assign({}, params), { currentPage: params.page_num, pageSize: params.page_size });
        // delete data.page_num
        // delete data.page_size
        data.page_num = undefined;
        data.page_size = undefined;
        (0, futures_axios_new_1.get)('/private/account/assetRecord', data)
            .then(function (res) { return resolve(__assign(__assign({}, res), { totalCount: res.totalNum })); })
            .catch(reject);
    });
}
exports.getAssetRecord = getAssetRecord;
function getLimitOrderDealDetails(orderId) {
    return (0, futures_axios_new_1.get)('/private/order/dealDetails/' + orderId);
}
exports.getLimitOrderDealDetails = getLimitOrderDealDetails;
function getOrderLiquidatePrice(id) {
    return (0, futures_axios_new_1.get)('/private/order/liquidatePrice/' + id);
}
exports.getOrderLiquidatePrice = getOrderLiquidatePrice;
