<template>
  <div class="loading bc-loading" :style="{ width: size, height: size }">
    <svg viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19 37.3294C8.87694 37.3294 0.670588 29.1231 0.670588 19C0.670588 8.87694 8.87694 0.670588 19 0.670588C29.1231 0.670588 37.3294 8.87694 37.3294 19C37.3294 23.9958 35.3316 28.5237 32.0899 31.8306C28.7624 35.2248 24.1276 37.3294 19 37.3294Z"
        :stroke="`url(#${id})`"
        stroke-width="1.5"
        stroke-miterlimit="1.30541"
        stroke-linecap="round"
        stroke-linejoin="bevel"
      />
      <defs>
        <radialGradient
          :id="id"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(17.3375 12.1125) rotate(77.7352) scale(27.9504)"
        >
          <stop offset="0.335209" :stop-color="color" />
          <stop offset="0.846343" stop-color="#C4E6FB" stop-opacity="0" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </radialGradient>
      </defs>
    </svg>

    <slot>
      <img v-if="showLogo" class="loading-logo" :src="require('assets/bit_logo.png')" alt="" />
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      default: '1rem',
      type: [String, Number]
    },
    color: {
      default: '#4d77ff',
      type: String
    },
    showLogo: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      id: ''
    };
  },
  methods: {
    getRandomString(len = 6) {
      const $chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
      const maxPos = $chars.length;
      let str = '';
      for (let i = 0; i < len; i++) {
        str += $chars.charAt(Math.floor(Math.random() * maxPos));
      }
      return str;
    }
  },
  created() {
    this.id = 'loading_' + this.getRandomString();
  }
};
</script>
