import Mitt from 'mitt';

const mitt = new Mitt();

function openTransferModal(data) {
  mitt.emit('open', data);
}

function onOpenTransferModal(fn) {
  mitt.on('open', (data) => {
    fn(data);
  });
}

function off() {
  mitt.all.clear();
}

export { openTransferModal, onOpenTransferModal, off };
