'use strict'
module.exports = {
  backendUrl: 'https://www.biconomy.com/api/v1',
  v4Url: 'https://www.biconomy.com/api/v4',
  v8Url: 'https://www.biconomy.com/api/v8',
  v9Url: 'https://www.biconomy.com/api/v9',
  v10Url: 'https://www.biconomy.com/api/v10',
  backendOTCUrl: 'https://www.biconomy.com/otc/api/v1',
  launchpadUrl:'https://www.biconomy.com/api',
  activityUrl: 'https://www.biconomy.com/api/activity',                         // 服务端 websocket 的 url
  klineSocketUrl: 'wss://bei.biconomy.com/ws',                            // 服务端 k线的websocket 的 url

  "siteId": 127,
  futureUrl: 'https://api-future.biconomy.com/future/api/v1',
  futuresWs: 'wss://api-future.biconomy.com/future/websocket',
  oss: 'https://biconomyweb.oss-ap-northeast-1.aliyuncs.com',
  ossAccelerate: 'https://biconomyweb.oss-accelerate.aliyuncs.com', // oss加速
  ossDoc: 'https://biconomydoc.oss-accelerate.aliyuncs.com',
  ossId: 'https://biconomyid.oss-accelerate.aliyuncs.com',
  turnstileSiteKey: '0x4AAAAAAAbgXhQIez7_78fy',
  secondsTradeUrl: 'https://seconds.biconomy.com/api',
};
