<template>
  <RouterLink 
    v-if="data.module === 'exchange'" 
    :to="data.link"
    class="header-item"
    @click.native="$emit('click')"
  >
    <slot />
  </RouterLink>

  <a 
    v-else 
    class="header-item" 
    :href="data.link" 
    :target="data.target" 
    :rel="rel"
    @click="$emit('click')"
  >
    <slot />
  </a>
</template>

<script>
export default {
  name: 'NavLink',
  props: {
    data: Object
  },
  computed: {
    rel() {
      if (this.data.target === '_blank') return 'noopener noreferrer';
    }
  }
};
</script>
