export const SYSTEM = {
  GET_BANNERS: 'GET_BANNERS',
  GET_POPUP: 'GET_POPUP',
  GET_SITE_INFO: 'GET_SITE_INFO',
  GET_CONTACTS: 'GET_CONTACTS',
  GET_HEADERS: 'GET_HEADERS',
  GET_FOOTERS: 'GET_FOOTERS',
  GET_DELTA_TIME: 'GET_DELTA_TIME',
  SET_THEAME: 'SET_THEAME',
};

export const USER = {
  GET_USER_INFO: 'GET_USER_INFO',
  UPDATE_USER_INFO: 'UPDATE_USER_INFO',
  GET_COUNTRIES: 'GET_COUNTRIES',
  GET_DEFAULT_COUNTRIES: 'GET_DEFAULT_COUNTRIES',
  GET_USER_ASSETS: 'GET_USER_ASSETS',
  GET_USER_ASSETS_BALANCE: 'GET_USER_ASSETS_BALANCE',
  GET_ASSETS_HISTORY: 'GET_ASSETS_HISTORY',
  UPDATE_ASSETS: 'UPDATE_ASSETS',
  GET_OPEN_ORDERS: 'GET_OPEN_ORDERS',
  UPDATE_OPEN_ORDERS: 'UPDATE_OPEN_ORDERS',
  DELETE_OPEN_ORDERS: 'DELETE_OPEN_ORDERS',
  GET_HISTORY_ORDERS: 'GET_HISTORY_ORDERS',
  UPDATE_HISTORY_ORDERS: 'UPDATE_HISTORY_ORDERS',
  GET_SECURITY: 'GET_SECURITY',
  UPDATE_SECURITY: 'UPDATE_SECURITY',
  GET_ADDRESSES: 'GET_ADDRESSES',
  UPDATE_DEPOSIT_ADDRESSES: 'UPDATE_DEPOSIT_ADDRESSES',
  GET_DEPOSIT_RECORDS: 'GET_DEPOSIT_RECORDS',
  GET_WITHDRAW_RECORDS: 'GET_WITHDRAW_RECORDS',
  GET_LOGIN_HISTORY: 'GET_LOGIN_HISTORY',
  GET_BANK_CARD: 'GET_BANK_CARD',
  DEL_BANK_CARD: 'DEL_BANK_CARD',
  GET_INVITE_INFO: 'GET_INVITE_INFO',
  GET_INVITE_DATA: 'GET_INVITE_DATA',
  GET_INVITE_RECORDS: 'GET_INVITE_RECORDS',
  GET_REBASE_RECORDS: 'GET_REBASE_RECORDS',
  GET_INVITE_TOP: 'GET_INVITE_TOP',
  GET_TOKEN_STATEMENT: 'GET_TOKEN_STATEMENT',
  GET_BUSINESS_TYPES: 'GET_BUSINESS_TYPES',
  GET_CERTIFICATE_INFO: 'GET_CERTIFICATE_INFO',
  GET_OSS_CONFIG: 'GET_OSS_CONFIG',
  GET_PAY_TYPES: 'GET_PAY_TYPES',
  GET_USER_SECONDS_ASSET: 'GET_USER_SECONDS_ASSET', // 用户秒合约资产
};

export const EXCHANGE = {
  UPDATE_ANCHOR_COIN: 'UPDATE_ANCHOR_COIN',
  GET_EXCHANGE_RATE: 'GET_EXCHANGE_RATE',
  UPDATE_EXCHANGE_RATE: 'UPDATE_EXCHANGE_RATE',
  GET_ORDER_HISTORY: 'GET_ORDER_HISTORY',
  GET_CASH_PRICE: 'GET_CASH_PRICE',
  GET_OTC_PRICE: 'GET_OTC_PRICE',
  GET_OTC_MERCHANTS: 'GET_OTC_MERCHANTS',
  GET_OTC_ORDERS: 'GET_OTC_ORDERS',
  GET_OTC_SWT: 'GET_OTC_SWT',
};

export const MARKET = {
  GET_SYMBOLS: 'GET_SYMBOLS',
  GET_SYMBOL_CLASSES: 'GET_SYMBOL_CLASSES',
  GET_ASSETS_INFO_LIST: 'GET_ASSETS_INFO_LIST',
  GET_ASSET_INFO: 'GET_ASSET_INFO',
  GET_QUOTE_ASSETS: 'GET_QUOTE_ASSETS',
  UPDATE_SYMBOL: 'UPDATE_SYMBOL',
  GET_DEALS: 'GET_DEALS',
  UPDATE_DEALS: 'UPDATE_DEALS',
  GET_DEPTH: 'GET_DEPTH',
  UPDATE_DEPTH: 'UPDATE_DEPTH',
  SUBSCRIBE_KLINE: 'SUBSCRIBE_KLINE',
  QUERY_KLINE: 'QUERY_KLINE',
  FOREIGN_OTC_ASSETS: 'FOREIGN_OTC_ASSETS',
};

export const ARTICLE = {
  GET_ARTICLES: 'GET_ARTICLES',
  GET_FAMILIAR: 'GET_FAMILIAR',
  GET_ARTICLE: 'GET_ARTICLE',
  GET_ABOUT_ARTICLE: 'GET_ABOUT_ARTICLE',
  UPDATE_CLASSES: 'UPDATE_CLASSES',
};

export const ACTIVITY = {
  GET_EXCHANGE_TOP_LIST: 'GET_EXCHANGE_TOP_LIST',
  GET_DAILY_TOP_LIST: 'GET_DAILY_TOP_LIST',
  GET_POK_LOTTERY_INFO: 'GET_POK_LOTTERY_INFO',
  GET_USER_POK_LOTTERY_RECORDS: 'GET_USER_POK_LOTTERY_RECORDS',
  GET_POK_LOTTERY_RECORDS: 'GET_POK_LOTTERY_RECORDS',
  GET_HOLD_CONFIG: 'GET_HOLD_CONFIG',
  GET_POK_DEALS: 'GET_POK_DEALS',
  GET_HOLD_AWARDS: 'GET_HOLD_AWARDS',
  GET_POK_TIME: 'GET_POK_TIME',
  GET_MID_AUTUMN: 'GET_MID_AUTUMN',
  GET_MID_AUTUMN_CONFIG: 'GET_MID_AUTUMN_CONFIG',
  GET_MID_AUTUMN_ANNOUNCE: 'GET_MID_AUTUMN_ANNOUNCE',
  GET_MID_AUTUMN_TICKETS: 'GET_MID_AUTUMN_TICKETS',
  GET_MID_AUTUMN_DETAILS: 'GET_MID_AUTUMN_DETAILS',
};

export const FEEDBACK = {
  GET_LIST: 'GET_LIST',
  GET_DETAILS: 'GET_DETAILS',
};
