<template>
  <div class="header-nav">
    <template v-for="item in nav">
      <NavDropdown v-if="item.children" :data="item" :key="item.title" />

      <NavLink v-else :key="item.title" :data="item">
        <p class="header-label">
          {{ item.title }}
        </p>

        <div class="beta" v-if="item.beta">
          <span>BETA</span>
        </div>

        <img
          class="hot-icon"
          src="https://biconomyweb.oss-accelerate.aliyuncs.com/header/hot.svg"
          alt=""
          v-if="item.hot"
        />
      </NavLink>
    </template>
  </div>
</template>

<script>
import NavLink from './nav-link.vue';
import NavDropdown from './nav-dropdown.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'Nav',
  components: {
    NavLink,
    NavDropdown
  },
  data() {
    return {
      NavLink
    };
  },
  computed: {
    ...mapGetters('header', ['nav'])
  }
};
</script>
