import SecondsTrade from 'data/api/seconds-trade';

const state = () => ({
  config: {},
  openingStatus: true,
  openingTime: 0,
  shareQrcodeModalShow: false,
});

const mutations = {
  setConfig(state, config) {
    state.config = config;
  },
  setOpeningStatus(state, status) {
    state.openingStatus = status;
  },
  setOpeningTime(state, time) {
    state.openingTime = time;
  },
  setShareQrcodeModalShow(state, show) {
    state.shareQrcodeModalShow = show;
  }
};

const actions = {
  async getConfig({ commit, state, dispatch }) {
    try {
      const data = await SecondsTrade.getConfig();
      commit('setConfig', data);
      const currentTime = Math.floor((new Date()).getTime() / 1000);
      if (data.time_range.today.start <= currentTime && data.time_range.today.end >= currentTime) {
        commit('setOpeningStatus', true);
      } else {
        commit('setOpeningStatus', false);
      }

      // 开市时间
      if (data.time_range.today.start > currentTime) {
        commit('setOpeningTime', data.time_range.today.start);
      } else {
        commit('setOpeningTime', data.time_range.tomorrow.start);
      }
    } catch (err) {
      console.error('seconds getConfig error', err);
      // 如果没成功就继续请求
      setTimeout(() => {
        dispatch('getConfig');
      }, 1000);
    } finally {
      // 确保 finally 在 try/catch 结束后执行
    }
  },
  updateOpeningStatus({ commit, state, dispatch }, status) {
    commit('setOpeningStatus', status);
  },
};

const getters = {};

export const seconds = {
  namespaced: true, // 修正为 namespaced
  state: state(), // 保持 state 是一个函数返回对象
  mutations,
  actions,
  getters,
};
