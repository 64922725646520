<template>
  <div class="no-result-wrap">
    <img class="no-result-icon" :src="require('assets/common/tip.png')" alt="" />
    <div class="no-result" @click="$emit('click', $event)">
      {{ tipText || $t('result_no_record') }}
    </div>
  </div>
</template>

<script>
// 未查询到内容时显示的占位符
export default {
  name: 'NoResult',
  props: {
    tipText: String
  }
};
</script>
