import { AuthToken } from 'data/api/auth-token';
import Exchange from 'data/api/exchange';
import MarketSocket from 'data/api/market-socket';
// eslint-disable-next-line import/no-unresolved,import/extensions
import User from 'data/api/user-zt';
import Status from 'data/immutable-data/Status';
import { USER } from 'data/store/mutations-types';
import Singleton from 'utils/Singleton';
import { deepMergeArr, deepMergeObj, orderBy } from 'utils/Sort';
import StoreToStorage from 'utils/StoreToStorage';
import { termOverview } from 'data/api/wealth';
import { getScanLoginResult } from 'data/api/common'
import Vue from 'vue';
import SecondsTrade from 'data/api/seconds-trade';

const subscription = {
  assets: null,
  order: null,
};

const state = () => ({
  info: StoreToStorage.getUser(AuthToken.getToken()),
  countries: [],
  defaultCountries: {},
  assets: {},
  assetsBalance: [],
  assetsHistory: {},
  depositAddresses: {},
  openOrder: [],
  orderHistory: [],
  security: {
    phone: false,
    email: false,
    google: false,
  },
  addresses: {},
  loginHistory: [],
  bankCards: [],
  inviteData: {},
  inviteRecords: {},
  rebaseRecords: {},
  inviteInfo: StoreToStorage.getInvite(AuthToken.getToken()),
  inviteTop: [],
  tokenHistory: [],
  businessTypes: [],
  certificateInfo: {},
  ossConfig: null,
  myPayTypes: [],
  earnOverview: {
    total_deposited: 0,
    total_earnings: 0,
    yesterday_earnings: 0
  },
  walletHide: StoreToStorage.getWalletHide(),
  // 用户搜索资产币种
  assetSearchList: StoreToStorage.getAssetSearchHistory(),
  secondsAsset: {
    balance: 0,
  },
});

const getters = {
  isLogin(_state) {
    return !!_state.info.id;
  },
  id(_state) {
    return _state.info.id;
  },
  otcBalance(_state) {
    const obj = {};
    for (let i = 0, len = _state.assetsBalance.length; i < len; i++) {
      obj[_state.assetsBalance[i].asset] = _state.assetsBalance[i];
    }
    return obj;
  },
  filterAssetSearchList(_state) {
    return _state.assetSearchList.filter(item =>  _state.assets[item]?.is_display)
  },
  userVerified(_state) {
    return _state.user_identity_auth === 1
  }
};

const mutations = {
  init(_state) {
    const data = state();
    Object.keys(data).forEach((k) => {
      Vue.set(_state, k, data[k]);
    });
  },
  [USER.GET_USER_INFO](_state, info) {
    Vue.set(_state, 'info', {
      ...info,
      username: info.phone || info.email,
      username_origin: info.phone_origin || info.email_origin,
      pay_bound_all: info.bank_card_bound || info.pay_bound ? 1 : 0,
    });
    StoreToStorage.setUser(_state.info, AuthToken.getToken());
  },
  [USER.UPDATE_USER_INFO](_state, info) {
    Vue.set(_state, 'info', { ..._state.info, ...info });
    StoreToStorage.setUser(_state.info, AuthToken.getToken());
  },
  [USER.GET_COUNTRIES](_state, info) {
    Vue.set(_state, 'countries', info);
  },
  [USER.GET_DEFAULT_COUNTRIES](_state, info) {
    Vue.set(_state, 'defaultCountries', info);
  },
  [USER.GET_USER_ASSETS](_state, info) {
    Vue.set(_state, 'assets', info);
  },
  [USER.UPDATE_ASSETS](_state, info) {
    Vue.set(_state, 'assets', deepMergeObj(_state.assets, info));
  },
  [USER.GET_USER_ASSETS_BALANCE](_state, info) {
    Vue.set(_state, 'assetsBalance', info);
  },
  [USER.GET_ASSETS_HISTORY](_state, info) {
    Vue.set(_state.assetsHistory, info.asset, info.array);
  },
  [USER.GET_OPEN_ORDERS](_state, info) {
    Vue.set(_state.openOrder, info.symbol, info.array);
  },
  [USER.UPDATE_OPEN_ORDERS](_state, info) {
    Vue.set(_state.openOrder, info.symbol, orderBy(deepMergeArr(_state.openOrder[info.symbol] || [], [info.data], 'id'), 'mtime', 'desc'));
  },
  [USER.DELETE_OPEN_ORDERS](_state, info) {
    const {
      symbol,
      data: { id },
    } = info;
    const array = _state.openOrder[symbol];
    const index = Object.keys(array).find((k) => array[k].id === id);
    if (index) {
      array.splice(index, 1);
      Vue.set(_state.openOrder, symbol, array);
    }
  },
  [USER.GET_HISTORY_ORDERS](_state, info) {
    Vue.set(_state.orderHistory, info.symbol, info.array);
  },
  [USER.UPDATE_HISTORY_ORDERS](_state, info) {
    Vue.set(_state.orderHistory, info.symbol, [info.data, ...(_state.orderHistory[info.symbol] || [])]);
  },
  [USER.GET_SECURITY](_state, info) {
    Vue.set(_state, 'security', info);
  },
  [USER.UPDATE_SECURITY](_state, info) {
    Vue.set(_state, 'security', { ..._state.security, ...info });
  },
  [USER.GET_ADDRESSES](_state, info) {
    Vue.set(_state, 'addresses', info);
  },
  [USER.UPDATE_DEPOSIT_ADDRESSES](_state, info) {
    Vue.set(_state.depositAddresses, info.asset, info.address);
  },
  [USER.GET_LOGIN_HISTORY](_state, array) {
    Vue.set(_state, 'loginHistory', array);
  },
  [USER.GET_BANK_CARD](_state, info) {
    Vue.set(_state, 'bankCards', info);
  },
  [USER.DEL_BANK_CARD](_state, id) {
    Vue.set(
      _state,
      'bankCards',
      _state.bankCards.filter((card) => card.ID !== id),
    );
  },
  [USER.GET_INVITE_INFO](_state, info) {
    Vue.set(_state, 'inviteInfo', info);
    StoreToStorage.setInvite(_state.inviteInfo, AuthToken.getToken());
  },
  [USER.GET_INVITE_DATA](_state, info) {
    Vue.set(_state, 'inviteData', info);
  },
  [USER.GET_INVITE_RECORDS](_state, info) {
    Vue.set(_state, 'inviteRecords', info);
  },
  [USER.GET_REBASE_RECORDS](_state, info) {
    Vue.set(_state, 'rebaseRecords', info);
  },
  [USER.GET_INVITE_TOP](_state, arr) {
    Vue.set(_state, 'inviteTop', arr);
  },
  [USER.GET_TOKEN_STATEMENT](_state, arr) {
    Vue.set(_state, 'tokenHistory', arr);
  },
  [USER.GET_BUSINESS_TYPES](_state, arr) {
    Vue.set(_state, 'businessTypes', arr);
  },
  [USER.GET_CERTIFICATE_INFO](_state, obj) {
    Vue.set(_state, 'certificateInfo', { ..._state.certificateInfo, ...obj });
  },
  [USER.GET_OSS_CONFIG](_state, obj) {
    Vue.set(_state, 'ossConfig', obj);
  },
  [USER.GET_PAY_TYPES](_state, arr) {
    Vue.set(_state, 'myPayTypes', arr);
  },
  setEarnOverview(_state, data) {
    _state.earnOverview = data
  },
  setWalletHide(_state, data) {
    _state.walletHide = data
    StoreToStorage.setWalletHide(data)
  },
  setAssetSearchHistory(_state, coin) {
    StoreToStorage.setAssetSearchHistory(coin)
    _state.assetSearchList = StoreToStorage.getAssetSearchHistory()
  },
  [USER.GET_USER_SECONDS_ASSET](_state, info) {
    Vue.set(_state, 'secondsAsset', info);
  },
};

const actions = {
  // eslint-disable-next-line max-len
  register({ commit }, { username, password, code, password_confirmation, country_id, recommend_code }) {
    return User.register({
      username,
      password,
      code,
      password_confirmation,
      country_id,
      recommend_code,
    }).then((res) => {
      commit(USER.GET_USER_INFO, { role: 'client', ...res });
      return res;
    });
  },
  getCountries({ commit }) {
    return User.getCountries().then((res) => {
      commit(USER.GET_COUNTRIES, res);
    });
  },
  getDefaultCountries({ commit }) {
    return User.getDefaultCountries().then((res) => {
      commit(USER.GET_DEFAULT_COUNTRIES, res);
    });
  },
  getUserInfo({ commit }) {
    if (!AuthToken.getToken()) {
      // return new Promise(() => {});
      return Promise.reject();
    }
    return Singleton.promise(
      () =>
        User.getUser().then((res) => {
          commit(USER.GET_USER_INFO, { role: 'client', ...res });
          return res;
        }),
      'getUser',
    );
  },
  signIn(context, { username, password }) {
    return User.signIn({ username, password });
  },
  safeLogin({ commit }, { sms_code, email_code, two_step_code }) {
    return User.safeLogin({ sms_code, email_code, two_step_code }).then((res) => commit(USER.GET_USER_INFO, { role: 'client', ...res }));
  },
  safeLoginEasy({ commit }, { sms_code, email_code, two_step_code }) {
    return User.safeLoginEasy({ sms_code, email_code, two_step_code }).then((res) =>
      commit(USER.GET_USER_INFO, { role: 'client', ...res }),
    );
  },
  logout() {
    return Singleton.promise(function() {
      return User.signOut().then(() => {
        // commit('init')
        // commit('exchange/init', null, { root: true })
        // window.location.href = '/';
        window.location.reload()
      });
    }, 'logOut')
  },
  getAssets({ commit, dispatch }) {
    return Singleton.promise(function() {
      return User.getAssets().then((data) => {
        commit(USER.GET_USER_ASSETS, data);
        dispatch('assetsSubscribe')
        return data;
      })
    }, 'getAssets');
  },
  getAssetsBalance({ commit }, asset = '') {
    return User.getAssetsBalance(asset).then((data) => {
      commit(USER.GET_USER_ASSETS_BALANCE, data);
      return data;
    });
  },
  getAssetsHistory({ commit }, { asset, offset, limit }) {
    return User.getRechargeHistory({ asset, offset, limit }).then((res) => {
      commit(USER.GET_ASSETS_HISTORY, { asset, array: res });
      return res;
    });
  },
  assetsSubscribe({ commit, state, dispatch }, symbols) {
    if (subscription.assets) {
      if (process.env.NODE_ENV == 'development') {
        console.warn('Please unsubscribe the previous subscription')
      }

      return
      // return Promise.reject(new Error('Please unsubscribe the previous subscription'));
    }

    try {
      const list = symbols || Object.keys(state.assets)
      subscription.assets = MarketSocket.assetSubscribe(list).subscribe((data) => {
        commit(USER.UPDATE_ASSETS, data)
        // 如果包含USDT, 刷新合约余额
        if (data?.['USDT']) {
          dispatch('futures/getFuturesAssets', {}, { root: true })
        }
      });
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  },
  assetsUnsubscribe() {
    try {
      if (subscription.assets) {
        subscription.assets.unsubscribe();
        MarketSocket.assetUnsubscribe();
      }
      subscription.assets = null;
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  },
  orderQuery({ commit }, { symbol, offset, limit }) {
    return MarketSocket.orderQuery({ symbol, offset, limit }).then((res) => {
      commit(USER.GET_OPEN_ORDERS, {
        symbol,
        array: res.records,
      });
      return res;
    });
  },
  orderHistoryQuery({ commit }, { symbol, start_time, end_time, offset, limit, side }) {
    return MarketSocket.orderHistoryQuery({
      symbol,
      start_time,
      end_time,
      offset,
      limit,
      side,
    }).then((res) => {
      commit(USER.GET_HISTORY_ORDERS, { symbol, array: res.records });
      return res;
    });
  },
  getOrderHistory({ commit }, { symbol, start_time, end_time, offset, limit, side }) {
    return Exchange.orderFinishedList({
      market: symbol,
      start_time,
      end_time,
      offset,
      limit,
      side,
    }).then((res) => {
      commit(USER.GET_HISTORY_ORDERS, { symbol, array: res.records });
      return res;
    });
  },
  orderSubscribe({ commit }, symbols) {
    if (subscription.order) return Promise.reject(new Error('Please unsubscribe the previous subscription'));
    try {
      subscription.order = MarketSocket.orderSubscribe(symbols).subscribe(({ event, order }) => {
        /**
         * @param {Number} event, 1: PUT, 2: UPDATE, 3: FINISH
         * */
        const result = { symbol: order.market, data: order };
        if (event !== 3) {
          commit(USER.UPDATE_OPEN_ORDERS, result);
        } else {
          commit(USER.DELETE_OPEN_ORDERS, result);
          if (order.status !== Status.orderStatus.CANCELED.value) {
            commit(USER.UPDATE_HISTORY_ORDERS, result);
          }
        }
      });
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  },
  orderUnsubscribe() {
    try {
      if (subscription.order) {
        subscription.order.unsubscribe();
        MarketSocket.orderUnsubscribe();
      }
      subscription.order = null;
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  },
  getSecurity({ commit }) {
    return User.getSecurity().then((res) => {
      commit(USER.GET_SECURITY, res);
      return res;
    });
  },
  verifyOpen({ commit }, type) {
    return User.verifyOpen(type).then((res) => {
      commit(USER.UPDATE_SECURITY, { [type]: true });

      if (type === 'google') {
        commit(USER.UPDATE_USER_INFO, { two_step_open: 1 });
      }
      return res;
    });
  },
  verifyClose({ commit }, { type, sms_code, email_code, two_step_code }) {
    return User.verifyClose({ type, sms_code, email_code, two_step_code }).then((res) => {
      commit(USER.UPDATE_SECURITY, { [type]: false });

      if (type === 'google') {
        commit(USER.UPDATE_USER_INFO, { two_step_open: 0 });
      }
      return res;
    });
  },
  getDepositAddress({ commit }, asset) {
    return User.getRechargeAddress(asset).then((res) => {
      commit(USER.UPDATE_DEPOSIT_ADDRESSES, { asset, address: res });
      return res;
    });
  },
  getDepositAddressByFiat({ commit }, asset) {
    return User.getRechargeAddressByFiat(asset).then((res) => {
      commit(USER.UPDATE_DEPOSIT_ADDRESSES, { asset, address: res });
      return res;
    });
  },
  getWithdrawAddresses({ commit }, { asset, offset, limit }) {
    return User.getWithdrawAddresses({ asset, offset, limit }).then((res) => {
      commit(USER.GET_ADDRESSES, res);
      return res;
    });
  },
  // eslint-disable-next-line max-len
  addWithdrawAddress(context, { asset, name, address, remark, memo, sms_code, email_code, two_step_code }) {
    return User.addWithdrawAddress({
      asset,
      name,
      address,
      remark,
      memo,
      sms_code,
      email_code,
      two_step_code,
    });
  },
  updateWithdrawAddress(_, data) {
    return User.updateWithdrawAddress(data)
  },
  delWithdrawAddress(context, id) {
    return User.delWithdrawAddress(id);
  },
  // eslint-disable-next-line
  withdraw({ dispatch }, { asset, address_id, amount, password, sms_code, email_code, two_step_code, address, domain, memo }) {
    return User.withdraw({
      asset,
      address_id,
      amount,
      password,
      sms_code,
      email_code,
      two_step_code,
      address,
      domain,
      memo,
    }).then((res) => {
      dispatch('getAssets');
      return res;
    });
  },
  // eslint-disable-next-line
  withdrawByFiat(context, { asset, funds_to, amount, password, sms_code, email_code, two_step_code, question, answer }) {
    return User.withdrawByFiat({
      asset,
      funds_to,
      amount,
      password,
      sms_code,
      email_code,
      two_step_code,
      question,
      answer,
    });
  },
  commonAddress(context, { id, remark }) {
    return User.commonAddress({
      id, // 提现返回的id
      remark, // 备注,
    });
  },
  withdrawCancel(context, id) {
    return User.withdrawCancel(id);
  },
  withdrawCancelByFiat(context, id) {
    return User.withdrawCancelByFiat(id);
  },
  bindPhone({ commit }, { phone, sms_code, email_code, country_id, two_step_code }) {
    return User.bindPhone({
      phone,
      sms_code,
      email_code,
      country_id,
      two_step_code,
    }).then((res) => {
      commit(USER.UPDATE_USER_INFO, { phone, username: phone, phone_bound: 1 });
      commit(USER.UPDATE_SECURITY, { phone: true });
      return res;
    });
  },
  updatePhone({ commit }, { phone, code, sms_code, email_code, country_id, two_step_code }) {
    return User.updatePhone({ phone, code, sms_code, email_code, country_id, two_step_code }).then((res) => {
      commit(USER.UPDATE_USER_INFO, { phone, phone_bound: 1 });
      return res;
    });
  },
  bindEmail({ commit }, { email, sms_code, email_code, two_step_code }) {
    return User.bindEmail({ email, sms_code, email_code, two_step_code }).then((res) => {
      commit(USER.UPDATE_USER_INFO, { email, email_bound: 1 });
      commit(USER.UPDATE_SECURITY, { email: true });
      return res;
    });
  },
  updateEmail({ commit }, { email, code, sms_code, email_code, two_step_code }) {
    return User.updateEmail({ email, code, sms_code, email_code, two_step_code }).then((res) => {
      commit(USER.UPDATE_USER_INFO, { email, email_bound: 1 });
      return res;
    });
  },
  // eslint-disable-next-line
  setFundPassword({ commit }, { password, password_confirmation, sms_code, email_code, two_step_code }) {
    return User.setFundPassword({
      password,
      password_confirmation,
      sms_code,
      email_code,
      two_step_code,
    }).then((res) => {
      commit(USER.UPDATE_USER_INFO, { withdraw_password_set: 1 });
      return res;
    });
  },
  // eslint-disable-next-line
  resetFundPassword(context, { password, password_confirmation, sms_code, email_code, two_step_code }) {
    return User.resetFundPassword({
      password,
      password_confirmation,
      sms_code,
      email_code,
      two_step_code,
    });
  },
  setGoogle({ commit }, { two_step_code, sms_code, email_code }) {
    return User.setGoogleVerify({ two_step_code, sms_code, email_code }).then(() => {
      commit(USER.UPDATE_USER_INFO, {
        two_step_set: 1,
        two_step_open: 1 // 默认开启
      });
    });
  },
  deleteGoogle({ commit }, { two_step_code, sms_code, email_code }) {
    return User.deleteGoogleVerify({ two_step_code, sms_code, email_code }).then(() => {
      commit(USER.UPDATE_USER_INFO, { two_step_set: 0 });
    });
  },
  getCertificationUrl(context, { country_id, first_name, last_name, number, type }) {
    return User.getCertificationUrl({
      country_id,
      first_name,
      last_name,
      number,
      type,
    }).then((res) => {
      context.dispatch('getCertificateInfo');
      return res;
    });
  },
  getLoginHistory({ commit }, { offset, limit }) {
    return User.getLoginHistory({ offset, limit }).then((res) => {
      commit(USER.GET_LOGIN_HISTORY, res.data_list || []);
      return res;
    });
  },
  getCard({ commit }) {
    return User.getCard().then((res) => {
      commit(USER.GET_BANK_CARD, res);
      return res;
    });
  },
  // eslint-disable-next-line max-len
  setCard({ commit }, { bank_name, sub_bank_name, number, pre_phone, sms_code, email_code, two_step_code }) {
    return User.setCard({
      bank_name,
      sub_bank_name,
      number,
      pre_phone,
      sms_code,
      email_code,
      two_step_code,
    }).then((res) => {
      commit(USER.UPDATE_USER_INFO, { bank_card_bound: 1 });
      return res;
    });
  },
  delCard({ commit }, id) {
    return User.delCard(id).then((res) => {
      commit(USER.DEL_BANK_CARD, id);
      return res;
    });
  },
  getCode(context, { username, use_type, id, img_code, country_id, validate_method, human_id }) {
    return User.sendSms({
      username,
      use_type,
      id,
      img_code,
      country_id,
      validate_method,
      human_id,
    });
  },
  getCodeAfterLogin(context, { type, use_type, phone, email, country_id, human_id }) {
    return User.sendSmsAfterLogin({ type, use_type, phone, email, country_id, human_id });
  },
  getGoogleVerify() {
    return User.getGoogleVerify();
  },
  resetPasswordAfterLogin(context, { origin_password, password, password_confirmation }) {
    return User.resetPasswordAfterLogin({
      origin_password,
      password,
      password_confirmation,
    });
  },
  getCodeImg() {
    return User.getImgCode();
  },
  resetPassword(context, { username, code, password, password_confirmation }) {
    return User.resetPassword({
      username,
      code,
      password,
      password_confirmation,
    });
  },
  getInviteInfo({ commit }) {
    return User.getInvite().then((res) => {
      commit(USER.GET_INVITE_INFO, res);
      return res;
    });
  },
  getInviteData({ commit }) {
    return User.getInviteData().then((res) => {
      commit(USER.GET_INVITE_DATA, res);
      return res;
    });
  },
  getInviteRecords({ commit }, { offset, limit }) {
    return User.getInviteRecords({ offset, limit }).then((res) => {
      commit(USER.GET_INVITE_RECORDS, res);
      return res;
    });
  },
  getRebaseRecords({ commit }, { offset, limit }) {
    return User.getRebaseRecords({ offset, limit }).then((res) => {
      // const arr = []
      // Object.keys(res).forEach((key) => {
      //   arr.push(res[key])
      // })
      commit(USER.GET_REBASE_RECORDS, res);
      return res;
    });
  },
  getInviteTop({ commit }) {
    return User.getRankTop().then((res) => {
      commit(USER.GET_INVITE_TOP, res);
      return res;
    });
  },
  getTokenStatement({ commit }, { asset, business, start_time, end_time, offset, limit }) {
    return User.getTokenHistory({
      asset,
      business,
      start_time,
      end_time,
      offset,
      limit,
    }).then((res) => {
      commit(USER.GET_TOKEN_STATEMENT, res.records);
      return res;
    });
  },
  getBusinessType({ commit }) {
    return User.getBusinessType().then((res) => {
      commit(USER.GET_BUSINESS_TYPES, res);
      return res;
    });
  },
  getCertificateInfo({ commit, state }) {
    return User.getCertificateInfo().then((res) => {
      commit(USER.GET_CERTIFICATE_INFO, res);
      commit(USER.GET_USER_INFO, {
        ...state.info,
        identity_status: res.status,
        user_identity_auth: res.user_identity_status,
        user_identity_auth_step: res.user_identity_step
      });

      return res;
    });
  },
  updateCertificateInfo(context, data) {
    return User.updateCertificateInfo(data).then((res) => {
      context.dispatch('getCertificateInfo');
      return res;
    });
  },
  getOssConfig({ commit, state: _state }) {
    return _state.ossConfig
      ? Promise.resolve(_state.ossConfig)
      : User.getOssConfig().then((res) => {
          commit(USER.GET_OSS_CONFIG, res);
          return res;
        });
  },
  uploadCertificateImg(context, { id, file, img_type }) {
    return User.uploadCertificateImg({
      identity_id: id,
      filename: file,
      img_type,
    });
  },
  certificateSubmitAll(context, id) {
    return User.certificateSubmitAll(id);
  },
  getMyPayTypes({ commit }) {
    return User.getMyOtcPayTypes().then((res) => {
      commit(USER.GET_PAY_TYPES, res);
      return res;
    });
  },
  transferToOtc({ dispatch }, params) {
    return User.transferToOtc(params).then((res) => {
      dispatch('getAssets');
      dispatch('getAssetsBalance');
      return res;
    });
  },
  transferFromOtc({ dispatch }, params) {
    return User.transferFromOtc(params).then((res) => {
      dispatch('getAssets');
      dispatch('getAssetsBalance');
      return res;
    });
  },
  addMobilePay(context, params) {
    return User.addMobilePay(params);
  },
  delMobilePay(context, id) {
    return User.delMobilePay(id);
  },
  checkUser() {
    return User.checkUser();
  },
  getCustom() {
    return User.getCustom();
  },
  sycCustom(context, symbol_id) {
    User.sycCustom(symbol_id);
  },
  getApi() {
    return User.getApi();
  },
  createApi(context, { remark, bind_ips, sms_code, email_code, two_step_code }) {
    return User.createApi({
      remark,
      bind_ips,
      sms_code,
      email_code,
      two_step_code,
    });
  },
  updateApi(context, { user_app_id, remark, bind_ips, permissions, sms_code, email_code, two_step_code }) {
    return User.updateApi({ user_app_id, remark, bind_ips, permissions, sms_code, email_code, two_step_code });
  },
  deleteApi(context, { user_app_id }) {
    return User.deleteApi({ user_app_id });
  },
  searchAssetsData(context, { field, value, search }) {
    return User.searchAssetsData({ field, value, search });
  },

  createBusinessIdentity(context, { name, number, create_at, address, type }) {
    return User.createBusinessIdentity({
      name,
      number,
      create_at,
      address,
      type,
    }).then((res) => {
      context.dispatch('getCertificateInfo');
      return res;
    });
  },
  updateBusinessIdentity(context, data) {
    return User.updateBusinessIdentity(data).then((res) => {
      context.dispatch('getCertificateInfo');
      return res;
    });
  },
  setAntiPhishingCode({ commit }, anti_phishing_code) {
    return User.setAntiPhishingCode(anti_phishing_code).then(() => {
      commit(USER.UPDATE_USER_INFO, { anti_phishing_code });
    });
  },
  coinToContract({ dispatch }, { amount }) {
    return User.coinToContract({ amount }).then(() => {
      dispatch('getAssets');
      dispatch('futures/getFuturesAssets', {}, { root: true });
    });
  },
  contractToCoin({ dispatch }, { amount }) {
    return User.contractToCoin({ amount }).then(() => {
      dispatch('getAssets');
      dispatch('futures/getFuturesAssets', {}, { root: true });
    });
  },
  getEarnOverview({ commit }) {
    return Singleton.promise(function() {
      return new Promise((resolve, reject) => {
        termOverview()
          .then(data => {
            commit('setEarnOverview', {
              total_deposited: data.total_deposited || 0,
              total_earnings: data.total_earnings || 0,
              yesterday_earnings: data.yesterday_earninds || 0
            })
            resolve()
          })
          .catch(reject)
      })
    }, 'getEarnOverview');
  },
  getScanLoginResult({ commit }, token) {
    return getScanLoginResult(token).then((res) => {
      if (res.status === 'complete') {
        AuthToken.setToken(res.data);
        commit(USER.GET_USER_INFO, { role: 'client', ...res.data })
      }

      return res
    })
  },
  createPersonalIdentity(_, data) {
    return User.createPersonalIdentity(data)
  },
  upgradePersonalIdentity(_, data) {
    return User.upgradePersonalIdentity(data)
  },
  createBusinessIdentity(_, data) {
    return User.createBusinessIdentity(data)
  },
  changeWithdrawWhite({ dispatch, commit }, data) {
    return new Promise((resolve, reject) => {
      User.changeWithdrawWhite(data).then(() => {
        resolve()
        commit(USER.UPDATE_SECURITY, { withdrawal_whitelist: data.open ? true : false });
        // dispatch('')
      }).catch(reject)
    })
  },
  postSecondsTrade({ dispatch }, data) {
    return SecondsTrade.postOrder(data).then((res) => {
      dispatch('getSecondsAsset'); // 重新获取资产
      return res;
    });
  },
  getSecondsTradeRounds(_, data) {
    return SecondsTrade.getRoundData(data).then((res) => {
        return res;
      })
      .catch((err) => {
        console.log('getSecondsTradeRounds err', err);
      });
  },
  getSecondsHistoryOrder(_, data) {
    return SecondsTrade.getHistoryOrder(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log('getSecondsHistoryOrder err', err);
      });
  },
  getSecondRecentOrder(_, data) {
    return SecondsTrade.getPendingOrder(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log('getSecondRecentOrder err', err);
      });
  },
  getSecondsAsset({ commit, state }) {
    return Singleton.promise(function() {
      return SecondsTrade.getUserAsset({ underlying: 'usdt' })
        .then((res) => {
          const asset = state.secondsAsset;
          asset.balance = res.balance;
          // console.log('getSecondsAsset', 'asset', asset);
          commit(USER.GET_USER_SECONDS_ASSET, asset);
          return res;
        })
        .catch((err) => {
          // console.log('getSecondsAsset err', err);
        });
    }, 'getSecondsAssets');
  },
  coinToSecondsAccount({ commit, state, dispatch }, { amount }) {
    return User.coinToSeconds({ amount, coinId: 'USDT' })
      .then((res) => {
        dispatch('getAssets');
        dispatch('getSecondsAsset');
      })
      .catch((err) => {
        console.log("coinToSecondsAccount", err);
      });
  },
  secondsAccountToCoin({ commit, state, dispatch }, { amount }) {
    return User.secondsToAccount({amount: amount, coinId: 'USDT'}).then((res) => {
        dispatch('getAssets');
        dispatch('getSecondsAsset');
      })
      .catch((err) => {
        console.log("coinToSecondsAccount", err);
      });
  }
};

export const user = {
  namespaced: true, // 增加命名空间，外部使用时需要指明命名空间（如果没有外部冲突，可以去掉）
  state: state(),
  getters,
  mutations,
  actions,
};
