<!--
 * 根据element-ui input组件修改
 -->

<template>
  <div
    :class="[
      `bc-input-${variant}`,
      type === 'textarea' ? 'bc-textarea' : 'bc-input',
      inputSize ? 'bc-input--' + inputSize : '',
      {
        'is-readonly': readonly,
        'is-disabled': inputDisabled,
        'is-exceed': inputExceed,
        'bc-input-group': $slots.prepend || $slots.append,
        'bc-input-group--append': $slots.append,
        'bc-input-group--prepend': $slots.prepend,
        'bc-input--prefix': $slots.prefix,
        'bc-input--suffix': $slots.suffix || clearable || showPassword
      }
    ]"
    @mouseenter="hovering = true"
    @mouseleave="hovering = false"
  >
    <!-- 前置元素 -->
    <div class="bc-input-group__prepend" v-if="$slots.prepend">
      <slot name="prepend"></slot>
    </div>

    <div class="bc-input__wrapper">
      <!-- 前置内容 -->
      <span class="bc-input__prefix" v-if="$slots.prefix">
        <slot name="prefix"></slot>
      </span>

      <input
        :tabindex="tabindex"
        v-if="type !== 'textarea'"
        class="bc-input__inner"
        v-bind="$attrs"
        :type="showPassword ? (passwordVisible ? 'text' : 'password') : type"
        :disabled="inputDisabled"
        :readonly="readonly"
        :autocomplete="autocomplete"
        :autofocus="autofocus"
        ref="input"
        @compositionstart="handleCompositionStart"
        @compositionupdate="handleCompositionUpdate"
        @compositionend="handleCompositionEnd"
        @input="handleInput"
        @focus="handleFocus"
        @blur="handleBlur"
        @change="handleChange"
        :aria-label="label"
        :id="id"
      />

      <textarea
        v-else
        :tabindex="tabindex"
        class="bc-textarea__inner"
        @compositionstart="handleCompositionStart"
        @compositionupdate="handleCompositionUpdate"
        @compositionend="handleCompositionEnd"
        @input="handleInput"
        ref="textarea"
        v-bind="$attrs"
        :disabled="inputDisabled"
        :readonly="readonly"
        :autocomplete="autocomplete"
        :style="textareaStyle"
        :autofocus="autofocus"
        @focus="handleFocus"
        @blur="handleBlur"
        @change="handleChange"
        :aria-label="label"
        :id="id"
      />

      <!-- 后置内容 -->
      <span class="bc-input__suffix" v-if="getSuffixVisible()">
        <span class="bc-input__suffix-inner" v-if="getSuffixInnerVisible()">
          <SvgIcon
            v-if="showClear"
            name="clear"
            class="bc-input__icon bc-input__clear"
            @mousedown.prevent
            @click="clear"
          />

          <template v-if="showPwdVisible">
            <SvgIcon
              v-if="!passwordVisible"
              name="eye-invisible"
              class="bc-input__icon bc-input__eye"
              @click="handlePasswordVisible"
            />

            <SvgIcon
              v-else
              name="eye"
              class="bc-input__icon bc-input__eye"
              @click="handlePasswordVisible"
            />
          </template>

          <span v-if="isWordLimitVisible" class="bc-input__count">
            <span class="bc-input__count-inner"> {{ textLength }}/{{ upperLimit }} </span>
          </span>
        </span>

        <template>
          <slot name="suffix"></slot>
        </template>
      </span>
    </div>

    <!-- 后置元素 -->
    <div class="bc-input-group__append" v-if="$slots.append">
      <slot name="append"></slot>
    </div>

    <span v-if="isWordLimitVisible && type === 'textarea'" class="bc-input__count"
      >{{ textLength }}/{{ upperLimit }}</span
    >
  </div>
</template>
<script>
import emitter from 'element-ui/src/mixins/emitter';
import Migrating from 'element-ui/src/mixins/migrating';
import calcTextareaHeight from './calcTextareaHeight';
import merge from 'element-ui/src/utils/merge';
import { isKorean } from 'element-ui/src/utils/shared';

export default {
  name: 'ElInput',

  componentName: 'ElInput',

  mixins: [emitter, Migrating],

  inheritAttrs: false,

  inject: {
    elForm: {
      default: ''
    },
    elFormItem: {
      default: ''
    }
  },

  data() {
    return {
      textareaCalcStyle: {},
      hovering: false,
      focused: false,
      isComposing: false,
      passwordVisible: false
    };
  },

  props: {
    value: [String, Number],
    formatter: Function,
    size: String,
    resize: String,
    form: String,
    disabled: Boolean,
    readonly: Boolean,
    autofocus: Boolean,
    type: {
      type: String,
      default: 'text'
    },
    autosize: {
      type: [Boolean, Object],
      default: false
    },
    autocomplete: {
      type: String,
      default: 'off'
    },
    validateEvent: {
      type: Boolean,
      default: true
    },
    label: String,
    clearable: {
      type: Boolean,
      default: false
    },
    showPassword: {
      type: Boolean,
      default: false
    },
    showWordLimit: {
      type: Boolean,
      default: false
    },
    tabindex: String,
    id: String,
    // outlined, filled
    variant: {
      type: String,
      default: 'filled'
    }
  },

  computed: {
    _elFormItemSize() {
      return (this.elFormItem || {}).elFormItemSize;
    },
    textareaStyle() {
      return merge({}, this.textareaCalcStyle, { resize: this.resize });
    },
    inputSize() {
      return this.size || this._elFormItemSize || (this.$ELEMENT || {}).size;
    },
    inputDisabled() {
      return this.disabled || (this.elForm || {}).disabled;
    },
    nativeInputValue() {
      return this.value === null || this.value === undefined ? '' : String(this.value);
    },
    showClear() {
      return this.clearable && !this.inputDisabled && !this.readonly && !!this.nativeInputValue;
    },
    showPwdVisible() {
      return (
        this.showPassword &&
        !this.inputDisabled &&
        !this.readonly &&
        (!!this.nativeInputValue || this.focused)
      );
    },
    isWordLimitVisible() {
      return (
        this.showWordLimit &&
        this.$attrs.maxlength &&
        (this.type === 'text' || this.type === 'textarea') &&
        !this.inputDisabled &&
        !this.readonly &&
        !this.showPassword
      );
    },
    upperLimit() {
      return this.$attrs.maxlength;
    },
    textLength() {
      if (typeof this.value === 'number') {
        return String(this.value).length;
      }

      return (this.value || '').length;
    },
    inputExceed() {
      // show exceed style if length of initial value greater then maxlength
      return this.isWordLimitVisible && this.textLength > this.upperLimit;
    }
  },

  watch: {
    value(val) {
      this.$nextTick(this.resizeTextarea);
      if (this.validateEvent) {
        this.dispatch('ElFormItem', 'el.form.change', [val]);
      }
    },
    // native input value is set explicitly
    // do not use v-model / :value in template
    // see: https://github.com/ElemeFE/element/issues/14521
    nativeInputValue() {
      this.setNativeInputValue();
    },
    // when change between <input> and <textarea>,
    // update DOM dependent value and styles
    // https://github.com/ElemeFE/element/issues/14857
    type() {
      this.$nextTick(() => {
        this.setNativeInputValue();
        this.resizeTextarea();
        // this.updateIconOffset();
      });
    }
  },

  methods: {
    focus() {
      this.getInput().focus();
    },
    blur() {
      this.getInput().blur();
    },
    handleBlur(event) {
      this.focused = false;
      this.$emit('blur', event);
      if (this.validateEvent) {
        this.dispatch('ElFormItem', 'el.form.blur', [this.value]);
      }
    },
    select() {
      this.getInput().select();
    },
    resizeTextarea() {
      if (this.$isServer) return;
      const { autosize, type } = this;
      if (type !== 'textarea') return;
      if (!autosize) {
        this.textareaCalcStyle = {
          minHeight: calcTextareaHeight(this.$refs.textarea).minHeight
        };
        return;
      }
      const minRows = autosize.minRows;
      const maxRows = autosize.maxRows;

      this.textareaCalcStyle = calcTextareaHeight(this.$refs.textarea, minRows, maxRows);
    },
    setNativeInputValue() {
      const input = this.getInput();
      if (!input) return;
      if (input.value === this.nativeInputValue) return;
      input.value = this.nativeInputValue;
    },
    handleFocus(event) {
      this.focused = true;
      this.$emit('focus', event);
    },
    handleCompositionStart(event) {
      this.$emit('compositionstart', event);
      this.isComposing = true;
    },
    handleCompositionUpdate(event) {
      this.$emit('compositionupdate', event);
      const text = event.target.value;
      const lastCharacter = text[text.length - 1] || '';
      this.isComposing = !isKorean(lastCharacter);
    },
    handleCompositionEnd(event) {
      this.$emit('compositionend', event);
      if (this.isComposing) {
        this.isComposing = false;
        this.handleInput(event);
      }
    },
    handleInput(event) {
      // should not emit input during composition
      // see: https://github.com/ElemeFE/element/issues/10516
      if (this.isComposing) return;

      // hack for https://github.com/ElemeFE/element/issues/8548
      // should remove the following line when we don't support IE
      if (event.target.value === this.nativeInputValue) return;

      if (this.formatter) {
        this.$emit('input', this.formatter(event.target.value));
      } else {
        this.$emit('input', event.target.value);
      }

      // ensure native input value is controlled
      // see: https://github.com/ElemeFE/element/issues/12850
      this.$nextTick(this.setNativeInputValue);
    },
    handleChange(event) {
      this.$emit('change', event.target.value);
    },
    clear() {
      this.$emit('input', '');
      this.$emit('change', '');
      this.$emit('clear');

      this.$nextTick(() => {
        this.focus();
      });
    },
    handlePasswordVisible() {
      this.passwordVisible = !this.passwordVisible;
      this.$nextTick(() => {
        this.focus();
      });
    },
    getInput() {
      return this.$refs.input || this.$refs.textarea;
    },
    getSuffixVisible() {
      return this.$slots.suffix || this.showClear || this.showPassword || this.isWordLimitVisible;
    },
    getSuffixInnerVisible() {
      return this.showClear || this.showPassword || this.isWordLimitVisible;
    }
  },
  mounted() {
    this.setNativeInputValue();
    this.resizeTextarea();
  }
};
</script>
