<template>
  <transition name="bc-scrollbar-fade">
    <div
      v-show="always || visible"
      ref="instance"
      class="bc-scrollbar__bar"
      :class="'is-' + bar.key"
      @mousedown="clickTrackHandler"
    >
      <div
        ref="thumb"
        class="bc-scrollbar__thumb"
        :style="thumbStyle"
        @mousedown="clickThumbHandler"
      />
    </div>
  </transition>
</template>

<script>
import { BAR_MAP, renderThumbStyle } from './util'
let cursorDown = false
let cursorLeave = false
let originalOnSelectStart = document.onselectstart

export default {
  name: 'ScrollbarThumb',
  // inject: {
  //   scrollbar: 'scrollbarContextKey'
  // },
  inject: ['scrollbarContextKey'],
  props: {
    vertical: Boolean,
    size: String,
    move: Number,
    ratio: {
      type: Number,
      required: true,
    },
    always: Boolean,
  },
  data() {
    return {
      thumbState: {},
      visible: false
    }
  },
  computed: {
    scrollbar() {
      return this.scrollbarContextKey()
    },
    bar() {
      return BAR_MAP[this.vertical ? 'vertical' : 'horizontal']
    },
    thumbStyle() {
      return renderThumbStyle({
        size: this.size,
        move: this.move,
        bar: this.bar,
      })
    },
    offsetRatio() {
      return (
        this.$refs.instance[this.bar.offset] ** 2 /
        this.scrollbar.wrapElement[this.bar.scrollSize] /
        this.ratio /
        this.$refs.thumb[this.bar.offset]
      )
    }
  },
  watch: {
    scrollbar({ scrollbarElement }) {
      if (scrollbarElement) {
        this.scrollbar.scrollbarElement.addEventListener('mousemove', this.mouseMoveScrollbarHandler)
        this.scrollbar.scrollbarElement.addEventListener('mouseleave', this.mouseLeaveScrollbarHandler)
      }
    }
  },
  methods: {
    clickThumbHandler(e) {
      e.stopPropagation()
      if (e.ctrlKey || [1, 2].includes(e.button)) return
      window.getSelection().removeAllRanges()
      this.startDrag(e)

      const el = e.currentTarget
      if (!el) return
      this.thumbState[this.bar.axis] =
        el[this.bar.offset] -
        (e[this.bar.client] - el.getBoundingClientRect()[this.bar.direction])
    },
    clickTrackHandler(e) {
      if (!this.$refs.thumb || !this.$refs.instance || !this.scrollbar.wrapElement) return

      const offset = Math.abs(
        e.getBoundingClientRect()[this.bar.direction] -
          e[this.bar.client]
      )
      const thumbHalf = this.$refs.thumb[this.bar.offset] / 2
      const thumbPositionPercentage =
        ((offset - thumbHalf) * 100 * this.offsetRatio) /
        this.$refs.instance[this.bar.offset]

      this.scrollbar.wrapElement[this.bar.scroll] =
        (thumbPositionPercentage * this.scrollbar.wrapElement[this.bar.scrollSize]) /
        100
    },
    startDrag(e) {
      e.stopImmediatePropagation()
      cursorDown = true
      document.addEventListener('mousemove', this.mouseMoveDocumentHandler)
      document.addEventListener('mouseup', this.mouseUpDocumentHandler)
      originalOnSelectStart = document.onselectstart
      document.onselectstart = () => false
    },
    mouseMoveDocumentHandler(e) {
      if (!this.$refs.instance || !this.$refs.thumb) return;
      if (cursorDown === false) return;

      const prevPage = this.thumbState[this.bar.axis]
      if (!prevPage) return

      const offset =
        (this.$refs.instance.getBoundingClientRect()[this.bar.direction] -
          e[this.bar.client]) *
        -1
      const thumbClickPosition = this.$refs.thumb[this.bar.offset] - prevPage
      const thumbPositionPercentage =
        ((offset - thumbClickPosition) * 100 * this.offsetRatio) /
        this.$refs.instance[this.bar.offset]
      this.scrollbar.wrapElement[this.bar.scroll] =
        (thumbPositionPercentage * this.scrollbar.wrapElement[this.bar.scrollSize]) /
        100
    },
    mouseUpDocumentHandler() {
      cursorDown = false
      this.thumbState[this.bar.axis] = 0
      document.removeEventListener('mousemove', this.mouseMoveDocumentHandler)
      document.removeEventListener('mouseup', this.mouseUpDocumentHandler)
      this.restoreOnselectstart()
      if (cursorLeave) this.visible = false
    },
    mouseMoveScrollbarHandler() {
      cursorLeave = false
      this.visible = !!this.size
    },
    mouseLeaveScrollbarHandler() {
      cursorLeave = true
      this.visible = cursorDown
    },
    restoreOnselectstart() {
      if (document.onselectstart !== originalOnSelectStart) {
        document.onselectstart = originalOnSelectStart
      }
    }
  },
  beforeDestroy() {
    this.restoreOnselectstart()
    document.removeEventListener('mouseup', this.mouseUpDocumentHandler)

    this.scrollbar.scrollbarElement.removeEventListener('mousemove', this.mouseMoveScrollbarHandler)
    this.scrollbar.scrollbarElement.removeEventListener('mouseleave', this.mouseLeaveScrollbarHandler)
  }
}
</script>