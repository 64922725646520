

<script>
import Popper from 'utils/mixins/popper'
import { on, off, addClass, removeClass } from 'element-ui/src/utils/dom';
import Vue from 'vue';

export default {
  name: 'BcPopover',
  mixins: [Popper],
  props: {
    trigger: {
      type: String,
      default: 'click',
      validator: value => ['click', 'focus', 'hover', 'manual'].includes(value)
    },
    disabled: Boolean,
    openDelay: {
      type: Number,
      default: 0
    },
    closeDelay: {
      type: Number,
      default: 200
    },
    popperClass: String,
    width: {},
    referenceArrow: {
      type: Boolean,
      default: true
    }
  },
  render(h) {
    if (this.popperVM) {
      let events = {}
      if (this.trigger === 'hover') {
        events = {
          on: { 
            mouseenter: this.handleMouseEnter,
            mouseleave: this.handleMouseLeave
          },
        }
      }

      this.popperVM.node = (
        <transition name="popover-transition">
          <div
            ref="popper"
            role="tooltip"
            class={
              ['bc-popover', this.popperClass]
            }
            style={{ width: this.width + 'px' }}
            {...events}
            v-show={!this.disabled && this.showPopper}>
            { this.$slots.popper }
          </div>
        </transition>
      );
    }

    if (this.referenceArrow) {
      return (
        <div class="bc-popover-wrapper">
          {this.$slots.default}

          <SvgIcon
            name="caret-down"
            class={[{ reverse: this.showPopper }]}
          />
        </div>
      )
    } else {
      return this.$slots.default
    }
  },
  watch: {
    showPopper: {
      handler(val) {
        if (val) {
          addClass(this.$el, 'bc-popover-open')
        } else {
          removeClass(this.$el, 'bc-popover-open')
        }
      },
      immediate: true
    }
  },
  methods:{
    doToggle() {
      this.showPopper = !this.showPopper;
    },
    handleDocumentClick(e) {
      let reference = this.referenceElm || this.$refs.reference;
      const popper = this.popper || this.$refs.popper;

      if (!this.$el ||
        !reference ||
        this.$el.contains(e.target) ||
        reference.contains(e.target) ||
        !popper ||
        popper.contains(e.target)) return;
      this.showPopper = false;
    },
    handleMouseEnter() {
      clearTimeout(this._timer);
      if (this.openDelay) {
        this._timer = setTimeout(() => {
          this.showPopper = true;
        }, this.openDelay);
      } else {
        this.showPopper = true;
      }
    },
    handleMouseLeave() {
      clearTimeout(this._timer);
      if (this.closeDelay) {
        this._timer = setTimeout(() => {
          this.showPopper = false;
        }, this.closeDelay);
      } else {
        this.showPopper = false;
      }
    },
  },
  beforeCreate() {
    this.popperVM = new Vue({
      name: 'VirtualPopper',
      parent: this,
      data: { node: '' },
      render(h) {
        return this.node;
      }
    }).$mount();
  },
  mounted() {
    const reference = this.referenceElm = this.$el;

    if (this.$el.nodeType === 1) {
      if (this.trigger === 'click') {
        on(reference, 'click', this.doToggle)
        
      } else if (this.trigger === 'hover') {
        on(reference, 'mouseenter', this.handleMouseEnter);
        on(reference, 'mouseleave', this.handleMouseLeave);
      }
    }

    on(document, 'click', this.handleDocumentClick);

    if (this.value && this.popperVM) {
      this.popperVM.$nextTick(() => {
        if (this.value) {
          this.updatePopper();
        }
      });
    }
  },
  destroyed() {
    off(this.referenceElm, 'click', this.doToggle);
    off(document, 'click', this.handleDocumentClick);
    off(this.referenceElm, 'mouseleave', this.handleMouseLeave);
    off(this.referenceElm, 'mouseenter', this.handleMouseEnter);
    off(this.$refs.popper, 'mouseleave', this.handleMouseLeave);
    off(this.$refs.popper, 'mouseenter', this.handleMouseEnter);
  }
}
</script>

<style lang="scss">
.popover-transition-enter, 
.popover-transition-enter-from, 
.popover-transition-leave-to {
  opacity: 0;
  transform: scale(.85);
}

.popover-transition-enter-to, 
.popover-transition-leave-from {
  transform: scale(1);
} 

.popover-transition-enter-active {
  transition: opacity .15s cubic-bezier(0, 0, .2, 1), transform .15s cubic-bezier(0, 0, .2, 1);
}

.popover-transition-leave-active {
  transition: opacity .15s cubic-bezier(.4, 0, 1, 1), transform .15s cubic-bezier(.4, 0, 1, 1);
}

.bc-popover-wrapper {
  cursor: pointer;
  @include flex;
  
  --bc-popover-arrow-color: #{$black};
  
  .bc-icon-caret-down {
    margin-inline-start: 0.04rem;
    transition: transform 0.3s ease;
    font-size: 0.14rem;
    color: var(--bc-popover-arrow-color);

    &.reverse {
      transform: rotate(180deg);
    }
  }
}

.bc-popover {
  --bc-popover-bg: var(--popper-bc);
  --bc-popover-radius: 8px;

  border-radius: var(--bc-popover-radius);
  background-color: var(--bc-popover-bg);
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, .12), 0 6px 16px 0 rgba(0, 0, 0, .08), 0 9px 28px 8px rgba(0, 0, 0, .05);
  overflow: hidden;
  z-index: $popper-index;
}
</style>  