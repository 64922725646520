import { get, post } from 'extensions/axios';
import config from 'config/config.js'

function v4Url(url) {
  return config.v4Url + url
}

export function antiFake(input) {
  return get(`/antiFake`, { input });
}

export function getScanLoginToken() {
  return get('/getScanLoginToken')
}

// 获取登录扫码结果
export function getScanLoginResult(token) {
  return post('/getScanLoginResult', { token })
}

export function getExchangeFee() {
  return get('/exchange-fee')
}

export function getChainsConfig() {
  return get('/chains-config')
}

export function setTopSearch({ symbol, type }) {
  return post(v4Url('/search/top-search'), { symbol, type })
}

export function getTopSearch() {
  return get(v4Url('/search/get-top-search'))
}

export function getHotEvent() {
  return get(v4Url('/search/get-event-list'))
}