import config from 'config/config';

// oss: 'https://biconomyweb.oss-ap-northeast-1.aliyuncs.com',
//   ossAccelerate: 'https://biconomyweb.oss-accelerate.aliyuncs.com', // oss加速

export function getOssUrl(url, accelerate = true) {
  return (accelerate ? config.ossAccelerate : config.oss) + url;
}

export function getOssDoc(url) {
  return config.ossDoc + url;
}

export function getOssId(url) {
  if (url.includes('http')) {
    return url;
  }

  return config.ossId + url;
}
