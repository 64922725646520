<template>
  <div class="bc-modal" v-if="value">
    <div v-if="mask" class="bc-modal-mask" :style="{ 'z-index': zIndex }"> </div>

    <div
      class="bc-modal-wrapper"
      @click="onWrapperClick"
      ref="wrapperRef"
      :style="{ 'z-index': zIndex }"
    >
      <div
        ref="dialog"
        class="bc-modal-content"
        :style="{ width, transformOrigin }"
        v-show="dialogShow"
      >
        <div class="bc-modal-header" v-if="showHeader">
          <div class="bc-modal-title">
            <slot name="header">{{ title }}</slot>
          </div>

          <div v-if="closable" class="close-wrap" @click="cancel">
            <SvgIcon name="close" />
          </div>
        </div>

        <div class="bc-modal-body">
          <slot name="body"></slot>
        </div>

        <div v-if="$slots.footer" class="bc-modal-footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    value: Boolean,
    // 标题
    title: String,
    // 是否显示关闭按钮
    closable: {
      type: Boolean,
      default: true
    },
    // 是否显示遮罩层
    mask: {
      type: Boolean,
      default: true
    },
    // 点击遮罩层是否关闭弹窗
    maskClosable: {
      type: Boolean,
      default: true
    },
    zIndex: Number,
    width: {
      type: String,
      default: '5.6rem'
    },
    appendToBody: Boolean
  },
  data() {
    return {
      dialogShow: false,
      transformOrigin: ''
    };
  },
  computed: {
    showHeader() {
      return this.title || this.$slots.header
    }
  },
  watch: {
    value: {
      handler(val) {
        this.dialogShow = val;

        if (!document.body.style.overflow && val) {
          const scrollbarWidth = window.innerWidth - document.body.clientWidth;
          // document.body.style.width = `calc(100% - ${scrollbarWidth}px)`;
          document.body.style.marginInlineEnd = `${scrollbarWidth}px`;

          document.body.style.overflow = 'hidden';
        }

        if (!val) {
          document.body.style.overflow = '';
          // document.body.style.width = '';
          document.body.style.marginInlineEnd = ``;

          this.transformOrigin = '';
        }
      },
      immediate: true
    }
  },
  methods: {
    cancel() {
      this.$emit('input', false);
      this.$emit('cancel');
    },
    onWrapperClick(e) {
      if (!this.maskClosable) return null;

      if (this.$refs.wrapperRef == e.target) {
        this.cancel();
      }
    },
    onMouseClick(e) {
      if (this.value) {
        const elementOffset = this.$refs.dialog.getBoundingClientRect();
        this.transformOrigin = `${e.x - elementOffset.left}px ${e.y - elementOffset.top}px`;
      }
    }
  },
  created() {
    window.addEventListener('click', this.onMouseClick);
  },
  mounted() {
    if (this.appendToBody) {
      this.$nextTick(() => {
        document.body.appendChild(this.$el);
      });
    }
  },
  beforeDestroy() {
    window.removeEventListener('click', this.onMouseClick);

    document.body.style.overflow = '';
    document.body.style.marginInlineEnd = '';
  }
};
</script>
