<template>
  <div>
    <thumb :move="moveX" :ratio="ratioX" :size="width" :always="always" />
    <thumb
      :move="moveY"
      :ratio="ratioY"
      :size="height"
      vertical
      :always="always"
    />
  </div>
</template>

<script>
import Thumb from './thumb.vue'
import { GAP } from './util'

export default {
  props: {
    always: {
      type: Boolean,
      default: true,
    },
    width: String,
    height: String,
    ratioX: {
      type: Number,
      default: 1,
    },
    ratioY: {
      type: Number,
      default: 1,
    },
  },
  components: {
    Thumb
  },
  data() {
    return {
      moveX: 0,
      moveY: 0
    }
  },
  methods: {
    handleScroll(wrap) {
      if (wrap) {
        const offsetHeight = wrap.offsetHeight - GAP
        const offsetWidth = wrap.offsetWidth - GAP

        this.moveY = ((wrap.scrollTop * 100) / offsetHeight) * this.ratioY
        this.moveX = ((wrap.scrollLeft * 100) / offsetWidth) * this.ratioX
      }
    }
  }
}
</script>
