<template>
  <Popover
    class="header-dropdown"
    trigger="hover"
    :closeDelay="10"
    :offset="0"
    popperClass="header-nav-popover"
    placement="bottom-end"
    ref="popoverRef"
  >
    <p class="header-label">
      {{ data.title }}
    </p>

    <template slot="popper">
      <WalletInfo v-if="data.type === 'wallet'" @clickLink="clickLink" />
      <SecondsWalletInfo v-if="data.type === 'seconds-wallet'" @clickLink="clickLink" />

      <template v-for="item in data.children">
         <NavLink
          class="header-user-popover-item"
          :key="item.name"
          :data="item"
          @click="clickLink"
        >
          {{ item.name }}
        </NavLink>
      </template>
    </template>
  </Popover>
</template>

<script>
import Popover from 'components/common/popover'
import NavLink from '../nav-link.vue';
import WalletInfo from './wallet-info';
import SecondsWalletInfo from 'components/client/header/user/seconds-wallet-info.vue';

export default {
  props: {
    data: Object
  },
  components: {
    SecondsWalletInfo,
    Popover,
    NavLink,
    WalletInfo
  },
  methods: {
    clickLink() {
      this.$refs['popoverRef'].doToggle();
    }
  }
}
</script>

<style lang="scss">
.header-nav-popover {
  .header-user-popover-item {
    @include flex();
    height: 0.48rem;
    white-space: nowrap;
    padding: 0 0.24rem;
    min-width: 1rem;
    color: var(--color);
    font-weight: 500;
    text-transform: capitalize;

    &:hover {
      background-color: $gray-hover;
      color: $main-color;
    }

    .name {
      position: relative;

      .new {
        position: absolute;
        top: 0;
        color: $white;
        padding: 0 0.06rem;
        background: $main-color;
        border-radius: 0.04rem;
        height: 0.16rem;
        line-height: 0.16rem;
        font-size: 0.12rem;
        @include absoluteRight(0);

        @include ltl {
          transform: translate(100%, -50%) scale(0.9);
        }

        @include rtl {
          transform: translate(-100%, -50%) scale(0.9);
        }
      }
    }
  }
}
</style>
